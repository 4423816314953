import { Button } from '@material-ui/core';
import FormContainer from 'components/formContainer/FormContainer';
import FormLogo from 'components/formLogo/FormLogo.component';
import React from 'react';
import usePatchEmailStyles from './usePatchEmailStyles';

interface FormProps {
  handleClick: () => void;
}

const Form: React.FC<FormProps> = ({ handleClick }) => {
  const classes = usePatchEmailStyles();

  return (
    <>
      <FormContainer elevation={4}>
        <FormLogo />
        <h6 className={classes.header}>Thank you!</h6>
        <p className={classes.text}>Your e-mail has been successfully updated!</p>
        <Button className={classes.button} onClick={handleClick} variant="contained" color="secondary">
          Continue
        </Button>
      </FormContainer>
    </>
  );
};

export default Form;
