import React from 'react';
import { Button } from '@material-ui/core';
import theme from 'MuiTheme';

export interface PaidButtonProps {
  isPaid: boolean;
  onClick: () => void;
  isFree?: boolean;
  style?: React.CSSProperties;
}

const renderPaidText = (isFree: boolean | undefined, isPaid: boolean) => {
  if (isFree) {
    return 'Free';
  }
  return isPaid ? 'Paid' : 'Pay';
};

const PaidButton: React.FC<PaidButtonProps> = ({ isPaid, onClick, isFree, style }) => (
  <Button
    onClick={onClick}
    variant={isPaid && !isFree ? 'contained' : 'outlined'}
    color="secondary"
    style={{ width: '105px', backgroundColor: isPaid && !isFree ? theme.palette.success.main : '', ...style }}
    disabled={isFree}
  >
    {renderPaidText(isFree, isPaid)}
  </Button>
);

export default PaidButton;
