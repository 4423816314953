import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  interval: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  intervalWrap: {
    flexWrap: 'wrap',
  },
  leftInterval: {
    marginRight: '18px',
    width: '100%',
  },
  full: {
    width: '100%',
  },
  rightInterval: {
    marginLeft: '18px',
    width: '100%',
  },
}));

const PriceInterval = ({ control }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.interval}>
        <div className={classes.leftInterval}>
          <TextComponent control={control} name='startPrice' label='from' postfix={'SEK'} />
        </div>
        <div>-</div>
        <div className={classes.rightInterval}>
          <TextComponent control={control} name='endPrice' label='to' postfix={'SEK'} />
        </div>
      </div>
    </>
  );
};

export default PriceInterval;
