import React from 'react';
import { Chip } from '@material-ui/core';
import { Typography } from '@material-ui/core';

/**
 * Displays chips and their data
 * expects a array of strings
 */
const CategoryChips = ({ data }) => {
  if (data.length === 0) {
    return <Typography variant='body2'>No categories found for this user</Typography>;
  }

  return (
    <div>
      <Typography variant='body2' style={{ marginBottom: '8px' }}>
        Categories
      </Typography>
      {data.map((chip, i) => (
        <Chip
          label={<Typography variant='body2'>{chip}</Typography>}
          key={i}
          style={{ marginRight: '8px', marginBottom: '8px' }}
        />
      ))}
    </div>
  );
};

export default CategoryChips;
