import React from 'react';
import { Typography, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    //minWidth: '100%',
    maxWidth: (props) => (props.isMobile ? '86vw' : '42vw'),
    color: (props) => (props.disabled ? 'rgba(0, 0, 0, 0.3)' : null),
  },
  checkboxName: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    flex: 1,
    minWidth: 0,
  },
  checkbox: {
    width: '24px',
    height: '24px',
    padding: 0,
  },
  date: {
    flexBasis: 'max-content',
  },
  name: {
    // props isMobile on marginLeft
    marginLeft: (props) => (props.isMobile ? '8px' : '16px'),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const CheckboxEvent = ({
  name = '',
  checked = false,
  onChange = () => null,
  isMobile = false,
  date = new Date(),
  disabled = false,
}) => {
  const classes = useStyles({ isMobile, disabled });

  // take date and return YY-MM-DD
  const getDate = () => {
    const year = date.getFullYear().toString().slice(-2);
    const month = date.getMonth() + 1;
    const monthString = month < 10 ? `0${month}` : month;
    const day = date.getDate();
    const dayString = day < 10 ? `0${day}` : day;
    return `${year}-${monthString}-${dayString}`;
  };

  return (
    <div className={classes.root}>
      <div className={classes.checkboxName}>
        <Checkbox checked={checked} onChange={onChange} className={classes.checkbox} disabled={disabled} />
        <Typography variant='body2' className={classes.name}>
          {name}
        </Typography>
      </div>
      <Typography variant='body2' className={classes.date}>
        {getDate()}
      </Typography>
    </div>
  );
};
