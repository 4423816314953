import './app.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate, Navigate } from 'react-router-dom';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Home from '/pages/home/Home';
import Events from '/pages/events/Events';
import Tickets from '/pages/tickets/Tickets';
import { Login } from '/pages/auth/login/Login';
import { CreateAccount } from '/pages/auth/createAccount/CreateAccount';
import SentForgot from '/pages/auth/sentForgot/SentForgot';
import ConfirmEmail from '/pages/auth/confirmEmail/ConfirmEmail';
import LoginForgot from '/pages/auth/loginForgot/LoginForgot';
import { LoginReset } from '/pages/auth/loginReset/LoginReset';
import Logout from '/pages/auth/logout/Logout';
import WriteProjectInfo from '/pages/writeProjectInfo/WriteProjectInfo';
import Profile from '/pages/profile/Profile';
import About from '/pages/about/About';
import SnackbarComponent from '/components/snackBar/Snackbar.component';
import Admin from '/pages/admin/Admin';
import { Grid } from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import EventIcon from '@material-ui/icons/Event';
import Pony from '/pages/pony/Pony';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Menu } from '/components/navbar/Menu';
import { projectActions } from '/redux/actions/ProjectActions';
import { accessActions } from '/redux/actions/AccessActions';
import theme from '/MuiTheme';
import PatchEmail from '/pages/auth/patchEmail/PatchEmail';
import SubMenuContainer from 'containers/subMenu/SubMenuContainer';
import PrivateRoute from '/components/private-route/PrivateRoute';

const DEFAULT_PROJECT = 5;
const DEFAULT_ROUTE = `/${DEFAULT_PROJECT}/`;

const useStyles = makeStyles((t) => ({
  root: {
    margin: '0 auto',
    maxWidth: t.breakpoints.values.lg,
  },
}));

const routesList = [
  {
    name: 'Home',
    icon: <HomeIcon />,
    location: 'middle',
  },
  {
    name: 'Events',
    icon: <EventIcon />,
    location: 'middle',
  },
  {
    name: 'Tickets',
    icon: <ConfirmationNumberIcon />,
    location: 'middle',
  },
  {
    name: 'Admin',
    icon: <SettingsIcon />,
    location: 'right',
  },
  {
    name: 'Profile',
    icon: <AccountCircleIcon />,
    location: 'right',
  },
  {
    name: 'Login',
    icon: <ExitToAppIcon />,
    location: 'right',
  },
  {
    name: 'Logout',
    icon: <ExitToAppIcon />,
    location: 'right',
  },
];

/**
 * Renders page depending on if the project exists or not
 * To prevent wrong URLs.
 */
const ProjectRender = ({ renderComponent }) => {
  const permittedIds = ['1', '2', '3', '4', '5'];
  const navigate = useNavigate();
  const { projectId } = useParams();
  let somethingWrong = false;

  /**
   * FIXME: get info from the backend later on
   */
  if (!permittedIds.includes(projectId)) {
    somethingWrong = true;
    navigate(DEFAULT_ROUTE);
  }

  return <>{somethingWrong ? null : renderComponent}</>;
};

const App = ({ routes, landingPage, setRole, projects, projectSet, projectId, noProject }) => {
  const classes = useStyles(theme);
  const [openDrawer, setDrawer] = useState(false);
  const [access, setAccess] = useState([]);

  const updateAccess = () => {
    setAccess(routesList.filter((x) => routes?.includes(x.name)));
  };

  useEffect(() => {
    updateAccess();
  }, [landingPage]);

  useEffect(() => {
    // get projectId
    projectSet(parseInt(window.location.pathname.split('/')[1], 10));

    // set role
    const { token } = sessionStorage;
    if (token) {
      setRole(token);
      projects(token);
    }
  }, []);

  useEffect(() => {
    if (landingPage === '') updateAccess();
  }, [routes]);

  const toggleDrawer = () => {
    setDrawer(!openDrawer);
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography component="span">
        <SnackbarComponent />
        <Grid className={classes.root} container justifyContent="center">
          <Router>
            <SubMenuContainer />
            <Menu openDrawer={openDrawer} toggleDrawer={toggleDrawer} routes={access} />
            <Routes>
              <Route
                path="/:projectId/*"
                element={
                  <ProjectRender
                    renderComponent={
                      <Routes>
                        <Route path="/login/*" element={<Login />} />
                        <Route
                          path="/events/*"
                          element={
                            <PrivateRoute>
                              <Events />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/tickets/*"
                          element={
                            <PrivateRoute>
                              <Tickets />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/admin/*"
                          element={
                            <PrivateRoute admin>
                              <Admin />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/profile/*"
                          element={
                            <PrivateRoute>
                              <Profile />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/logout/*"
                          element={
                            <PrivateRoute>
                              <Logout />
                            </PrivateRoute>
                          }
                        />
                        <Route path="/forgot/*" element={<LoginForgot />} />
                        <Route path="/reset/*" element={<LoginReset />} />
                        <Route path="/createAccount/*" element={<CreateAccount />} />
                        <Route path="/sentForgot/*" element={<SentForgot />} />
                        <Route path="/confirm/*" element={<ConfirmEmail />} />
                        <Route path="/patchEmail/*" element={<PatchEmail />} />
                        <Route path="/pony/*" element={<Pony />} />
                        <Route path="/projectInfo/*" element={<WriteProjectInfo />} />
                        <Route path="about/*" element={<About />} />
                        <Route
                          path="*"
                          element={noProject ? <Navigate to={`/${projectId}/projectInfo`} /> : <Home />}
                        />
                      </Routes>
                    }
                  />
                }
              />
              <Route path="*" element={<Navigate to={DEFAULT_ROUTE} />} />
            </Routes>
          </Router>
        </Grid>
      </Typography>
    </ThemeProvider>
  );
};

const mapState = (state) => ({
  landingPage: state.authentication.landingPage,
  routes: state.roles.routes,
  projectId: state.projectInfo.projectId,
  noProject: state.roles.noProject,
});

const actionCreators = {
  setRole: accessActions.setRole,
  projects: projectActions.projects,
  projectSet: projectActions.projectSET,
};

export default connect(mapState, actionCreators)(App);
