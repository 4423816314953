import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUserBooking } from 'queries/AdminEventQueries';
import { useAppSelector } from 'redux/storeConfig';

interface MutationProps {
  bookingId: number;
  isPaid: boolean;
  isAttending: boolean;
}

const useUpdateBooking = (updateBooking: (bookingId: number, isAttending: boolean, isPaid: boolean) => void) => {
  const queryClient = useQueryClient();
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const sendData = useMutation({
    mutationFn: async ({ bookingId, isAttending, isPaid }: MutationProps) => {
      updateBooking(bookingId, isAttending, isPaid);
      await updateUserBooking(projectId, bookingId, { isPaid, isAttending });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getAdminEventStats']);
    },
  });

  const changeBooking = (bookingId: number, isAttending: boolean, isPaid: boolean) => {
    sendData.mutate({ bookingId, isAttending, isPaid });
  };

  return {
    isLoading: sendData.isLoading,
    changeBooking,
  };
};

export default useUpdateBooking;
