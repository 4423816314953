import React from 'react';
import { Button } from '@material-ui/core';

interface EventButtonsProps {
  onBack: () => void;
  onSubmit: () => void;
}

const EventEditButtons: React.FC<EventButtonsProps> = ({ onBack, onSubmit }) => (
  <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', marginBottom: '16px' }}>
    <Button color="secondary" onClick={onBack}>
      Cancel
    </Button>
    <Button variant="contained" color="secondary" style={{ width: '100px' }} onClick={onSubmit}>
      Save
    </Button>
  </div>
);

export default EventEditButtons;
