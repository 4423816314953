import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((t) => ({
  arrow: {
    width: 18,
    height: 18,
    opacity: 0.6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItemHover: {
    '&:hover': {
      color: t.palette.secondary.main,
    },
  },
}));

/**
 * Required props:
 *  - options
 *    example:
 *      [{
 *        onclick: () => console.log('hello'),
 *        label: 'my label',
 *      }]
 */
const UserRoleMenu = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ArrowDropDownIcon
        aria-controls='userRoleMenu'
        aria-haspopup='true'
        className={classes.arrow}
        onClick={handleClick}
      />
      <Menu id='userRoleMenu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {props.options.map((option, index) => (
          <MenuItem
            key={index}
            className={classes.menuItemHover}
            onClick={() => {
              option.onclick();
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default UserRoleMenu;
