import {
  CheckTicket,
  PostTicket,
  GetUserArrivalDate,
  GetAllEvents,
  CheckEventToUser,
  SetEventPaid,
  DeleteBookingToUser,
  AddBookingToUser,
  GetUsersList,
  SetUserRole,
  DeleteUser,
  GetUserInfo,
  GetUserTickets,
  GetUserCheckedIn,
  SetUserCheckedIn,
  GetUserStudy,
  GetUserPrefs,
  DeleteEventGroup,
} from '/data/api/AdminRequest';

import {
  adminUsersTransferChecks,
  adminUsersTransferSearchValue,
  adminUsersTransferGeneral,
  adminUsersTransferStudy,
  adminUsersTransferPreferences,
  adminUsersEventListSet,
  adminUsersTransferOpen,
  adminUsersSelectEvent,
  adminUsersAddBookingDialogOpenSet,
  adminUsersAddBookingDialogExpandedSet,
  adminUsersAddBookingResponseDataSet,
  adminUsersSetGeneral,
  adminUsersSetStudy,
  adminUsersSetPreferences,
  adminUsersSetEvents,
  adminUsersSetCheckedIn,
  adminUsersSetSelectedEvents,
} from '/redux/reducers/AdminReducers';
import { adminDeleteEventGroup } from '/redux/reducers/EventGroupsReducers';

import { snackActions } from './FeedbackActions';
import { getOptions } from './OptionsActions';
import * as Backend from '/data/api/BackendRequest.ts';
import { eventActions } from './EventActions';

import {
  ADMIN_ACCESS_USERS_SET,
  ADMIN_ACCESS_SEARCH_VALUE_SET,
  ADMIN_ACCESS_ROLE_SORT_UP_SET,
  ADMIN_ACCESS_ID_TO_DELETE_SET,
  ADMIN_ACCESS_DELETE_DIALOG_OPEN_SET,
  ADMIN_ACCESS_ROLE_FILTER_SET,
  ADMIN_ACCESS_ROLES_SET,
  ADMIN_ACCESS_CURRENT_PAGE_SET,
} from '../reducers/AdminReducers';

export const clearAddBooking = () => {
  return (dispatch) => {
    dispatch(adminUsersSelectEvent(null));
    dispatch(adminUsersAddBookingDialogOpenSet(false));
    dispatch(adminUsersAddBookingDialogExpandedSet(false));
    dispatch(adminUsersAddBookingResponseDataSet(null));
  };
};

export const fetchEventList = () => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;

    GetAllEvents(projectId)
      .then((response) => {
        dispatch(adminUsersEventListSet(response.data.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

export const fetchCheckEvent = (value) => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const userId = getState().adminUsers.searchValue;

    CheckEventToUser(projectId, value.eventId, userId)
      .then((response) => {
        dispatch(adminUsersSelectEvent(value));
        dispatch(adminUsersAddBookingResponseDataSet(response.data.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

export const addBooking = (eventId, isPaid, isAttending) => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const userId = getState().adminUsers.searchValue;

    AddBookingToUser(projectId, userId, eventId, isPaid, isAttending)
      .then(() => {
        dispatch(updateBookings());
        dispatch(snackActions.successFeedback('Booking added to user'));
        dispatch(adminUsersAddBookingDialogOpenSet(false));
        dispatch(clearAddBooking());
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

const updateBookings = () => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const userId = getState().adminUsers.searchValue;

    GetUserTickets(projectId, userId)
      .then((tickets) => {
        dispatch(adminUsersSetEvents(tickets.data.data));
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

export const deleteBookings = () => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const selectedEvents = getState().adminUsers.selectedEvents;
    const events = getState().adminUsers.events;

    const filteredEvents = events.filter((event) => selectedEvents.includes(event.bookingId));

    const proms = [];

    filteredEvents.forEach((event) => {
      proms.push(DeleteBookingToUser(projectId, event.bookingId));
    });

    Promise.all(proms)
      .then(() => {
        dispatch(snackActions.successFeedback('Events deleted'));
        dispatch(adminUsersSetSelectedEvents([]));
        dispatch(updateBookings());
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

export const payBookings = () => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;

    const selectedEvents = getState().adminUsers.selectedEvents;
    const events = getState().adminUsers.events;

    const filteredEvents = events.filter((event) => selectedEvents.includes(event.bookingId));

    // Only set paid to true if there is a selected event that is not paid
    let setPaid = false;

    filteredEvents.forEach((event) => {
      if (!event.isPaid) setPaid = true;
    });

    const proms = [];
    filteredEvents.forEach((event) => {
      proms.push(SetEventPaid(projectId, event.bookingId, setPaid));
    });

    Promise.all(proms)
      .then(() => {
        dispatch(snackActions.successFeedback(`Events ${setPaid ? 'paid' : 'unpaid'}`));
        dispatch(updateBookings());
      })
      .catch((err) => {
        console.log(err);
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

const formatStudyProgram = (studyProgramId, studyInfo) => {
  return studyInfo.find((studyProgram) => studyProgram.studyProgramId === studyProgramId);
};

const mapStudyProgramTransfer = (studyProgramId) => {
  return (dispatch, getState) => {
    const studyInfo = getState().options.study;
    dispatch(adminUsersTransferStudy(formatStudyProgram(studyProgramId, studyInfo)));
  };
};

// Maps the studyId given to the redux info
const mapStudyProgram = (studyProgramId) => {
  return (dispatch, getState) => {
    const studyInfo = getState().options.study;
    dispatch(adminUsersSetStudy(formatStudyProgram(studyProgramId, studyInfo)));
  };
};

const formatPreferences = (option1, option2, option3, options) => {
  // taken from preferences.jsx
  let filterer = options;
  filterer.unshift(filterer.pop());
  let preferenceList = filterer.slice(0, 7);

  let allergies = [];
  let foodprefs = [];

  option2.forEach((opt) => {
    if (preferenceList.includes(opt)) foodprefs.push(opt);
    else allergies.push(opt);
  });

  return {
    drinking: option1[0] === 'Alcohol',
    foodPrefs: foodprefs,
    allergies: allergies,
    other: option3[0],
  };
};

const mapPreferencesTransfer = (option1, option2, option3, options) => {
  return (dispatch) => {
    dispatch(adminUsersTransferPreferences(formatPreferences(option1, option2, option3, options)));
  };
};

const mapPreferences = (option1, option2, option3, options) => {
  return (dispatch) => {
    dispatch(adminUsersSetPreferences(formatPreferences(option1, option2, option3, options)));
  };
};

const formatGeneralInfo = (generalInfo, general) => {
  const mappedGeneral = { ...generalInfo };

  mappedGeneral.sex = general.gender.find((gen) => gen.value === mappedGeneral.sex).label;
  mappedGeneral.nationality = general.nationalities.find((nation) => nation.value === mappedGeneral.nationality).label;
  return mappedGeneral;
};

const mapGeneralInfoTransfer = (generalInfo) => {
  return (dispatch, getState) => {
    const general = getState().options.general;
    dispatch(adminUsersTransferGeneral(formatGeneralInfo(generalInfo, general)));
  };
};

// Maps the gender and nationality
const mapGeneralInfo = (generalInfo) => {
  return (dispatch, getState) => {
    const general = getState().options.general;
    dispatch(adminUsersSetGeneral(formatGeneralInfo(generalInfo, general)));
  };
};

export const wipeTransferWindow = () => {
  return (dispatch) => {
    dispatch(adminUsersTransferSearchValue(''));
    dispatch(adminUsersTransferGeneral(null));
    dispatch(adminUsersTransferStudy(null));
    dispatch(adminUsersTransferPreferences(null));
    dispatch(adminUsersTransferChecks(null));
  };
};

export const transferTicket = (isAttending, isPaid) => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const selectedEvents = getState().adminUsers.selectedEvents;
    const userId = getState().adminUsers.transferTicket.searchValue;
    const realUserId = getState().adminUsers.searchValue;

    PostTicket(projectId, userId, selectedEvents[0], isAttending, isPaid)
      .then(() => {
        dispatch(snackActions.successFeedback('The ticket transfer was successful'));
        GetUserTickets(projectId, realUserId)
          .then((rs) => {
            dispatch(adminUsersSetEvents(rs.data.data));
            dispatch(adminUsersSetSelectedEvents([]));
            dispatch(adminUsersTransferOpen(false));
            dispatch(wipeTransferWindow());
          })
          .catch((err) => {
            dispatch(snackActions.errorFeedback('Error fetching data'));
          });
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('The ticket cannot be transferred to this user'));
      });
  };
};

export const getUserDataTransfer = () => {
  return async (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const selectedEvents = getState().adminUsers.selectedEvents;
    const userId = getState().adminUsers.transferTicket.searchValue;

    const proms = [];
    proms.push(GetUserInfo(projectId, userId)); // [0]
    proms.push(GetUserStudy(projectId, userId)); // [1]
    proms.push(GetUserPrefs(projectId, userId, 1)); // [2]
    proms.push(GetUserPrefs(projectId, userId, 2)); // [3]
    proms.push(GetUserPrefs(projectId, userId, 3)); // [4]
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/2`)); // [5]
    proms.push(GetUserArrivalDate(projectId, userId)); // [6]
    proms.push(CheckTicket(projectId, userId, selectedEvents[0])); // [7]

    Promise.all(proms)
      .then((response) => {
        dispatch(
          mapGeneralInfoTransfer({
            ...response[0].data.data.AccountInfo,
            arrivalDate: response[6].data.data.arrivalDate,
          })
        );
        dispatch(mapStudyProgramTransfer(response[1].data.data.studyProgramId));
        dispatch(
          mapPreferencesTransfer(
            response[2].data.data,
            response[3].data.data,
            response[4].data.data,
            response[5].data.data
          )
        );
        dispatch(adminUsersTransferChecks(response[7].data.data));
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

// Gets user's general info and bookings
export const getUserData = () => {
  return async (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const userId = getState().adminUsers.searchValue;

    if (!getState().options.study) {
      // call if study is empty
      await dispatch(getOptions());
    }

    if (!getState().eventRetrival.eventGroups) {
      await dispatch(eventActions.getEventGroups(projectId));
    }

    const proms = [];
    proms.push(GetUserInfo(projectId, userId)); // [0]
    proms.push(GetUserTickets(projectId, userId)); // [1]
    proms.push(GetUserCheckedIn(projectId, userId)); // [2]
    proms.push(GetUserStudy(projectId, userId)); // [3]
    proms.push(GetUserPrefs(projectId, userId, 1)); // [4]
    proms.push(GetUserPrefs(projectId, userId, 2)); // [5]
    proms.push(GetUserPrefs(projectId, userId, 3)); // [6]
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/2`)); // [7]
    proms.push(GetUserArrivalDate(projectId, userId)); // [8]

    Promise.all(proms)
      .then((response) => {
        dispatch(
          mapGeneralInfo({ ...response[0].data.data.AccountInfo, arrivalDate: response[8].data.data.arrivalDate })
        );
        dispatch(adminUsersSetEvents(response[1].data.data));
        dispatch(adminUsersSetCheckedIn(response[2].data.data));
        dispatch(mapStudyProgram(response[3].data.data.studyProgramId));
        dispatch(
          mapPreferences(response[4].data.data, response[5].data.data, response[6].data.data, response[7].data.data)
        );
        dispatch(adminUsersSetSelectedEvents([]));
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
        dispatch(adminUsersSetGeneral(null));
        dispatch(adminUsersSetEvents([]));
        dispatch(adminUsersSetCheckedIn(false));
        dispatch(adminUsersSetSelectedEvents([]));
      });
  };
};

// Toggle users checked in status
export const toggleUserCheckedIn = () => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const userId = getState().adminUsers.searchValue;
    const checkedIn = getState().adminUsers.checkedIn;
    SetUserCheckedIn(projectId, userId, !checkedIn)
      .then(() => {
        dispatch(adminUsersSetCheckedIn(!checkedIn));
        dispatch(snackActions.successFeedback('Checked in status set'));
      })
      .catch((err) => dispatch(snackActions.errorFeedback('' + err)));
  };
};

// Gets list of users
export const getUsers = () => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    GetUsersList(projectId)
      .then((response) => {
        let responseUsers = [];
        response.data.data.map((user) => {
          user.editing = false;
          responseUsers.push(user);
        });
        dispatch(adminAccessUsersSet(responseUsers));
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

// Set user editing
export const setUserEditing = (accountId, value) => {
  return (dispatch, getState) => {
    let oldUsers = getState().adminAccess.users;
    let arr = [...oldUsers];
    arr[oldUsers.findIndex((user) => user.accountId === accountId)] = {
      ...arr[oldUsers.findIndex((user) => user.accountId === accountId)],
      editing: value,
    };
    dispatch(adminAccessUsersSet(arr));
  };
};

// sets a user role
export const setUserRole = (accountId, value) => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const oldUsers = getState().adminAccess.users;
    let arr = [...oldUsers];
    arr[oldUsers.findIndex((user) => user.accountId === accountId)] = {
      ...arr[oldUsers.findIndex((user) => user.accountId === accountId)],
      roleId: value,
    };
    SetUserRole(projectId, accountId, value)
      .then(() => dispatch(adminAccessUsersSet(arr)))
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

// toggles the role
export const roleSortToggle = () => {
  return (dispatch, getState) => {
    const oldUsers = getState().adminAccess.users;
    const oldRoleSortUp = getState().adminAccess.roleSortUp;

    const tempUsers = [...oldUsers];
    tempUsers.sort((a, b) => (oldRoleSortUp ? a.roleId - b.roleId : b.roleId - a.roleId));
    dispatch(adminAccessUsersSet(tempUsers));
    dispatch(adminAccessRoleSortUpSet(!oldRoleSortUp));
  };
};

// delete a user
export const deleteUser = () => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    const idToDelete = getState().adminAccess.idToDelete;

    DeleteUser(projectId, idToDelete)
      .then(() => {
        const oldUsers = getState().adminAccess.users;
        let tempUsers = [...oldUsers];
        tempUsers = tempUsers.filter((user) => user.accountId !== idToDelete);
        dispatch(adminAccessUsersSet(tempUsers));
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

export const deleteEventGroup = (eventGroupId) => {
  return (dispatch, getState) => {
    const projectId = getState().projectInfo.projectId;
    DeleteEventGroup(projectId, eventGroupId)
      .then(() => {
        const eventGroups = getState().adminEventGroups.eventGroups;
        const eventGroupToDelete = eventGroups.filter((eventGroup) => eventGroup.id === eventGroupId);
        dispatch(snackActions.successFeedback(`Event group '${eventGroupToDelete[0].name}' deleted.`));
        dispatch(adminDeleteEventGroup(eventGroupId));
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback('' + err));
      });
  };
};

export const adminAccessUsersSet = (payload) => {
  return { type: ADMIN_ACCESS_USERS_SET, payload: payload };
};

export const adminAccessSearchValueSet = (payload) => {
  return { type: ADMIN_ACCESS_SEARCH_VALUE_SET, payload: payload };
};

export const adminAccessRoleSortUpSet = (payload) => {
  return { type: ADMIN_ACCESS_ROLE_SORT_UP_SET, payload: payload };
};

export const adminAccessIdToDeleteSet = (payload) => {
  return { type: ADMIN_ACCESS_ID_TO_DELETE_SET, payload: payload };
};

export const adminAccessDeleteDialogSet = (payload) => {
  return { type: ADMIN_ACCESS_DELETE_DIALOG_OPEN_SET, payload: payload };
};

export const adminAccessRoleFilterSet = (payload) => {
  return { type: ADMIN_ACCESS_ROLE_FILTER_SET, payload: payload };
};

export const adminAccessRolesSet = (payload) => {
  return { type: ADMIN_ACCESS_ROLES_SET, payload: payload };
};

export const adminAccessCurrentPageSet = (payload) => {
  return { type: ADMIN_ACCESS_CURRENT_PAGE_SET, payload: payload };
};
