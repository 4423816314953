import { makeStyles } from '@material-ui/core';

const useStartEndStyles = makeStyles((t) => ({
  inputContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [t.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export default useStartEndStyles;
