import React from 'react';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

/**
 * Displays the title for events overview
 * required props:
 *  - isCollapsed : boolean
 *  - isAlcohol : boolean
 *  - isMobile : boolean
 *  - onClick : function
 */
const EventsTitle = ({ isCollapsed, isAlcohol, isMobile, onClick }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: isMobile ? '0' : '8px',
    }}
  >
    <Typography variant='h6'>Events Overview ({returnText(isAlcohol, isMobile)})</Typography>
    {isMobile ? (
      <IconButton style={{ padding: '8px' }} onClick={onClick} color='secondary'>
        {isCollapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </IconButton>
    ) : null}
  </div>
);

/**
 * Returns string based on if it's alcohol and if it's mobile format
 */
const returnText = (isAlcohol, isMobile) => {
  if (isAlcohol) {
    if (isMobile) return 'A';
    return 'Alcohol';
  }

  if (isMobile) return 'N-A';
  return 'Non-Alcohol';
};

export default EventsTitle;
