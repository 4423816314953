export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_STUDENT = 'ROLE_STUDENT';
export const ROLE_NO_PROJECT = 'ROLE_NO_PROJECT';
export const ROLE_FAIL = 'ROLE_FAIL';

const accessState = {
  routes: ['Login'],
  noProject: false,
  error: null,
};

export const roles = (state = accessState, action: any) => {
  switch (action.type) {
    case ROLE_ADMIN:
      return {
        routes: action.routes,
        noProject: false,
        error: null,
      };
    case ROLE_STUDENT:
      return {
        routes: action.routes,
        noProject: false,
        error: null,
      };
    case ROLE_NO_PROJECT:
      return {
        routes: action.routes,
        noProject: true,
        error: null,
      };
    case ROLE_FAIL:
      return {
        routes: action.routes,
        error: action.error,
        noProject: false,
      };
    default:
      return state;
  }
};
