import React from 'react';
import { Typography, Divider } from '@material-ui/core';

import theme from 'MuiTheme';

export const AdminHeader = ({ isMobile = false, title = '', subtitle = '', disableDivider = false, small = false }) => {
  const renderVariant = (mobileVariant, desktopVariant) => {
    return isMobile ? mobileVariant : desktopVariant;
  };

  return (
    <>
      <Typography
        variant={small ? 'h6' : renderVariant('h5', 'h4')}
        style={isMobile && !small ? { marginTop: '16px', marginBottom: '10px' } : null}
      >
        {title}
      </Typography>
      <Typography
        variant={renderVariant('body2', 'body1')}
        style={{
          marginTop: 0,
          marginBottom: '12px',
          color: `${theme.palette.surface.text}${theme.palette.other.opacityMedium}`,
        }}
      >
        {subtitle}
      </Typography>
      {!disableDivider && <Divider style={{ marginBottom: '16px' }} />}
    </>
  );
};

export default AdminHeader;
