import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Theme from '/MuiTheme';
import HeaderDesktop from '/components/adminAccess/desktop/HeaderDesktop';
import HeaderMobile from '/components/adminAccess/mobile/HeaderMobile';
import { connect } from 'react-redux';
import { roleSortToggle } from '/redux/actions/AdminActions';

const HeaderContainer = ({ roleSortToggle, roleSortUp }) => (
  <>
    {useMediaQuery(Theme.breakpoints.up('sm')) ? (
      <HeaderDesktop roleSortToggle={roleSortToggle} roleSortUp={roleSortUp} />
    ) : (
      <HeaderMobile roleSortToggle={roleSortToggle} roleSortUp={roleSortUp} />
    )}
  </>
);

const mapState = (state) => {
  return {
    roleSortUp: state.adminAccess.roleSortUp,
  };
};

const actions = {
  roleSortToggle: roleSortToggle,
};

export default connect(mapState, actions)(HeaderContainer);
