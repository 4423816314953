import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core/';
import * as Backend from '/data/api/BackendRequest.ts';
import useDebounce from '/utils/useDebounce';

const EventGroupsSearchBar = ({ setSelected, projectId }) => {
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const debouncedQuery = useDebounce(query, 350);

  useEffect(() => {
    if (debouncedQuery.length > 1) {
      Backend.request('get', `/admin/${projectId}/users/search/${debouncedQuery}`).then((res) => {
        setOptions(res.data.data.filter((e, _) => e));
      });
    }
  }, [debouncedQuery, setSelected]);

  const onChangeQuery = (event, value, reason) => {
    if (reason === 'input') {
      setQuery(value);
    } else if (reason === 'clear') {
      setSelected('');
      setQuery(name);
    }
  };

  const onChangeChosenOption = (event, value, reason) => {
    if (reason === 'select-option') {
      setSelected(value.id);
      setQuery(value.name + ' ' + value.email);
    } else if (reason !== 'create-option') {
      setSelected('');
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(x) => {
        return x.name + ' ' + x.email;
      }}
      options={options}
      filterOptions={(opt, _) => {
        return query && opt;
      }}
      inputValue={query}
      onChange={onChangeChosenOption}
      onInputChange={onChangeQuery}
      blurOnSelect={true}
      noOptionsText={'Search for the name of an event group'}
      renderInput={(params) => <TextField label={'Search group'} {...params} variant="outlined" />}
    ></Autocomplete>
  );
};

export default EventGroupsSearchBar;
