import React from 'react';
import PrivacyCollectingMinors from './components/PrivacyCollectingMinors';
import PrivacyInfoKeeping from './components/PrivacyInfoKeeping';
import PrivacyLastPoints from './components/PrivacyLastPoints';
import PrivacyRights from './components/PrivacyRights';
import PrivacyTrackingAndTransfer from './components/PrivacyTrackingAndTransfer';
import PrivacyUseOfInformation from './components/PrivacyUseOfInformation';
import PrivacyWhatInformationCollected from './components/PrivacyWhatInformationCollected';
import PrivacyWillItBeShared from './components/PrivacyWillItBeShared';
import PrivacyWithWho from './components/PrivacyWithWho';

const Privacy = () => (
  <>
    <h5>PRIVACY POLICY</h5>
    <p>
      <strong>Last updated January 1, 2022</strong>
    </p>
    <p>
      Thank you for choosing to use our system and services at THS International ("
      <strong>Company</strong>
      ", "<strong>we</strong>
      ", "<strong>us</strong>
      ", "<strong>our</strong>
      "). We are a student organization and we aim to use our energy and commitment to work in both fun and professional
      manners. As such, we are committed to protecting your personal information and your right to privacy. Consequently
      we also have a long privacy policy for you to read through :D As we are students at a technical university and
      hold no law degree, this privacy policy might not be 100% perfect. So, if you have any questions or concerns about
      this privacy notice, or our practices with regards to your personal information, please contact us at{' '}
      <a href="mailto:info@thsint.se">info@thsint.se</a>
      .&nbsp;
    </p>
    <p>
      When you visit our website <a href="http://www.event.thsint.se">http://www.event.thsint.se</a> (the "
      <strong>Website</strong>"), and more generally, use any of our services (the "<strong>Services</strong>
      ", which include the Website), we appreciate that you are trusting us with your personal information. We take your
      privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what
      information we collect, how we use it and what rights you have in relation to it. We hope you take some time to
      read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree
      with, please discontinue use of our Services immediately.
    </p>
    <p>
      This privacy notice applies to all information collected through our Services (which, as described above, includes
      our Website), as well as, any related services, sales, marketing or events.
    </p>
    <p>
      <strong>
        Please read this privacy notice carefully as it will help you understand what we do with the information that we
        collect.
      </strong>
    </p>
    <h6>TABLE OF CONTENTS</h6>
    <ol>
      <li>WHAT INFORMATION DO WE COLLECT?</li>
      <li>HOW DO WE USE YOUR INFORMATION?</li>
      <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
      <li>WHO WILL YOUR INFORMATION BE SHARED WITH?</li>
      <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
      <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
      <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
      <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
      <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
      <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
      <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
      <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
      <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
      <li>HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</li>
    </ol>
    <PrivacyWhatInformationCollected />
    <PrivacyUseOfInformation />
    <PrivacyWillItBeShared />
    <PrivacyWithWho />
    <PrivacyTrackingAndTransfer />
    <PrivacyInfoKeeping />
    <PrivacyCollectingMinors />
    <PrivacyRights />
    <PrivacyLastPoints />
  </>
);

export default Privacy;
