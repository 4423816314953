import React, { useState } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '/MuiTheme';
import { Control, Controller } from 'react-hook-form';
import locale from 'date-fns/locale/en-US';

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

/**
 * Default date validator, always exists in the ruleset
 */
const validateDate = (date: string | Date) => {
  if (date === null) return false;
  const dateObject = new Date(date);
  return !isNaN(dateObject.getTime()) && dateObject !== null;
};

// Creating a temporary MuiTheme to override Toolbar (apparently according to documentation, this is the only way so far)
const themeDate = createTheme({
  palette: {
    primary: Theme.palette.secondary,
  },
  overrides: {
    ...Theme.overrides,
    MuiOutlinedInput: {
      root: {
        paddingRight: '0 !important',
      },
    },
    MuiInputAdornment: {
      root: {
        marginLeft: '0px !important',
      },
    },
    MuiButtonBase: {
      root: {
        paddingLeft: '0px !important',
      },
    },
    MuiFormHelperText: {
      // this overrides the color for helper text
      root: {
        '&$error': {
          color: `${Theme.palette.error.main} !important`,
        },
      },
      error: {},
    },
  },
  typography: Theme.typography,
});

/**
 * two different error colors for when focused or not
 */
const useStyles = makeStyles((t: any) => ({
  errorNotFocused: {
    '& .Mui-error': {
      color: `${t.palette.error.main}`,
    },
    '& input': {
      color: `${t.palette.surface.text}${t.palette.other.opacityHigh}`,
    },
    '& .Mui-error::after': {
      borderBottomColor: `${t.palette.error.main}`,
    },
  },
  errorFocused: {
    '& .Mui-error': {
      color: `${t.palette.secondary.main}`,
    },
    '& input': {
      color: `${t.palette.surface.text}${t.palette.other.opacityHigh}`,
    },
    '& .Mui-error::after': {
      borderBottomColor: `${t.palette.secondary.main}`,
    },
  },
}));

interface DateComponentProps {
  control: Control<any, any>;
  name: string;
  label: string;
  defaultValue?: string | Date | null;
  variant?: 'standard' | 'outlined' | 'filled';
  rules?: any;
  style?: React.CSSProperties;
  disabled?: boolean;
  isDatetime?: boolean;
  helperText?: string;
}

/**
 * Renders a full-width Date component with secondary as palette
 *
 * @param name the variable name
 * @param control control object from useForm
 * @param label label of the input
 * @param defaultValue null or specified default value
 * @param variant default is outlined, can be 'standard', 'outlined', 'standard'
 * @param rules object of rules for RHF
 * @param disabled component in disabled mode or not
 */
const DateComponent: React.FC<DateComponentProps> = ({
  control,
  name,
  label,
  defaultValue = null,
  variant = 'outlined',
  rules = null,
  style = null,
  disabled = false,
  isDatetime = false,
  helperText = '',
}) => {
  const [focused, setFocused] = useState(false);

  const classes = useStyles();

  return (
    <ThemeProvider theme={themeDate}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || null}
          rules={
            rules
              ? {
                  ...rules,
                  validate: {
                    validateDate: (v) => (validateDate(v) ? true : 'Invalid date'),
                  },
                }
              : {
                  validate: {
                    validateDate: (v) => (validateDate(v) ? true : 'Invalid date'),
                  },
                }
          }
          render={({ field: { ref, onBlur, ...rest }, fieldState: { error } }) => (
            <>
              {!isDatetime ? (
                <KeyboardDatePicker
                  className={focused ? `${classes.errorFocused}` : `${classes.errorNotFocused}`}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  fullWidth
                  margin='normal'
                  inputVariant={variant || 'outlined'}
                  variant='inline'
                  label={label}
                  format='yy-MM-dd'
                  placeholder='yy-mm-dd'
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={error ? true : false}
                  helperText={error ? error.message : helperText}
                  disabled={disabled}
                  style={style || { marginBottom: '16px', marginTop: '16px' }}
                  color='primary'
                  {...rest}
                />
              ) : (
                <KeyboardDateTimePicker
                  className={focused ? `${classes.errorFocused}` : `${classes.errorNotFocused}`}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  fullWidth
                  margin='normal'
                  inputVariant={variant || 'outlined'}
                  variant='inline'
                  label={label}
                  format='yy-MM-dd hh:mm'
                  placeholder='yy-mm-dd hh:mm'
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={error ? true : false}
                  helperText={error ? error.message : helperText}
                  disabled={disabled}
                  style={style || { marginBottom: '16px', marginTop: '16px' }}
                  color='primary'
                  {...rest}
                />
              )}
            </>
          )}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DateComponent;
