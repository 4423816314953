import React from 'react';
import TextComponent from '/components/forms-v2/Text.component';

const ConfirmEmailInput = ({ control, getValues, label = 'Confirm E-mail' }) => {
  return (
    <TextComponent
      name='confirmEmail'
      control={control}
      label={label}
      rules={{
        required: { value: true, message: 'Confirm e-mail' },
        pattern: {
          value:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
          message: 'Invalid E-mail address',
        },
        validate: {
          emailsMatch: (v) => (v === getValues('email') ? true : 'E-mails does not match'),
        },
        maxLength: { value: 128, message: 'Too long input' },
      }}
    />
  );
};

export default ConfirmEmailInput;
