/**
 * sorts events by date
 *
 * @param {array} events list of events
 * @returns sorted events
 */
export const sortEventsByDate = (events) => {
  return events.sort((a, b) => b.date - a.date);
};

/**
 * sorts events by their checked status
 *
 * @param {array} events list of events
 * @param {array} checked checked list
 * @returns sorted events
 */
export const sortEventsByChecked = (events, checked) => {
  return events.sort((a, b) => {
    if (checked.includes(a.id) && !checked.includes(b.id)) {
      return -1;
    }
    if (!checked.includes(a.id) && checked.includes(b.id)) {
      return 1;
    }
    return 0;
  });
};
