import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarItem from './sidebarItem/SidebarItem';
import { List } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

/* 
  Sets background color to white and sticky so it sticks when user scrolls 
*/
const useStyles = makeStyles((t) => ({
  root: {
    backgroundColor: 'white',
    position: 'sticky',
    top: '40px',
  },
}));

//items hold value and label.
const Sidebar = ({ items, handler, sidebarComponent }) => {
  //is created because we need to keep track which tab is open
  const [activeSideItem, setActiveSideItem] = useState(0);

  //Sets active side item based on the url.
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!items) return;
    Object.values(items).forEach((value, index) => {
      if (location?.pathname.includes(value)) setActiveSideItem(index);
    });
  }, [location, location?.pathname, items]);

  const classes = useStyles();

  /*
    mapping the whole object to reduce code
  */
  return (
    <div className={classes.root}>
      {sidebarComponent || null}
      {items ? (
        <List dense className='list' onClick={handler}>
          {Object.keys(items).map((key, i) => (
            <SidebarItem
              key={i}
              name={key}
              id={i}
              active={activeSideItem}
              onClick={() => {
                setActiveSideItem(i);
                navigate(items[key]);
              }}
            />
          ))}
        </List>
      ) : null}
    </div>
  );
};

export default Sidebar;
