import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { userActions } from '/redux/actions/AuthActions';
import { useAppDispatch, useAppSelector } from 'redux/storeConfig';

const Logout = () => {
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const dispatch = useAppDispatch();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    dispatch(userActions.logout());
    setRedirect(true);
  }, []);

  return <>{redirect ? <Navigate to={`/${projectId}/`} /> : null}</>;
};

export default Logout;
