import React from 'react';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Table, TableBody, TableHead, TableRow, TableContainer } from '@material-ui/core';
import EventGroupsListHeader from '/components/eventGroupsList/EventGroupsListHeader.component';
import EventGroupBodyDesktop from '/components/eventGroupsList/EventGroupBodyDesktop.component';
import EventGroupBodyMobile from '/components/eventGroupsList/EventGroupBodyMobile.component';

const EventGroupsList = ({ groups, onDeleteGroup, onShowGroup }) => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));

  return !isMobile && groups !== [] ? (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow key={'tableHeaderRow'}>
            <EventGroupsListHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group) => (
            <EventGroupBodyDesktop
              groupName={group.name}
              appliedEvents={group.eventIDs.length}
              ticketLimit={group.ticketLimit}
              displayed={group.isBroadcasted}
              eventGroupId={group.id}
              onDeleteGroup={onDeleteGroup}
              onShowGroup={onShowGroup}
              key={group.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <React.Fragment>
      {groups.map((group) => (
        <EventGroupBodyMobile
          groupName={group.name}
          appliedEvents={group.eventIDs.length}
          ticketLimit={group.ticketLimit}
          displayed={group.isBroadcasted}
          eventGroupId={group.id}
          onDeleteGroup={onDeleteGroup} //Doesn't seem required on mobile.
          onShowGroup={onShowGroup}
          key={group.id}
        />
      ))}
    </React.Fragment>
  );
};

export default EventGroupsList;
