import React from 'react';

const PrivacyTrackingAndTransfer = () => {
  return (
    <>
      <h6>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h6>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>We may use cookies and other tracking technologies to collect and store your information.</em>
      </p>
      <p>
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
        information. Specific information about how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </p>
      <h6>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h6>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>We may transfer, store, and process your information in countries other than your own.</em>
      </p>
      <p>
        Our database servers are located in Western Europe, hosted by Microsoft Azure. If you are accessing our Website
        from outside Europe, please be aware that your information may be transferred to, stored, and processed by us in
        our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR
        INFORMATION BE SHARED WITH ANYONE?" above), in the United States, and other countries.
      </p>
      <p>
        If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not
        necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will
        however take all necessary measures to protect your personal information in accordance with this privacy notice
        and applicable law.
      </p>
      <p>European Commission's Standard Contractual Clauses:</p>
      <p>
        We have implemented measures to protect your personal information, including by using the European Commission's
        Standard Contractual Clauses for transfers of personal information between our group companies and between us
        and our third-party providers. These clauses require all recipients to protect all personal information that
        they process originating from the EEA or UK in accordance with European data protection laws and regulations.
        Our Standard Contractual Clauses can be provided upon request. We have implemented similar appropriate
        safeguards with our third-party service providers and partners and further details can be provided upon request.
      </p>
    </>
  );
};

export default PrivacyTrackingAndTransfer;
