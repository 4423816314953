import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 400,
  },
  subtitle: {
    color: '#00000099',
  },
  button: {
    padding: '4px',
  },
}));

type EventHeaderProps = {
  expanded?: boolean;
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  hidden?: boolean;
};

/**
 * Header for event card. Displays title and the 'more' or 'less' icon button
 */
const EventHeader = ({
  expanded = false,
  title = '',
  subtitle = '',
  onClick = () => null,
  hidden,
}: EventHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <div style={{ width: '80%' }}>
        <Typography className={classes.title} variant="h6" noWrap>
          {title}
        </Typography>
        <Typography className={classes.subtitle} variant="body2" noWrap>
          {subtitle}
        </Typography>
      </div>
      {!hidden && (
        <div>
          <IconButton color="secondary" className={classes.button} onClick={onClick}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default EventHeader;
