import React from 'react';

import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((t) => ({
  root: {
    padding: '8px',
    color: 'white',
    textAlign: 'center',
    [t.breakpoints.up('md')]: {
      padding: '16px',
    },
  },
}));

export interface PaymentBoxProps {
  unpaidAmount: number;
}

const PaymentBox: React.FC<PaymentBoxProps> = ({ unpaidAmount }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));

  const variantRender = () => (isMobile ? 'body2' : 'body1');

  const renderBackground = () => (unpaidAmount > 0 ? Theme.palette.warning.main : Theme.palette.success.main);

  const paidOrUnpaid = () => {
    if (unpaidAmount > 0)
      return (
        <>
          Please remember to pay for your events.
          <br />
          Unpaid amount: {unpaidAmount} SEK
        </>
      );
    return <div>You&apos;re good to go! You have nothing to pay for</div>;
  };

  return (
    <>
      <Card className={classes.root} style={{ backgroundColor: renderBackground() }}>
        <Typography variant={variantRender()}>{paidOrUnpaid()}</Typography>
      </Card>
    </>
  );
};

export default PaymentBox;
