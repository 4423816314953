import { ReactElement } from "react";

export type EditEventFormValues = {
  title: string;
  description: string;
  startDatetime: string;
  endDatetime: string;
  attendanceLimit: number;
  isNoAttendanceLimit: boolean;
  price: number;
  isFree: boolean;
  location: string;
  bookingStartDatetime: string;
  bookingEndDatetime: string;
  unbookingEndDatetime: string;
  swapEndDatetime: string;
};

export const editEventFormKeys = {
  title: 'title',
  description: 'description',
  startDatetime: 'startDatetime',
  endDatetime: 'endDatetime',
  attendanceLimit: 'attendanceLimit',
  isNoAttendanceLimit: 'isNoAttendanceLimit',
  price: 'price',
  isFree: 'isFree',
  location: 'location',
  bookingStartDatetime: 'bookingStartDatetime',
  bookingEndDatetime: 'bookingEndDatetime',
  unbookingEndDatetime: 'unbookingEndDatetime',
  swapEndDatetime: 'swapEndDatetime',
};

export type EditEventFormCategory = {
  label: string;
  path: string;
  element: ReactElement;
}