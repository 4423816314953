import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Content from '/components/content/Content';
import TabBar from '/components/tabs/Tabs';
import Footer from '/components/footer/Footer';
import FormContainer from '/components/formContainer/FormContainer';
import { connect } from 'react-redux';

import General from './general/General';
import Studies from './studies/Studies';
import Account from './account/Account';
import Preferences from './preferences/Preferences';

/**
 * Profile Page
 * contains:
 *  - General
 *  - Account
 *  - Studies
 *  - Preferences
 */
const Profile = (props) => {
  // categories in about page.
  const categories = {
    General: `/${props.projectId}/profile/general`,
    Account: `/${props.projectId}/profile/account`,
    Studies: `/${props.projectId}/profile/studies`,
    Preferences: `/${props.projectId}/profile/prefs`,
  };

  return (
    <>
      <TabBar tablist={categories} centeredUntil='xs' />
      <Content sidebar={categories}>
        <FormContainer>
          {
            <Routes>
              <Route path='/general' element={<General />} />
              <Route path='/account' element={<Account />} />
              <Route path='/studies' element={<Studies />} />
              <Route path='/prefs' element={<Preferences />} />
              <Route path='*' element={<Navigate to='general' />} />
            </Routes>
          }
        </FormContainer>
      </Content>
      <Footer name='IR' />
    </>
  );
};

// Redux map/actions
function mapStateToProps(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}
export default connect(mapStateToProps)(Profile);
