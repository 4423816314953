import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import TextComponent from '/components/forms-v2/Text.component';
import Button from '@material-ui/core/Button';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import FormContainer from '/components/formContainer/FormContainer';
import FormLogo from '/components/formLogo/FormLogo.component';

import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '/redux/actions/AuthActions';
import { snackActions } from '/redux/actions/FeedbackActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loginButton: {
    marginBottom: '16px',
    marginTop: '16px',
    width: '100%',
  },
}));

/**
 * Renders Login Page
 */
export const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const error = useSelector((state) => state.authentication.error);
  const projectInfo = useSelector((state) => state.projectInfo);
  const projectId = useSelector((state) => state.projectInfo.projectId);
  const { handleSubmit, control, setError } = useForm({ mode: 'all' });

  /**
   * Logout user if the user comes to this page
   */
  useEffect(() => {
    dispatch(userActions.logout());
  }, []);

  /**
   * Redirect user if student is already logged in
   * if user has signed project info, send to /events, or /projectInfo
   */
  useEffect(() => {
    let landingPage = `/`;
    if (loggedIn) {
      if (projectInfo?.signed) {
        landingPage = `/${projectId}/events`;
      } else {
        landingPage = `/${projectId}/projectInfo`;
      }

      dispatch(userActions.redirect(landingPage));
      navigate(landingPage);
    }
  }, [projectInfo]);

  /**
   * check if any error has occured
   * set error for respective fields.
   * if there is a server error, just open a snackbar
   */
  useEffect(() => {
    if (error) {
      const errorMessage = error.response?.data?.error?.message;

      switch (errorMessage) {
        case 'There is no account with this email':
          setError('email', {
            type: 'authentication',
            message: errorMessage,
          });
          break;
        case 'The provided email is not confirmed':
          setError('email', {
            type: 'authentication',
            message: errorMessage,
          });
          break;
        case 'Invalid password':
          setError('password', {
            type: 'authentication',
            message: errorMessage,
          });
          break;
        default:
          if (!errorMessage) {
            dispatch(snackActions.errorFeedback('Internal Server Error'));
            break;
          }
          dispatch(snackActions.errorFeedback(errorMessage));
          break;
      }
    }
  }, [error]);

  /**
   * Tries to log in the user with data provided
   * @param data data that gets passed from React Hook Form
   */
  const onSubmit = (data) => {
    dispatch(userActions.login(data.email, data.password));
  };

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormLogo />

            <TextComponent
              name="email"
              control={control}
              label="E-mail"
              rules={{
                required: { value: true, message: 'Enter e-mail' },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid E-mail address',
                },
              }}
            />

            <TextComponent
              name="password"
              control={control}
              label="Password"
              type="password"
              rules={{
                required: { value: true, message: 'Enter password' },
              }}
            />

            <Button
              className={classes.loginButton}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Login
            </Button>

            <Link to={`/${projectId}/forgot`} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="secondary" style={{ marginBottom: 8 }}>
                Forgot Password?
              </Typography>
            </Link>
            <Link to={`/${projectId}/CreateAccount`} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="secondary">
                Don’t have an account? Register here
              </Typography>
            </Link>
          </form>
        </FormContainer>
      </Content>
      <Footer name="IR" />
    </>
  );
};

export default Login;
