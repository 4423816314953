import React from 'react';

import { AdminHeader } from 'components/adminHeader';

const EventBookingHeader = () => (
  <AdminHeader
    isMobile
    title="Booking Settings"
    subtitle="Change settings for booking-related actions. All dates and times shown are in CE(S)T"
    disableDivider
  />
);

export default EventBookingHeader;
