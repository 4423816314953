import React from 'react';

import { Divider, Button } from '@material-ui/core';
import EventCard from './EventCardBase';
import EventStatistics from './EventStatistics';
import EventCategory from './EventCategory';
import EventDescription from './EventDescription';
import EventButton from './EventButton';

export const TYPE = {
  TICKET: 'unbook',
  BOOKED: 'my tickets',
  FULL: 'full',
  CLOSED: 'closed',
  BOOKABLE: 'book',
};

export enum EventStatus {
  TICKET = 'unbook',
  BOOKED = 'my tickets',
  FULL = 'full',
  CLOSED = 'closed',
  BOOKABLE = 'book',
}

export type UserEventCardProps = {
  title: string;
  isMobile: boolean;
  state: EventStatus;
  location: string;
  attendance: number;
  price: number;
  startTime: Date;
  endTime: Date;
  transferEndTime: Date;
  description: string;
  categories: string[];
  booking: boolean;
  handleButton: () => void;
  handleTransfer: () => void;
  buttonDisabled: boolean;
};

/**
 * Displays an event card with customizable content
 */
export const UserEventCard = ({
  title,
  isMobile,
  state,
  location,
  attendance,
  price,
  startTime,
  endTime,
  transferEndTime,
  description,
  categories,
  booking,
  handleButton,
  handleTransfer,
  buttonDisabled,
}: UserEventCardProps) => {
  const isDisabled = state === TYPE.CLOSED || state === TYPE.FULL;
  const isActive = state === TYPE.BOOKED || state === TYPE.TICKET;
  const transferDisabled = new Date() > transferEndTime;

  return (
    <EventCard
      title={title}
      isMobile={isMobile}
      disabled={isDisabled}
      active={isActive}
      statistics={
        <EventStatistics isMobile={isMobile} location={location} attendanceLimit={attendance} startTime={startTime} endTime={endTime} price={price} />
      }
    >
      <EventDescription description={description} />
      <Divider style={{ marginTop: '16px' }} />
      <EventCategory isMobile={isMobile} categories={categories} disabled={isDisabled} active={isActive} />
      <Divider style={{ marginTop: '8px' }} />
      <div
        style={{
          marginTop: '16px',
          marginBottom: !isMobile ? '4px' : '0',
        }}
      >
        <EventButton
          disabled={isDisabled || booking || buttonDisabled}
          booked={state === TYPE.TICKET}
          style={{ minWidth: '124px', marginRight: '14px' }}
          onClick={handleButton}
          label={booking ? 'booking' : state}
        />
        {state === TYPE.TICKET && (
          <Button variant="contained" style={{ minWidth: '124px' }} onClick={handleTransfer} color="secondary" disabled={transferDisabled}>
            Transfer
          </Button>
        )}
      </div>
    </EventCard>
  );
};
