import React from 'react';

import UserDeleteModal from '/containers/adminAccess/userDeleteModal/UserDeleteModal';
import SearchBarContainer from '/containers/adminAccess/searchBar/SearchBar';
import AdminAccessTable from '/containers/adminAccess/table/AdminAccessTable';
import { Typography, Divider } from '@material-ui/core';
import TablePagination from '/containers/adminAccess/tablePagination/TablePagination';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Theme from '/MuiTheme';

const Access = () => {
  return (
    <>
      <UserDeleteModal />
      <Typography variant={useMediaQuery(Theme.breakpoints.up('sm')) ? 'h4' : 'h5'} style={{ margin: '16px auto' }}>
        Access Management
      </Typography>
      <Typography variant={useMediaQuery(Theme.breakpoints.up('sm')) ? 'body1' : 'body2'}>
        Change permissions of users in the project. Higher levels increase access to more personal data of all users.
      </Typography>
      <Divider style={{ margin: '16px auto' }} />
      <SearchBarContainer />
      <AdminAccessTable />
      <TablePagination />
    </>
  );
};

export default Access;
