import React from 'react';
import { CheckboxEvent } from './CheckboxEvent';
import { makeStyles } from '@material-ui/core/styles';
import { sortEventsByDate, sortEventsByChecked } from './utils';

const useStyles = makeStyles({
  root: {
    height: (props) => `${props.listHeight}px`,
    overflow: 'scroll',
  },
  desktopRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  list: {
    padding: (props) => (props.isMobile ? '0px 8px' : '0px 16px'),
    width: (props) => (props.isMobile ? '' : '45%'),
  },
  desktop: {
    flex: '1',
    width: '50%',
  },
  border: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
});

/**
 * This component is used to render a list of checkbox events.
 * it sorts list by ones that are checked.
 */
export const CheckboxEventList = ({
  checked = [],
  events = [],
  onClick = (eventId) => null,
  isMobile = false,
  listHeight = 300,
  isEditing = false,
}) => {
  const classes = useStyles({ isMobile, listHeight });

  // sort by ones that have checked first
  const sortedEvents = sortEventsByChecked(sortEventsByDate(events), checked);

  /**
   * Renders a checkbox event.
   *
   * @param {object} event event to render
   * @returns an event
   */
  const renderCheckboxEvent = (event) => {
    return (
      <CheckboxEvent
        name={event.name}
        checked={checked.includes(event.id)}
        onChange={() => onClick(event.id)}
        isMobile={isMobile}
        date={event.date}
        disabled={!isEditing}
      />
    );
  };

  /**
   * Renders a checkbox event cell that is used
   * for desktop rendering.
   *
   * @param {*} event event to render
   * @param {*} index index of event
   * @param {*} lineLength index of row
   * @param {*} ITEMS_PER_ROW max items per row
   * @returns a desktop checkbox event cell
   */
  const renderCheckboxEventCell = (event, index, lineLength, ITEMS_PER_ROW) => {
    return (
      <div
        key={`event-index-${index}`}
        className={`
          ${classes.list} 
          ${classes.desktop}
          ${lineLength + 1 < ITEMS_PER_ROW && classes.border}
        `}
      >
        {event}
      </div>
    );
  };

  /**
   * renders a checkbox event row for desktop
   * view.
   *
   * @param {*} renderLines length of render lines
   * @param {*} lineToRender row to render
   * @returns
   */
  const renderCheckboxEventRow = (renderLinesLength, lineToRender) => {
    return (
      <div key={renderLinesLength} className={classes.desktopRow}>
        {lineToRender}
        {
          // makes sure that the last element is not spanning over whole width.
          lineToRender.length == 1 && <div className={`${classes.list} ${classes.desktop}`} />
        }
      </div>
    );
  };

  /**
   * Renders events horizontally two by two
   *
   * @param {*} events list of events
   * @returns list of events two by two
   */
  const renderDesktop = (events) => {
    let renderLines = [];
    let currentLine = [];
    const ITEMS_PER_ROW = 2;

    events.forEach((event, index) => {
      currentLine.push(renderCheckboxEventCell(renderCheckboxEvent(event), index, currentLine.length, ITEMS_PER_ROW));

      if (currentLine.length == ITEMS_PER_ROW || index + 1 === events.length) {
        renderLines.push(renderCheckboxEventRow(renderLines.length, currentLine));
        currentLine = [];
      }
    });

    return <div>{renderLines}</div>;
  };

  /**
   * Renders events in a single list
   *
   * @param {*} events list of events
   * @returns events in a single list
   */
  const renderMobile = (events) => {
    return (
      <div className={classes.list}>
        {events.map((event, index) => (
          <div key={index}>{renderCheckboxEvent(event)}</div>
        ))}
      </div>
    );
  };

  return <div className={classes.root}>{isMobile ? renderMobile(sortedEvents) : renderDesktop(sortedEvents)}</div>;
};
