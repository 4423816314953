import React from 'react';
import pic from '/static/logo_500.png';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(() => ({
  logoCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '14px',
    width: '95px',
  },
  textCenter: {
    textAlign: 'center',
    lineHeight: '28px',
  },
  generalInfo: {
    marginTop: '16px !important',
    marginBottom: '16px !important',
  },
}));

interface FormLogoProps {
  dividerStyle?: React.CSSProperties;
}

const FormLogo: React.FC<FormLogoProps> = ({ dividerStyle }) => {
  const classes = useStyles();
  return (
    <>
      <img src={pic} className={classes.logoCenter} alt="ths-logo" />
      <p className={classes.textCenter}>
        THS International Reception
        <br />
        Spring 2023
      </p>
      <Divider style={dividerStyle} />
    </>
  );
};

export default FormLogo;
