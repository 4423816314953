import React from 'react';

const PrivacyWillItBeShared = () => {
  return (
    <>
      <h6>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h6>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We only share information with your consent, to comply with laws, to provide you with services, to protect
          your rights, or to fulfill business obligations.
        </em>
      </p>
      <p>We may process or share your data that we hold based on the following legal basis:</p>
      <ul>
        <li>
          <strong>Consent: </strong>
          We may process your data if you have given us specific consent to use your personal information for a specific
          purpose.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Legitimate Interests: </strong>
          We may process your data when it is reasonably necessary to achieve our legitimate business interests.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Performance of a Contract: </strong>
          Where we have entered into a contract with you, we may process your personal information to fulfill the terms
          of our contract.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Legal Obligations: </strong>
          We may disclose your information where we are legally required to do so in order to comply with applicable
          law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a
          court order or a subpoena (including in response to public authorities to meet national security or law
          enforcement requirements).
        </li>
      </ul>
      <ul>
        <li>
          <strong>Vital Interests: </strong>
          We may disclose your information where we believe it is necessary to investigate, prevent, or take action
          regarding potential violations of our policies, suspected fraud, situations involving potential threats to the
          safety of any person and illegal activities, or as evidence in litigation in which we are involved.
        </li>
      </ul>
      <p>
        More specifically, we may need to process your data or share your personal information in the following
        situations:
      </p>
      <ul>
        <li>
          <strong>Business Transfers. </strong>
          We may share or transfer your information in connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our business to another company.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Vendors, Consultants and Other Third-Party Service Providers. </strong>
          We may share your data with third-party vendors, service providers, contractors or agents who perform services
          for us or on our behalf and require access to such information to do that work. Examples include: payment
          processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may
          allow selected third parties to use tracking technology on the Website, which will enable them to collect data
          on our behalf about how you interact with our Website over time. This information may be used to, among other
          things, analyze and track data, determine the popularity of certain content, pages or features, and better
          understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your
          information with third parties for their promotional purposes. We have contracts in place with our data
          processors, which are designed to help safeguard your personal information. This means that they cannot do
          anything with your personal information unless we have instructed them to do it. They will also not share your
          personal information with any organization apart from us. They also commit to protect the data they hold on
          our behalf and to retain it for the period we instruct.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Affiliates. </strong>
          We may share your information with our affiliates, in which case we will require those affiliates to honor
          this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners or
          other companies that we control or that are under common control with us.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Business Partners. </strong>
          We may share your information with our business partners to offer you certain products, services or
          promotions.
        </li>
      </ul>
    </>
  );
};

export default PrivacyWillItBeShared;
