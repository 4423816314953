import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
  booked: {
    backgroundColor: t.palette.error.main,
    '&:hover': {
      backgroundColor: t.palette.error.dark,
    },
  },
}));

type EventButtonProps = {
  booked: boolean;
  label: string;
  [key: string]: any;
};

const EventButton = ({ booked, label, ...props }: EventButtonProps) => {
  const classes = useStyles();

  return (
    <Button color="secondary" className={booked ? classes.booked : undefined} variant="contained" {...props}>
      {label}
    </Button>
  );
};

export default EventButton;
