import React, { useState, useEffect } from 'react';
import { Divider, Typography } from '@material-ui/core';
import SearchBarContainer from '/containers/adminUsers/searchBar/SearchBarContainer';
import GeneralSettings from '/containers/adminUsers/generalSettings/GeneralSettingsContainer';
import Theme from '/MuiTheme';
import EventsOverview from '/containers/adminUsers/EventsOverview.container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BookingsContainer from '/containers/adminUsers/Bookings.container';
import BookingsList from '/containers/adminUsers/BookingsList.container';
import AddBookingContainer from '/containers/adminUsers/AddBooking.containter';
import AddBookingButton from '/containers/adminUsers/AddBookingButton.container';
import { TicketTransferContainer } from '/containers/adminUsers/TicketTransfer.container';

const Users = () => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));

  return (
    <>
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        style={isMobile ? { marginTop: '16px', marginBottom: '10px' } : null}
      >
        Users Overview
      </Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} style={{ marginTop: 0, marginBottom: '12px' }}>
        Search for users and check them in, view and update event bookings.
      </Typography>
      <Divider style={{ marginBottom: '16px' }} />
      <SearchBarContainer />
      <GeneralSettings />
      <EventsOverview />
      <BookingsContainer />
      <BookingsList />
      <AddBookingContainer />
      <AddBookingButton />
      <TicketTransferContainer />
    </>
  );
};

export default Users;
