import React, { useEffect } from 'react';
import Content from '/components/content/Content';
import { useNavigate } from 'react-router-dom';
import { GetUpdateEmail } from '/data/api/AuthRequest';
import Footer from '/components/footer/Footer';
import { useAppSelector } from 'redux/storeConfig';
import Form from './components/Form';

const PatchEmail = () => {
  const navigate = useNavigate();
  const projectId = useAppSelector((state) => state.projectInfo.projectId);

  const handleClick = () => {
    navigate(`/${projectId}/login`);
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) {
      return;
    }
    GetUpdateEmail(token).catch(() => {});
  }, []);

  return (
    <>
      <Content greyBackground>
        <Form handleClick={handleClick} />
      </Content>
      <Footer />
    </>
  );
};

export default PatchEmail;
