import React from 'react';

import { TableRow } from '@material-ui/core';

import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';
import NameEmail from './NameEmail';
import AttendingButton from './AttendingButton';
import PaidButton from './PaidButton';
import OptionsMenu from './OptionsMenu';
import { UsersEntryRow } from './CommonTypes';
import CheckedIn from './CheckedIn';

const UsersTableRow: React.FC<UsersEntryRow> = ({ name, attending, paid, options, isCheckedIn }) => (
  <TableRow>
    <CustomTableCell>
      <NameEmail
        firstName={name.firstName}
        lastName={name.lastName}
        email={name.email}
        copyToClipboard={name.copyToClipboard}
      />
    </CustomTableCell>
    <CustomTableCell>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CheckedIn displayText checkedIn={isCheckedIn} />
      </div>
    </CustomTableCell>
    <CustomTableCell>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AttendingButton isAttending={attending.isAttending} onClick={attending.onClick} />
      </div>
    </CustomTableCell>
    <CustomTableCell>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PaidButton isPaid={paid.isPaid} isFree={paid.isFree} onClick={paid.onClick} />
        <OptionsMenu onDeleteClick={options.onDeleteClick} onTransferClick={options.onTransferClick} />
      </div>
    </CustomTableCell>
  </TableRow>
);

export default UsersTableRow;
