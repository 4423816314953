import * as Backend from '/data/api/BackendRequest';
import {
  AdminDetailUserResponse,
  BackendEvent,
  BookedUser,
  ChangeEvent,
  EventBookingStats,
  PatchEvent,
  UserWithTicket,
} from './AdminEventQueries.types';

interface ProjectIdProps {
  projectId: string;
}

export const getAdminEvents = async ({ projectId }: ProjectIdProps) => {
  const resp = await Backend.request('GET', `/admin/${projectId}/events`);
  return resp?.data?.data;
};

export const getAdminEventStats = async ({ projectId }: ProjectIdProps): Promise<EventBookingStats[]> => {
  const resp = await Backend.request<EventBookingStats[]>('GET', `/admin/${projectId}/event/bookingstats`);
  if (!resp?.data?.data) {
    return [];
  }
  return resp.data.data;
};

export const getAdminEventFilter = async ({ projectId }: ProjectIdProps) => {
  const resp = await Backend.request('GET', `/admin/${projectId}/event-filter`);
  return resp?.data?.data;
};

export const getAdminEventGroup = async ({ projectId }: ProjectIdProps) => {
  const resp = await Backend.request('GET', `/admin/${projectId}/event-group`);
  return Object.values(resp?.data?.data);
};

export const patchAdminEvent = async (pid: string, eventId: number, patchEvent: PatchEvent) => {
  const changeEvent: ChangeEvent = {
    eventId,
    change: patchEvent,
    eventFilters: null,
    eventGroups: null,
  };
  await Backend.request('PATCH', `/event/${pid}`, {}, changeEvent);
};

interface UpdateBookingForm {
  isPaid: boolean;
  isAttending: boolean;
}

export const updateUserBooking = async (pid: number, bookingId: number, form: UpdateBookingForm) => {
  await Backend.request('PATCH', `/admin/${pid}/booking/${bookingId}`, {}, form);
};

export const deleteUserBooking = async (pid: number, bookingId: number) => {
  await Backend.request('DELETE', `/admin/${pid}/ticket/${bookingId}`, {});
};

interface IGetAdminEvent {
  projectId: string;
  eventId: string;
}

export const getAdminEvent = async ({ projectId, eventId }: IGetAdminEvent): Promise<BackendEvent> => {
  const resp = await Backend.request<BackendEvent[]>('GET', `/admin/${projectId}/events`);
  const foundEvent: BackendEvent | null | undefined = resp?.data?.data?.find(
    (event) => event.eventId === parseInt(eventId, 10)
  );
  if (!foundEvent) throw new Error('Event not found');
  return foundEvent;
};

export const getAdminEventBookings = async ({ projectId, eventId }: IGetAdminEvent): Promise<BookedUser[]> => {
  const resp = await Backend.request<UserWithTicket[]>('GET', `/admin/${projectId}/event/status/${eventId}`);
  const bookedUsers: BookedUser[] = await Promise.all(
    resp.data.data.map(async (ticket) => {
      const user = await Backend.request<AdminDetailUserResponse>(
        'GET',
        `/admin/${projectId}/user/${ticket.accountId}`
      );
      const checkedIn = await Backend.request<boolean>('GET', `/admin/${projectId}/user/checkin/${ticket.accountId}`);
      return {
        id: ticket.accountId,
        firstName: user.data.data.AccountInfo.firstName,
        lastName: user.data.data.AccountInfo.lastName,
        email: user.data.data.AccountInfo.email,
        checkedIn: checkedIn.data.data,
        isAttending: ticket.isAttending,
        isPaid: ticket.isPaid,
        bookingId: ticket.bookingId,
      };
    })
  );

  return bookedUsers;
};

export const getAdminEventBookingsQuery = ({ projectId, eventId }: IGetAdminEvent) => ({
  queryKey: ['getAdminEventBookings', eventId],
  queryFn: () => getAdminEventBookings({ projectId, eventId }),
  enabled: !!projectId,
});

export const getAdminEventQuery = ({ projectId, eventId }: IGetAdminEvent) => ({
  queryKey: ['getAdminEvent', eventId],
  queryFn: () => getAdminEvent({ projectId, eventId }),
  enabled: !!projectId,
});

export const getAdminEventsQuery = ({ projectId }: ProjectIdProps) => ({
  queryKey: ['getAdminEvents'],
  queryFn: () => getAdminEvents({ projectId }),
  enabled: !!projectId,
});

export const getAdminEventStatsQuery = ({ projectId }: ProjectIdProps) => ({
  queryKey: ['getAdminEventStats'],
  queryFn: () => getAdminEventStats({ projectId }),
  enabled: !!projectId,
});

export const getAdminEventFilterQuery = ({ projectId }: ProjectIdProps) => ({
  queryKey: ['getAdminEventFilter'],
  queryFn: () => getAdminEventFilter({ projectId }),
  enabled: !!projectId,
});

export const getAdminEventGroupQuery = ({ projectId }: ProjectIdProps) => ({
  queryKey: ['getAdminEventGroup'],
  queryFn: () => getAdminEventGroup({ projectId }),
  enabled: !!projectId,
});
