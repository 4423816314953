import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Content from '/components/content/Content';
import TabBar from '/components/tabs/Tabs';
import Footer from '/components/footer/Footer';

import { useAppSelector } from 'redux/storeConfig';
import AboutUs from './aboutus/AboutUs';
import Terms from './terms/Terms';
import Privacy from './privacy/Privacy';
import Additional from './additional/Additional';

/**
 * About Us Page.
 * contains:
 *  - About Us - IT Team
 *  - Terms and conditions
 *  - Privacy policy
 *  - Additional communication
 */
const About = () => {
  const projectId = useAppSelector((state) => state.projectInfo.projectId);

  // categories in about page.
  const categories = {
    'About Us': `/${projectId}/about/aboutus`,
    'Terms and Conditions': `/${projectId}/about/terms`,
    'Privacy Policy': `/${projectId}/about/privacy`,
    'Additional Communication': `/${projectId}/about/additional`,
  };

  return (
    <>
      <TabBar tablist={categories} centeredUntil={undefined} />
      <Content sidebar={categories}>
        <Routes>
          <Route path="/aboutus" element={<AboutUs projectId={projectId} />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/additional" element={<Additional />} />
          <Route path="*" element={<Navigate to="aboutusaosind" />} />
        </Routes>
      </Content>
      <Footer />
    </>
  );
};

export default About;
