import React, { useState } from 'react';

import { CircularProgress, Divider, Table, TableBody, useMediaQuery } from '@material-ui/core';
import UsersTableHeader from 'components/eventManageUsers/UsersTableHeader';
import UsersTableRow from 'components/eventManageUsers/UsersTableRow';
import { BackendEvent } from 'queries/AdminEventQueries.types';
import { useAppDispatch, useAppSelector } from 'redux/storeConfig';
import { snackActions } from 'redux/actions/FeedbackActions';
import { UsersEntryRow } from 'components/eventManageUsers/CommonTypes';
import theme from 'MuiTheme';
import UsersMobileRow from 'components/eventManageUsers/UsersMobileRow';
import { AdminHeader } from 'components/adminHeader';
import SearchBar from 'components/searchBar/SearchBar';
import useGetBookings from '../hooks/useGetBookings';
import useUpdateBooking from '../hooks/useUpdateBooking';
import useRemoveBooking from '../hooks/useRemoveBooking';

interface BookedUsersProps {
  eventId: string;
  eventData: BackendEvent;
}

const BookedUsers: React.FC<BookedUsersProps> = ({ eventId, eventData }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const bookingData = useGetBookings(eventId);
  const { changeBooking } = useUpdateBooking(bookingData.updateBooking);
  const { deleteBooking } = useRemoveBooking(bookingData.removeBooking);

  const [searchValue, setSearchValue] = useState('');

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(snackActions.successFeedback('Copied to clipboard'));
  };

  if (bookingData.isLoading || bookingData.isError || !bookingData.data)
    return <CircularProgress style={{ marginTop: '16px' }} color="secondary" />;

  const filteredBookingData =
    searchValue.length > 0
      ? bookingData.data.filter((booking) => booking.id.toString() === searchValue)
      : bookingData.data;

  const bookings: UsersEntryRow[] = filteredBookingData.map((booking) => ({
    name: {
      firstName: booking.firstName,
      lastName: booking.lastName,
      email: booking.email,
      copyToClipboard: () => copyToClipboard(booking.email),
    },
    attending: {
      isAttending: booking.isAttending,
      onClick: () => changeBooking(booking.bookingId, !booking.isAttending, booking.isPaid),
    },
    paid: {
      isPaid: booking.isPaid,
      isFree: eventData.basePriceSek === 0,
      onClick: () => changeBooking(booking.bookingId, booking.isAttending, !booking.isPaid),
    },
    options: {
      onDeleteClick: () => deleteBooking(booking.bookingId),
      onTransferClick: () => {},
    },
    isCheckedIn: booking.checkedIn,
  }));

  return (
    <>
      <div style={{ marginTop: '0.5rem' }} />
      <AdminHeader
        small
        isMobile
        title="Bookings"
        subtitle={isMobile ? 'Tap events to change their status.' : ''}
        disableDivider
      />
      <SearchBar setSelected={(value: number) => setSearchValue(value.toString())} projectId={projectId} />
      {isMobile && <Divider style={{ marginTop: '1rem' }} />}
      {isMobile ? (
        <div style={{ marginTop: '1rem' }}>
          {bookings.map((booking) => (
            <UsersMobileRow key={`booking--${booking.name.email}`} {...booking} />
          ))}
        </div>
      ) : (
        <Table>
          <UsersTableHeader />
          <TableBody>
            {bookings.map((booking) => (
              <UsersTableRow key={`booking--${booking.name.email}`} {...booking} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default BookedUsers;
