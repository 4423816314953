import React from 'react';

import { makeStyles } from '@material-ui/core';
import CheckComponent from '/components/forms-v2/Check.component';
import ButtonComponent from '/components/forms-v2/Button.component';
import FormLogo from '/components/formLogo/FormLogo.component.tsx';

const useStyles = makeStyles((t) => ({
  logoCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '14px',
    width: '95px',
  },
  textCenter: {
    textAlign: 'center',
    lineHeight: '28px',
  },
  generalInfo: {
    marginTop: '16px !important',
    marginBottom: '16px !important',
  },
}));

const PageOne = ({ control, onSubmit }) => {
  const classes = useStyles();

  return (
    <div style={{ margin: '16px 8px' }}>
      <FormLogo dividerStyle={{ maxWidth: '900px', margin: '0 auto', color: 'green' }} />
      <h5>THS International Reception Spring 2023 Rules</h5>
      <p>
        By using our signup page and participating in the THS International Reception Spring 2023, you agree and
        acknowledge the following rules and information based on the following three sections: who can participate,
        signing up for and participating in events, and data handling. &nbsp;
      </p>
      <h6>Who can participate</h6>
      <p>
        The THS International Reception is for incoming international students, newly admitted master and PhD students
        at KTH and newly admitted students to joint programs of KTH, that arrive for the start of the Spring Semester
        2023 only. Other students cannot participate. The final decision of eligibility to participate is made by the
        Project Managers and cannot be challenged.
      </p>
      <h6>Booking and participating in events</h6>
      <p>
        Do not book events that collide with each other or with other school activities or information sessions
        timewise. Remember that school activities should always have priority when you plan. The International Reception
        is run independently from KTH’s planning and therefore some schedule collisions can happen. Participating in
        events of the Reception is completely voluntary. You have to choose which event you want to attend. Furthermore,
        events can be booked up to 48 hours before an event starts. If you cannot attend an event it is important and
        necessary to cancel your booked ticket in the Signup System. This is possible up to 24 hours before the event
        starts.
      </p>
      <p>
        During the Spring 2023 Reception, check-in desks will be held regularly where you will be able to pick up your
        THS International Reception wristband, which you will need to wear for all the events that you're attending. You
        must pick it up <strong>yourself</strong> even if you don't need to pay for events;{' '}
        <strong>without the wristband, you won&rsquo;t be able to attend any event</strong>. Make sure to not lose or
        cut it! It must remain on your wrist for the entire THS International Reception period. You will also be able to
        pay for your events at the check-in desks. You can also delegate someone else to pay for you. Failing to collect
        your wristband or not paying before your events may result in unbooking of all your consecutive events. During
        the Spring 2023 Reception, for paid events, payment desks will be held regularly and information about when they
        will be held will be posted in the{' '}
        <a href="https://www.facebook.com/groups/irvt23">KTH International Students Spring 2023 Facebook group</a>, in
        addition to the check-in desk happening on the Arrival Day.&nbsp;
      </p>
      <p>
        You must follow the rules of the event/activity. This is for your own safety and other students’ safety. THS
        International Reception holds no liability for any accidents or damages, such as, but not limited to, monetary
        loss, property damage and personal injury, occurring during any activity in the context of the International
        Reception. Failing to follow these rules or if your behaviour at an event is considered rude, discriminatory,
        problematic or making others uncomfortable, we will ask you to leave. We ask you to respect other guests, the
        culture and the event organisers. The International Reception reserves the right to ban you permanently or
        remove you from one or all events of the Reception for any given reason. This decision is made by the Project
        Managers and cannot be challenged. If you witness anything that can be considered rude, discriminatory,
        problematic, or making others uncomfortable, please report this in writing by sending us an email to
        <a href="mailto:info@thsint.se">info@thsint.se</a> about it. If the Project Managers decide to ban you from the
        Reception, you will <strong>NOT </strong>get a refund on paid tickets.
      </p>
      <p>
        Unless decided otherwise by the Project Managers of the Reception, refunds for paid events are{' '}
        <strong>NOT</strong> possible.
      </p>
      <p>
        Instead, <strong>ticket transfers</strong> are allowed and encouraged. For these to be confirmed, the seller of
        the ticket must perform the ticket transfer on the signup system by entering information of the buyer (Last name
        and email address). The email has to be the one that the buyer has used to register to the signup system. Please
        note that transferring your ticket to another user will remove your status as ticket holder, and the transfer
        cannot be undone once it has been confirmed on the signup system.{' '}
      </p>
      <p>
        We encourage you not to send money to the seller before receiving confirmation of the ticket transfer via email.
        Please note that payment related to the transfer must be handled between the buyer and the seller, independently
        of the International Reception, and that the buyer has to have collected their wristband from a check-in desk.
        The International Reception is <b>not</b> to be held accountable for payments resulting from or related to
        ticket transfers.
      </p>
      <p>
        Please note that refunds on paid tickets can be exceptionally made due to medical situations that might warrant
        you to not take part in an event. Any intention of receiving a refund must be communicated to us through writing
        by sending an email to <a href="mailto:refunds@thsint.se">refunds@thsint.se</a>. The circumstances of the refund
        will be addressed individually on a case-by-case basis, and the International Reception reserves the right to
        decline refund requests if deemed invalid. We might require you to provide us with further information regarding
        the situation. We might also remove you from further events if we decide your situation makes you unable to
        attend them for said reason.
      </p>
      <h6>Data handling</h6>
      <p>
        The Signup System used by us has its own dedicated privacy policy. In addition to that, we automatically export
        data from the Signup System to Google Workspace for our work. Google Workspace is a Cloud Service by Google
        Inc., where we store data according to their terms &amp; conditions. Therefore, it may happen that some of your
        data is stored on servers outside of the European Union.
      </p>
      <p>
        If we co-host an event with other organisations, relevant data will be given to them, so we can organise the
        event properly with them. Your email will not be given out to any third parties unless otherwise stated. We will
        forward you relevant emails from them if needed. We keep the data after the Reception to evaluate the signup, so
        we can improve it for the future. This is for statistical purposes only; we do not look into individuals. If you
        would like to opt out, please write an email to <a href="mailto:info@thsint.se">info@thsint.se</a>. We will
        erase you from the database, and you will not be allowed to participate in any Reception activities anymore.
      </p>
      <h6>Other</h6>
      <p>
        By booking our events, you allow THS International to use photos/videos taken of you from the event for
        marketing purposes.
      </p>
      <p>
        In case of force majeure, the Project Team can cancel any event in order to guarantee the safety of everyone.
        However, you will be notified of this decision as early as possible.
      </p>
      <p>Please note that the rules above can be changed at any time without prior notification.</p>
      <CheckComponent
        name="isTosAgreed"
        control={control}
        rules={{
          validate: {
            isTrue: (v) => (v === true ? true : 'You must accept'),
          },
        }}
        noMargin
        label="I agree with the project rules"
      />
      <div style={{ float: 'right', marginBottom: 40 }}>
        <ButtonComponent click={onSubmit} fixedWidth label="Next" />
      </div>
    </div>
  );
};

export default PageOne;
