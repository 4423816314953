import React, { useState, useEffect } from 'react';
import * as Backend from '/data/api/BackendRequest.ts';
import ThemeScheme from '/components/themeScheme/ThemeScheme';
import Settings from '/components/settings/Settings';
import { Button, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';

import './project.scss';

const Project = ({ projectId }) => {
  const theme = useTheme();
  const [primaryColor, setPrimaryColor] = useState(theme.palette.primary.main);
  const [secondaryColor, setSecondaryColor] = useState(theme.palette.secondary.main);

  const [settings, setSettings] = useState({});

  useEffect(() => {
    Backend.request('get', `/user/project-info/${projectId}`, {}).then((response) => setSettings(response.data.data));
  }, []);

  const updateSettings = () => {
    Backend.request('patch', `admin/${projectId}/project/${projectId}`, {}, settings);
  };

  const resetColors = () => {
    setPrimaryColor(theme.palette.primary.main);
    setSecondaryColor(theme.palette.secondary.main);
  };

  return (
    <div className="indexContainer">
      Project settings
      <ThemeScheme
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        setPrimaryColor={setPrimaryColor}
        setSecondaryColor={setSecondaryColor}
      />
      <Settings settings={settings} setSettings={setSettings} />
      <div className="projectUpdateButtons">
        <Button onClick={() => resetColors()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => updateSettings()} variant="contained" color="primary">
          Update settings
        </Button>
      </div>
    </div>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(Project);
