import React from 'react';

const PrivacyCollectingMinors = () => {
  return (
    <>
      <h6>9. DO WE COLLECT INFORMATION FROM MINORS?</h6>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>We do not knowingly collect data from or market to children under 18 years of age.</em>
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you
        represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
        minor dependent&rsquo;s use of the Website. If we learn that personal information from users less than 18 years
        of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such
        data from our records. If you become aware of any data we may have collected from children under age 18, please
        contact us at info@thsint.se.
      </p>
    </>
  );
};

export default PrivacyCollectingMinors;
