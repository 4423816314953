import { makeStyles } from '@material-ui/core';

const useBookingStyles = makeStyles(() => ({
  inputContainer: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    margin: '16px 0px',
  },
}));

export default useBookingStyles;
