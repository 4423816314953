import React, { useEffect, useState } from 'react';
import Content from '/components/content/Content';
import { useNavigate } from 'react-router-dom';
import { GetConfirmation } from '/data/api/AuthRequest';
import FormLogo from '/components/formLogo/FormLogo.component';
import FormContainer from '/components/formContainer/FormContainer';
import Footer from '/components/footer/Footer';
import { useAppSelector } from 'redux/storeConfig';
import SuccessEmail from './components/SuccessEmail';
import ErrorEmail from './components/ErrorEmail';

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const projectId = useAppSelector((state) => state.projectInfo.projectId);

  const [error, setError] = useState<string>('');

  const handleClick = () => {
    navigate(`/${projectId}/login`);
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) {
      setError('There was an error confirming your E-mail.');
      return;
    }
    GetConfirmation(token).catch(() => {
      setError('There was an error confirming your E-mail.');
    });
  }, []);

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          {!error.length ? <SuccessEmail handleClick={handleClick} /> : <ErrorEmail errorMessage={error} />}
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default ConfirmEmail;
