import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/storeConfig';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getListItems = (categories) => {
  const listItems = [];
  for (var key in categories) {
    if (categories.hasOwnProperty(key)) {
      listItems.push({
        label: key,
        link: categories[key],
      });
    }
  }

  return listItems;
};

/* 
  Apparently, the tabs documentation on colors messed up.
  maybe a universal text system later?
*/
const useStyles = makeStyles((t) => ({
  root: {
    backgroundColor: t.palette.primary.main,
    color: `${t.palette.surface.main}${t.palette.other.opacityHigh}`,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2), 0px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  indicator: {
    backgroundColor: `${t.palette.surface.main}${t.palette.other.opacityHigh}`,
  },
  textColor: {
    color: `${t.palette.surface.main}${t.palette.other.opacityHigh}`,
  },
  textColorDisabled: {
    color: `${t.palette.surface.main}${t.palette.other.opacityDisabled}`,
  },
  manyTabs: {
    minWidth: '90px',
    maxWidth: '360px',
  },
}));

/**
 * Checks if it meets up to the breakpoint and "centers" the tabbar until it is fullfilled
 *
 * @param centeredUntil until which breakpoint it should be centered.
 * @param tablistLength length of the tab size
 * @returns true if it's met, false if it's not met
 */
const centeredBreakpoint = (centeredUntil, tablistLength) => {
  if (centeredUntil) {
    return useMediaQuery(Theme.breakpoints.down(centeredUntil)) && tablistLength > 3;
  } else {
    return tablistLength > 3;
  }
};

const TabBar = ({ tablist, centeredUntil }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const tabs = getListItems(tablist);
  const isSubMenu = useAppSelector((state) => state.subMenu.isSubMenu);

  //Sets active tab based on the url.
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!tabs) return;
    tabs.forEach((value, index) => {
      if (location?.pathname.includes(value?.link)) {
        setValue(index);
      }
    });
  }, [location, location?.pathname, tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const listItems = tabs.map((item, index) => (
    <Tab
      key={index}
      label={item.label}
      onClick={() => navigate(item.link)}
      className={
        tabs.length > 3
          ? value === index
            ? classes.textColor + ' ' + classes.manyTabs
            : classes.textColorDisabled + ' ' + classes.manyTabs
          : value === index
          ? classes.textColor
          : classes.textColorDisabled
      }
      {...a11yProps(index)}
    />
  ));

  if(isSubMenu) {
    return <></>;
  }

  /*
    this was the most effective way I found out to change the text color depending
    on if it is active or not.
  */
  return (
    <Hidden mdUp>
      <AppBar position='sticky' elevation={0}>
        <Tabs
          centered={centeredBreakpoint(centeredUntil, tabs.length) ? false : true}
          classes={{ indicator: classes.indicator }}
          className={classes.root}
          variant={centeredBreakpoint(centeredUntil, tabs.length) ? 'scrollable' : 'fullWidth'}
          value={value}
          scrollButtons={'on'}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          {listItems}
        </Tabs>
      </AppBar>
    </Hidden>
  );
};

export default TabBar;
