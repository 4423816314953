import React from 'react';

import { Divider } from '@material-ui/core';
import EventCard from './EventCardBase';
import EventCategory from './EventCategory';
import EventDescription from './EventDescription';
import EventsContent from '/components/adminUsers/eventsOverview/EventsContent.component';
import EventAdminButton from './EventAdminButton';
import AdminEventCheck, { AdminEventCheckItemProps } from './AdminEventCheck';

export type AdminEventCardProps = {
  title: string;
  subtitle: string;
  currentEventBooked: number;
  currentEventAttended: number;
  maxEventAttended: number;
  paidPeople: number;
  isMobile: boolean;
  description: string;
  checks: AdminEventCheckItemProps[];
  categories: string[];
  filters: string[];
  onManageClick: () => void;
  onEditClick: () => void;
  onTransferClick: () => void;
  onAddUserClick: () => void;
};

export const AdminEventCard = ({
  title,
  subtitle,
  currentEventBooked,
  currentEventAttended,
  maxEventAttended,
  paidPeople,
  isMobile,
  description,
  checks,
  categories,
  filters,
  onManageClick,
  onEditClick,
  onTransferClick,
  onAddUserClick,
}: AdminEventCardProps) => (
  <EventCard
    title={title}
    subtitle={subtitle}
    isMobile={isMobile}
    statistics={
      <>
        <Divider style={{ marginBottom: '16px', marginTop: '8px' }} />
        <EventsContent
          data={[
            {
              label: `${currentEventBooked}/${maxEventAttended > 0 ? maxEventAttended : '-'}`,
              state: 'default',
            },
            {
              label: `${currentEventAttended}/${currentEventBooked}`,
              state: currentEventAttended === currentEventBooked ? 'success' : 'warning',
            },
            {
              label: `${paidPeople}/${currentEventBooked}`,
              state: paidPeople === currentEventBooked ? 'success' : 'error',
            },
          ]}
          isMobile={isMobile}
        />
      </>
    }
    button={
      <EventAdminButton
        isMobile={isMobile}
        onManageClick={onManageClick}
        onEditClick={onEditClick}
        onTransferClick={onTransferClick}
        onAddUserClick={onAddUserClick}
      />
    }
  >
    <div style={{ marginTop: '16px' }} />
    <AdminEventCheck items={checks} />
    <Divider style={{ marginTop: '16px' }} />
    <EventDescription
      description={description}
      style={{
        color: '#00000099',
      }}
    />
    <Divider style={{ marginTop: '16px' }} />
    <EventCategory isMobile={isMobile} categories={categories} />
    <Divider style={{ marginTop: '8px' }} />
    <EventCategory title="Event Filter" isMobile={isMobile} categories={filters} />
  </EventCard>
);
