import React from 'react';
import { Control, UseFormWatch } from 'react-hook-form';

import EventAttendancePrice from './EventAttendancePrice';
import EventLocation from './EventLocation';
import EventStartEnd from './EventStartEnd';
import EventTitleDesc from './EventTitleDesc';
import EventGeneralHeader from '../components/EventGeneralHeader';

interface EventGeneralProps {
  control: Control<any, any>;
  watch: UseFormWatch<any>;
}

const EventGeneral: React.FC<EventGeneralProps> = ({ control, watch }) => (
  <>
    <EventGeneralHeader />
    <EventTitleDesc control={control} />
    <EventStartEnd control={control} />
    <EventAttendancePrice control={control} watch={watch} />
    <EventLocation control={control} />
  </>
);

export default EventGeneral;
