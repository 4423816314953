import { createSlice } from '@reduxjs/toolkit';
import { getEventGroups1, updateEventGroup1, createEventGroup1, getAllEvents1 } from '../actions/EventGroupsActions';

const adminEventGroupsSlice = createSlice({
  name: 'adminEventGroups',
  initialState: {
    eventGroupsFilterSearch: '', // Could perhaps be removed, depending on how we do filtering.
    eventGroups: [], // This becomes [{id: 28, name: "unlimited", ...}, {id: 29, name: "free", ...}, ... ]
    showingEventGroup: undefined,
    events: null,
    displayEventGroup: null,
    eventFilterSearch: '',
  },
  reducers: {
    adminEventGroupsDisplayEventGroupSet: (state, action) => {
      state.displayEventGroup = action.payload;
    },
    adminEventGroupsFilterSearch: (state, action) => {
      state.eventGroupsFilterSearch = action.payload;
    },
    // Look at how this is used in AdminActions.jsx.:
    adminEventGroupsListSet: (state, action) => {
      state.eventGroups = action.payload;
    },
    adminDeleteEventGroup: (state, action) => {
      state.eventGroups = state.eventGroups.filter((eventGroup) => eventGroup.id !== action.payload);
    },
    adminShowingEventGroup: (state, action) => {
      state.showingEventGroup = action.payload;
    },
    adminEventGroupsEventsSet: (state, action) => {
      state.events = action.payload;
    },
    adminEventGroupsEventFilterSearch: (state, action) => {
      state.eventFilterSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventGroups1.pending, (state, action) => {})
      .addCase(getEventGroups1.fulfilled, (state, action) => {
        state.eventGroups = Object.values(action.payload);
      })
      .addCase(updateEventGroup1.fulfilled, (state, action) => {
        console.log('fulfilled... action: ', action);
        const updatedEventGroups = state.eventGroups.map((eventGroup) => {
          if (eventGroup.id === action.payload.eventGroupId) {
            return { ...eventGroup, ...action.payload.requestBody };
          }
          return eventGroup;
        });
        console.log('updatedEventGroups after fulfilment: ', updatedEventGroups);
        state.eventGroups = updatedEventGroups;
        console.log('event groups after fulfilment: ', state.eventGroups);
      })
      .addCase(updateEventGroup1.rejected, (state, action) => {
        console.log('Updating rejected..., action: ', action);
      })
      .addCase(createEventGroup1.fulfilled, (state, action) => {
        console.log('Create fulfilled, action param is: ', action);
        // state.eventGroups = [...state.eventGroups, ]
      })
      .addCase(createEventGroup1.rejected, (state, action) => {
        console.log('Create rejected, action param is: ', action);
      })
      .addCase(getAllEvents1.fulfilled, (state, action) => {
        state.events = action.payload;
      });
  },
});

// To change state from components, useDispatch on these generated action creators.
export const {
  adminEventGroupsDisplayEventGroupSet,
  adminEventGroupsListSet,
  adminDeleteEventGroup,
  adminShowingEventGroup,
  adminEventGroupsFilterSearch,
  adminEventGroupsEventsSet,
  adminEventGroupsEventFilterSearch,
} = adminEventGroupsSlice.actions;

// Used by store to tell the store about the reducer in this slice.
export const adminEventGroups = adminEventGroupsSlice.reducer;
