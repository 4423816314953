import React from 'react';
import { Typography } from '@material-ui/core';
import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';

const EventGroupsListHeader = (props) => (
  <>
    <CustomTableCell>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography variant='body1'>Group Name</Typography>
      </div>
    </CustomTableCell>
    <CustomTableCell>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Typography variant='body1'>Applied Events</Typography>
      </div>
    </CustomTableCell>
    <CustomTableCell>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Typography variant='body1'>Ticket Limit</Typography>
      </div>
    </CustomTableCell>

    <CustomTableCell style={{ width: '110px' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Typography variant='body1'>Displayed?</Typography>
      </div>
    </CustomTableCell>

    <CustomTableCell>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '11px',
        }}
      >
        <Typography variant='body1'>Actions</Typography>
      </div>
    </CustomTableCell>
  </>
);

export default EventGroupsListHeader;
