import React from 'react';
import { useForm } from 'react-hook-form';

import { RequestReset } from '/data/api/AuthRequest';

import TextComponent from '/components/forms-v2/Text.component';
import { Button } from '@material-ui/core';
import qs from 'query-string';

/**
 * Renders The form to reset password with password and confirm password
 * Required props:
 *  - doneHandler: function to be called when form is successfully submitted
 *
 * TODO:
 *  - Add snackbars if there is any kind of errors(?)
 */
const ResetForm = (props) => {
  const { handleSubmit, getValues, control } = useForm({ mode: 'all' });

  /**
   * Form submit
   */
  const onSubmit = (data) => {
    var tokenParam = qs.parse(location.search).token;

    RequestReset(tokenParam, { password: data.password })
      .then((r) => {
        props.doneHandler();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <h6 style={{ marginTop: '16px' }}>Choose new password</h6>

      <TextComponent
        name='password'
        control={control}
        label='Password'
        type='password'
        rules={{
          required: { value: true, message: 'Enter password' },
          minLength: { value: 8, message: 'Password is too short' },
          // pattern: {
          //   value: /^(?=.*\d)(?=.*(([a-z])|[!@#$%^&*]))/i,
          //   message: 'Your password must have at least: 1 number, 1 character',
          // },
        }}
        helperText='Minimum 8 characters.'
      />

      <TextComponent
        name='confirmPassword'
        control={control}
        label='Confirm Password'
        type='password'
        rules={{
          required: { value: true, message: 'Enter confirm password' },
          validate: {
            passwordMatch: (v) => (v === getValues('password') ? true : 'Passwords do not match'),
          },
        }}
      />

      <Button
        variant='contained'
        color='secondary'
        fullWidth
        style={{ margin: '16px auto' }}
        onClick={handleSubmit(onSubmit)}
      >
        Confirm
      </Button>
    </>
  );
};

export default ResetForm;
