import React, { useMemo } from 'react';
import Theme from '/MuiTheme';
import { useQuery } from '@tanstack/react-query';
import {
  getAdminEventFilterQuery,
  getAdminEventGroupQuery,
  getAdminEventsQuery,
  getAdminEventStatsQuery,
} from '/queries/AdminEventQueries';
import { useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { classifyEventList } from 'utils/utils';
import StartEventDivider from '/components/eventCard/StartEventDivider';
import useDebounce from '/utils/useDebounce';
import { useSelector } from 'react-redux';
import EventListItem from './EventListItem';

const AdminEventList = ({ searchValue }) => {
  const projectId = useSelector((state) => state.projectInfo.projectId);
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));
  const { data, loading, error } = useQuery(getAdminEventsQuery({ projectId }));
  const {
    data: eventStats,
    loading: eventStatsLoading,
    error: eventStatsError,
  } = useQuery(getAdminEventStatsQuery({ projectId }));
  const {
    data: eventFilters,
    loading: eventFiltersLoading,
    error: eventFiltersError,
  } = useQuery(getAdminEventFilterQuery({ projectId }));
  const {
    data: eventGroups,
    loading: eventGroupsLoading,
    error: eventGroupsError,
  } = useQuery(getAdminEventGroupQuery({ projectId }));

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const eventsList = useMemo(() => {
    if (!data) return [];

    if (debouncedSearchValue?.length < 3) {
      return classifyEventList(data);
    }

    const eventsFiltered = data?.filter((event) => {
      const eventTitle = event?.displayTitle.toLowerCase();
      const searchValueLower = debouncedSearchValue.toLowerCase();
      return eventTitle.includes(searchValueLower);
    });
    return classifyEventList(eventsFiltered);
  }, [data, debouncedSearchValue]);

  if (
    loading ||
    error ||
    eventStatsLoading ||
    eventStatsError ||
    eventFiltersLoading ||
    eventFiltersError ||
    eventGroupsLoading ||
    eventGroupsError
  ) {
    return <CircularProgress />;
  }

  return (
    <>
      <div style={{ marginTop: '16px' }} />
      {eventsList?.map((item) => {
        const event = item.data;
        return (
          <div key={`admin-event-card-${event.eventId}`}>
            {item.first && <StartEventDivider date={event.startDatetime} />}
            <EventListItem
              event={event}
              statsList={eventStats}
              eventFilters={eventFilters}
              eventGroups={eventGroups}
              isMobile={isMobile}
            />
          </div>
        );
      })}
    </>
  );
};

export default AdminEventList;
