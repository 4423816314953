import * as Backend from '../api/BackendRequest';

export const RequestEventsList = (pid: string) => Backend.request('get', `/event/${pid}`, {});

export const RequestEventStats = (pid: string) => Backend.request('get', `/event/${pid}/stats`, {}, {});

export const RequestTicketsList = (pid: string) => Backend.request('get', `/user/${pid}/tickets`);

export const RequestBooking = (pid: string, eventId: string | number) => Backend.request('post', `/user/booking/${eventId}`);

export const RequestTicketRemoval = (bid: string | number) => Backend.request('delete', `/user/ticket/${bid}`);

export const RequestTicketTransfer = (bookingId: string | number, lastName: string, email: string) =>
  Backend.request('POST', `/user/booking/${bookingId}/transfer`, {}, { lastName, email });
