import React from 'react';
import { Dialog as MuiDialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';

export const Dialog = ({
  title = '',
  question = '',
  buttonLabel = '',
  confirmOnClick = () => null,
  cancelOnClick = () => null,
  buttonDisable = false,
  isOpen = false,
}) => {
  return (
    <MuiDialog open={isOpen} onClose={cancelOnClick}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelOnClick} color='secondary'>
          Cancel
        </Button>
        <Button onClick={confirmOnClick} color='secondary' disabled={buttonDisable}>
          {buttonLabel}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
