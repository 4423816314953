export const SNACK_SUCCESS = 'SNACK_SUCCESS';
export const SNACK_ERROR = 'SNACK_ERROR';
export const SNACK_CLOSE = 'SNACK_CLOSE';

const initialState = {
  snack: {
    open: false,
    severity: 'error',
    context: '',
  },
  button: {
    status: 'disabled',
    style: 'contained',
    text: 'JIMBOB',
  },
};

interface Action {
  [key: string]: any;
}

export const feedback = (state = initialState, action: Action) => {
  switch (action.type) {
    case SNACK_SUCCESS:
      return {
        ...state,
        snack: {
          open: true,
          severity: 'success',
          context: action.data,
        },
      };

    case SNACK_ERROR:
      return {
        ...state,
        snack: {
          open: true,
          severity: 'error',
          context: action.data,
        },
      };
    case SNACK_CLOSE:
      return {
        ...state,
        snack: {
          ...state.snack,
          open: false,
        },
      };
    default:
      return state;
  }
};
