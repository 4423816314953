import React, { useState } from 'react';
import EventGroupsSearchBar from '/components/eventGroupsSearchBar/EventGroupsSearchBar';
import { connect } from 'react-redux';

/**
 * Container for search bar in Event Groups overview
 * This component could possibly be deleted as it's not used and replaced by
 * FilterComponent for finding event groups.
 *
 */
const EventGroupsSearchBarContainer = (props) => {
  const [selected, setSelected] = useState('');
  return <EventGroupsSearchBar setSelected={setSelected} projectId={props.projectId} />;
};

const mapState = (state) => {
  return {
    projectId: state.projectInfo.projectId,
  };
};

export default connect(mapState)(EventGroupsSearchBarContainer);
