import theme from 'MuiTheme';
import { makeStyles } from '@material-ui/core';

const useSubMenuStyles = makeStyles(() => ({
  root: {
    zIndex: 99,
    width: '100%',
    height: '56px',
    position: 'fixed',
    backgroundColor: '#fff',
    paddingLeft: '1rem',
    borderRadius: '0',
  },
  container: {
    display: 'flex',
    color: theme.palette.secondary.main,
    alignItems: 'center',
    height: '100%',
    gap: '1rem',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0',
  },
  arrowBackIcon: {
    width: '1.4rem',
    height: '1.4rem',
  },
}));

export default useSubMenuStyles;
