import { makeStyles } from "@material-ui/core";

const useEmailStyles = makeStyles(() => ({
  logoCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '14px',
    width: '95px',
  },
  textCenter: {
    textAlign: 'center',
    lineHeight: '28px',
  },
  header: {
    marginTop: '16px !important',
  },
  text: {
    marginTop: '5px',
    marginBottom: '16px',
  },
  button: {
    width: '100%',
    marginBottom: '40px',
  },
}));

export default useEmailStyles;
