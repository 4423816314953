import { useQuery } from '@tanstack/react-query';
import { getAdminEventBookingsQuery } from 'queries/AdminEventQueries';
import { BookedUser } from 'queries/AdminEventQueries.types';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/storeConfig';

const useGetBookings = (eventId: string) => {
  const [bookings, setBookings] = useState<BookedUser[]>([]);
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const bookingsData = useQuery(getAdminEventBookingsQuery({ projectId, eventId }));

  useEffect(() => {
    if (bookingsData.data) {
      setBookings(bookingsData.data);
    }
  }, [bookingsData.data]);

  const updateBooking = (bookingId: number, isAttending: boolean, isPaid: boolean) => {
    const bookingCopy = [...bookings];
    const newBookings = bookingCopy.map((booking) => {
      if (booking.bookingId === bookingId) {
        const newBookedUser: BookedUser = {
          ...booking,
          isPaid,
          isAttending,
        };
        return newBookedUser;
      }
      return booking;
    });
    setBookings(newBookings);
  };

  const removeBooking = (bookingId: number) => {
    const newBookings = bookings.filter((booking) => booking.bookingId !== bookingId);
    setBookings(newBookings);
  };

  return {
    data: bookings,
    isLoading: bookingsData.isLoading,
    isError: bookingsData.isError,
    updateBooking,
    removeBooking,
  };
};

export default useGetBookings;
