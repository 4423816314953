import React from 'react';

import { Edit, DeleteOutline, Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { TableRow } from '@material-ui/core';
import Theme from '/MuiTheme';
import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';
import UserRoleMenu from '/components/adminAccess/userRoleMenu/UserRoleMenu';

const BodyDesktop = ({ user, index, roles, setUserRole, setUserEditing, setIdToDelete, setDeleteDialogOpen }) => (
  <TableRow>
    <CustomTableCell>{user.firstName}</CustomTableCell>
    <CustomTableCell>{user.lastName}</CustomTableCell>
    <CustomTableCell>{user.email}</CustomTableCell>
    <CustomTableCell>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {roles.find((role) => role.roleId === user.roleId).title}
          {user.editing ? (
            <UserRoleMenu
              options={roles.map((role) => ({
                label: role.title,
                onclick: () => {
                  setUserRole(user.accountId, role.roleId);
                },
              }))}
            />
          ) : null}
        </div>
      </div>
    </CustomTableCell>
    <CustomTableCell>
      {!user.editing ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={() => setUserEditing(user.accountId, true)} size='small' aria-label='Edit role'>
            <Edit fontSize='inherit' color='secondary' />
          </IconButton>
        </div>
      ) : (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <IconButton
            onClick={() => {
              /*TODO Likely handle the onClick here. instead..*/
            }}
            size='small'
            aria-label='Delete role'
            //disabled
            style={{
              color: `${Theme.palette.surface.text}${Theme.palette.other.opacityDisabled}`,
            }}
          >
            <DeleteOutline
              fontSize='inherit'
              //color='secondary'
              // FIXME: Reverse this when delete is implemented
              // onClick={() => {
              //   setIdToDelete(user.accountId);
              //   setDeleteDialogOpen(true);
              // }}
              // FIXME: Change this to Theme.palette.secondary.main when delete is implemented
            />
          </IconButton>

          <IconButton
            onClick={() => setUserEditing(user.accountId, false)}
            size='small'
            aria-label='Close delete option and show edit button'
          >
            <Close fontSize='inherit' color='primary' />
          </IconButton>
        </div>
      )}
    </CustomTableCell>
  </TableRow>
);

export default BodyDesktop;
