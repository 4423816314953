import { useMutation } from "@tanstack/react-query";
import { deleteUserBooking } from "queries/AdminEventQueries";
import { useAppSelector } from "redux/storeConfig";

interface MutationProps {
  bookingId: number;
}

const useRemoveBooking = (removeBooking: (bookingId: number) => void) => {
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const sendData = useMutation({
    mutationFn: async ({bookingId}: MutationProps) => {
      await deleteUserBooking(projectId, bookingId);
      removeBooking(bookingId);
    }
  });

  const deleteBooking = (bookingId: number) => {
    sendData.mutate({bookingId});
  }

  return {
    isLoading: sendData.isLoading,
    deleteBooking,
  };
};

export default useRemoveBooking;
