import React from 'react';

import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FilterListIcon from '@material-ui/icons/FilterList';
import Theme from '/MuiTheme';

import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const BookingsHeader = ({
  onClick,
  allChecked,
  timeFilterClick,
  priceFilterClick,
  attendedFilterClick,
  sortBy,
  sortDirection,
  setSortBy,
}) => (
  <>
    <CustomTableCell style={{ width: '42px' }}>
      <Checkbox checked={allChecked} onChange={onClick} style={{ padding: 0 }} />
    </CustomTableCell>

    <CustomTableCell style={{ width: '110px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FilterListIcon
          style={{ width: '20px', height: '20px', color: Theme.palette.secondary.main, cursor: 'pointer' }}
          onClick={timeFilterClick}
        />
        &nbsp;
        <TableSortLabel
          direction={sortDirection === 'asc' ? 'asc' : 'desc'}
          // hideSortIcon={true}
          active={sortBy === 'startDatetime'}
          onClick={() => setSortBy('startDatetime')}
        >
          <Typography variant="body1">Time</Typography>
        </TableSortLabel>
      </div>
    </CustomTableCell>

    <CustomTableCell>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TableSortLabel
          direction={sortDirection === 'asc' ? 'asc' : 'desc'}
          // hideSortIcon={true}
          active={sortBy === 'displayTitle'}
          onClick={() => setSortBy('displayTitle')}
        >
          <Typography variant="body1">Event Name</Typography>
        </TableSortLabel>
      </div>
    </CustomTableCell>

    <CustomTableCell>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <FilterListIcon
          style={{ width: '20px', height: '20px', color: Theme.palette.secondary.main, cursor: 'pointer' }}
          onClick={priceFilterClick}
        />
        &nbsp;
        <TableSortLabel
          direction={sortDirection === 'asc' ? 'asc' : 'desc'}
          // hideSortIcon={true}
          active={sortBy === 'basePriceSek'}
          onClick={() => setSortBy('basePriceSek')}
        >
          <Typography variant="body1">Price</Typography>
        </TableSortLabel>
      </div>
    </CustomTableCell>

    <CustomTableCell style={{ width: '120px' }}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
        <FilterListIcon
          style={{ width: '20px', height: '20px', color: Theme.palette.secondary.main, cursor: 'pointer' }}
          onClick={attendedFilterClick}
        />
        &nbsp;
        <TableSortLabel
          direction={sortDirection === 'asc' ? 'asc' : 'desc'}
          // hideSortIcon={true}
          active={sortBy === 'isAttending'}
          onClick={() => setSortBy('isAttending')}
        >
          <Typography variant="body1">Attendance</Typography>
        </TableSortLabel>
      </div>
    </CustomTableCell>
  </>
);

export default BookingsHeader;
