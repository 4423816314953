import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import './eventFilter.scss';
import {
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Input,
  ListItemText,
  Popper,
} from '@material-ui/core';
import * as Backend from '/data/api/BackendRequest.ts';

import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import FilterListIcon from '@material-ui/icons/FilterList';
import { MenuItem } from '@material-ui/core/';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const EventFilter = ({ events, projectId }) => {
  const [displayEvents, setDisplayEvents] = useState([]);

  useEffect(() => {
    const eventsClone = [...events];
    setDisplayEvents(eventsClone);
  }, [events]);

  const postFilter = (name, eventIDs, studyProgramIDs) => {
    Backend.request('post', `admin/${projectId}/event-filter`, {}, { name, eventIDs, studyProgramIDs }).then(
      (response) => {
        const newFilter = response.data.data;
        setFilter(newFilter.id);

        Backend.request('get', `admin/${projectId}/event-filter`).then((response) => {
          const data = response.data.data;
          setFilters(data);
        });
      }
    );
  };

  const patchFilter = (name, eventIDs, studyProgramIDs) => {
    Backend.request('patch', `admin/${projectId}/event-filter/${filter}`, {}, { name, eventIDs, studyProgramIDs }).then(
      (response) => {}
    );
  };

  const cancelChanges = () => {
    let changeBackToId;

    if (filters.length > 0) {
      if (filter !== -1) {
        changeBackToId = filter;
      }
    }

    Backend.request('get', `admin/${projectId}/event-filter`).then((response) => {
      const data = response.data.data;
      setFilters(data);
      if (changeBackToId != null) {
        data.forEach((f) => {
          if (f.id === changeBackToId) {
            updateCurrent(f);
          }
        });
      } else if (data.length > 0) {
        updateCurrent(data[0]);
      }
    });
  };

  const deleteFilter = (id) => {
    Backend.request('delete', `admin/${projectId}/event-filter/${id}`, {}, {}).then((response) => {
      Backend.request('get', `admin/${projectId}/event-filter`).then((response) => {
        const data = response.data.data;
        setFilters(data);
        if (data.length > 0) {
          updateCurrent(data[0]);
        }
      });
    });
  };

  const newFilter = () => {
    setFilter(-1);
    setFilterEvents([]);
    setFilterPrograms([]);
    setFilterName('');
  };

  const updateCurrent = (filterUpdate) => {
    setFilter(filterUpdate.id);
    setFilterEvents(filterUpdate.eventIds);
    setFilterPrograms(filterUpdate.studyProgramIds);
    setFilterName(filterUpdate.name);
  };

  const [studyPrograms, setStudyPrograms] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filter, setFilter] = useState(null);
  const [filterEvents, setFilterEvents] = useState([]);
  const [filterPrograms, setFilterPrograms] = React.useState([]);
  const [filterName, setFilterName] = useState('');

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [error, setError] = useState({});

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    Backend.request('get', `/programmes`, {}, {}).then((response) => {
      setStudyPrograms(response.data.data);
    });
    Backend.request('get', `admin/${projectId}/event-filter`).then((response) => {
      const data = response.data.data;
      setFilters(data);
      if (data.length > 0) {
        updateCurrent(data[0]);
      }
    });
  }, []);

  const validate = () => {
    setError({});
    if (filterName.length <= 1 || filterName.length >= 255) {
      setError((prevState) => ({
        ...prevState,
        filterName: 'Name must be between 2 and 255 characters long',
      }));
      return false;
    }
    return true;
  };

  const Submit = () => {
    if (!validate()) {
      return;
    }
    if (filter === -1) {
      postFilter(filterName, filterEvents, filterPrograms);
    } else {
      patchFilter(filterName, filterEvents, filterPrograms);
    }
  };

  return (
    <div className="filterpageContainer">
      <h2>Event filters</h2>

      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <FormControl fullWidth variant="outlined" id="sex">
          <InputLabel ref={inputLabel} id="select-sex">
            Filter
          </InputLabel>
          <Select
            style={{ width: '60%' }}
            labelId="filters"
            id="filters"
            value={filter || ''}
            onChange={(event) => {
              event.persist();
              setFilter(event.target.value);
              filters.forEach((f) => {
                if (f.id === event.target.value) {
                  updateCurrent(f);
                }
              });
            }}
            labelWidth={labelWidth}
          >
            {filters.map((f) => (
              <MenuItem key={f.id} value={f.id}>
                {f.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          disabled={filter === -1}
          onClick={() => deleteFilter(filter)}
          style={{
            width: '200px',
            height: '50px',
            marginRight: '30px',
          }}
          color="primary"
          variant="outlined"
        >
          Delete filter
        </Button>
        <Button
          disabled={filter === -1}
          onClick={() => newFilter()}
          style={{ width: '200px', height: '50px' }}
          color="primary"
          variant="contained"
        >
          Create new
        </Button>
      </div>

      <TextField
        size="small"
        style={{ width: '200px' }}
        label="Name"
        variant="outlined"
        value={filterName || ''}
        name="filterName"
        onChange={(e) => setFilterName(e.target.value)}
        error={error.filterName != null}
        helperText={error.filterName}
      />

      <div
        style={{
          position: 'relative',
          height: '350px',
          margin: '60px 0px',
        }}
      >
        <div className="subtitle">Applied events</div>
        <Paper
          style={{
            maxHeight: '100%',
            overflow: 'auto',
            borderRadius: '6px',
          }}
        >
          <div style={{ padding: '10px' }}></div>
          <div className="alternatives">
            {useMemo(() => {
              displayEvents?.sort((a, b) => {
                if (filterEvents.includes(b.eventId) && !filterEvents.includes(a.eventId)) {
                  return 1;
                }
                if (!filterEvents.includes(b.eventId) && filterEvents.includes(a.eventId)) {
                  return -1;
                }

                if (a.displayTitle < b.displayTitle) {
                  return -1;
                }

                if (a.displayTitle > b.displayTitle) {
                  return 1;
                }

                return 0;
              });

              return displayEvents?.map((e) => {
                return (
                  <EventBox
                    key={e.eventId}
                    id={e.eventId}
                    filterEvents={filterEvents}
                    setFilterEvents={setFilterEvents}
                    title={e.displayTitle}
                  />
                );
              });
            }, [filterEvents, displayEvents])}
          </div>
        </Paper>
      </div>

      <div>
        <div className="subtitle">Study programs</div>
        {useMemo(
          () => (
            <EnhancedTable
              studyProgramsInput={studyPrograms}
              selected={filterPrograms}
              setSelected={setFilterPrograms}
            />
          ),
          [studyPrograms, filterPrograms]
        )}
      </div>
      <div className="buttonGroup">
        <Button onClick={() => cancelChanges()} color="primary">
          Cancel
        </Button>
        <Button onClick={Submit} variant="contained" color="primary">
          Save changes
        </Button>
      </div>
    </div>
  );
};

const EventBox = ({ id, setFilterEvents, filterEvents, title }) => {
  return (
    <div>
      <FormControlLabel
        checked={filterEvents.includes(id)}
        control={
          <Checkbox
            onClick={() => {
              if (filterEvents.includes(id)) {
                setFilterEvents((prev) => prev.filter((e) => e !== id));
              } else {
                setFilterEvents((prev) => [...prev, id]);
              }
            }}
            style={{ padding: '0 5px', margin: 0 }}
            size="small"
          />
        }
        label={title}
      />
    </div>
  );
};

const EnhancedTableHead = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    studyPrograms,
    columnFilters,
    setColumnFilters,
  } = props;
  const [filterSelect, setFilterSelect] = useState(null);

  const handleChange = (column, item) => {
    if (columnFilters[column].includes(item)) {
      setColumnFilters({
        ...columnFilters,
        [column]: columnFilters[column].filter((e) => e !== item),
      });
    } else {
      setColumnFilters({
        ...columnFilters,
        [column]: [...columnFilters[column], item],
      });
    }
  };

  const headCells = [
    { id: 'abbreviation', numeric: false, label: 'Code' },
    { id: 'school', numeric: true, label: 'School' },
    { id: 'chapter', numeric: true, label: 'Chapter' },
    { id: 'typeEn', numeric: true, label: 'Type' },
    { id: 'nameEn', numeric: true, label: 'Program' },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={'left'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'}>
              <FilterListIcon style={{ fontSize: 20 }} onClick={() => setFilterSelect(headCell.id)} />

              {headCell.id === filterSelect && (
                <ClickAwayListener onClickAway={() => setFilterSelect(null)}>
                  <div
                    style={{
                      position: 'absolute',
                      height: '200px',
                      top: '50px',
                      width: '300px',
                    }}
                  >
                    <Paper
                      style={{
                        maxHeight: '100%',
                        overflow: 'auto',
                        borderRadius: '6px',
                      }}
                    >
                      {studyPrograms
                        .map((e) => e[headCell.id])
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .map((name) => (
                          <div
                            style={{
                              padding: '5px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            key={name}
                            value={name}
                            onClick={() => handleChange(headCell.id, name)}
                          >
                            <Checkbox checked={columnFilters[headCell.id].indexOf(name) > -1} />
                            <div>{name}</div>
                          </div>
                        ))}
                    </Paper>
                  </div>
                </ClickAwayListener>
              )}

              <span onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = ({ studyProgramsInput, selected, setSelected }) => {
  const [columnFilters, setColumnFilters] = React.useState({
    abbreviation: [],
    nameEn: [],
    school: [],
    chapter: [],
    typeEn: [],
  });
  const [studyPrograms, setStudyPrograms] = useState([]);

  useEffect(() => {
    if (studyProgramsInput != null && studyProgramsInput.length !== 0) {
      let programmes = studyProgramsInput.filter((programme) => {
        return !Object.keys(columnFilters).some((cf) => {
          if (columnFilters[cf].length > 0) {
            if (columnFilters[cf].includes(programme[cf])) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        });
      });
      setStudyPrograms(programmes);
    }
  }, [studyProgramsInput, columnFilters]);

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = studyPrograms.map((n) => n.nameEn);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper style={{ maxHeight: '400px', overflow: 'auto' }} className={classes.paper}>
        <TableContainer style={{ height: '400px' }}>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={studyPrograms.length}
              studyPrograms={studyProgramsInput}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
            />
            <TableBody>
              {stableSort(studyPrograms, getComparator(order, orderBy)).map((row, index) => {
                const isItemSelected = isSelected(row.studyProgramId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.studyProgramId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.studyProgramId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="left" id={labelId}>
                      {row.abbreviation}
                    </TableCell>
                    <TableCell align="left">{row.school}</TableCell>
                    <TableCell align="left">{row.chapter}</TableCell>
                    <TableCell align="left">{row.typeEn}</TableCell>
                    <TableCell align="left">{row.nameEn}</TableCell>
                  </TableRow>
                );
              })}
              {studyPrograms.length > 0 && (
                <TableRow style={{ height: 33 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(EventFilter);
