interface Action {
  [key: string]: any;
}

const emailRetrival = (state = '', action: Action) => {
  switch (action.type) {
    case 'GET_EMAIL':
      return action.email;
    default:
      return state;
  }
};

export default emailRetrival;
