import React from 'react';
import { Button, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';

import { adminUsersAddBookingDialogOpenSet as setIsOpen } from '/redux/reducers/AdminReducers';

const AddBookingButton = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.adminUsers.searchValue);
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));

  if (!userId) return <></>;

  const handleClick = () => {
    dispatch(setIsOpen(true));
  };

  return (
    <>
      {isMobile ? (
        <>
          <Fab
            onClick={handleClick}
            color='secondary'
            aria-label='add'
            style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 99 }}
            children={<AddIcon />}
          />
        </>
      ) : (
        <Button
          variant='contained'
          color='secondary'
          startIcon={<AddIcon />}
          style={{
            borderRadius: '50px',
            height: '48px',
            marginTop: '16px',
            float: 'right',
          }}
          onClick={handleClick}
        >
          Add Event
        </Button>
      )}
    </>
  );
};

export default AddBookingButton;
