import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Content from '/components/content/Content';
import FormContainer from '/components/formContainer/FormContainer';
import Footer from '/components/footer/Footer';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { RequestSignup, RequestConfirmEmail } from '/data/api/AuthRequest';
import { getGeneralOptions, getStudyOptions } from '/redux/actions/OptionsActions';
import FormLogo from '/components/formLogo/FormLogo.component';

import StepperComponent from '/components/forms-v2/Stepper.component';

import RegisterStepOne from './stepOne/StepOne';
import RegisterStepTwo from './stepTwo/StepTwo';
import RegisterStepThree from './stepThree/StepThree';
import StepCreated from './stepCreated/StepCreated';

const useStyles = makeStyles(() => ({
  logoCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '14px',
    width: '95px',
  },
  textCenter: {
    textAlign: 'center',
    lineHeight: '28px',
  },
  generalInfo: {
    marginTop: '16px !important',
    marginBottom: '16px !important',
  },
}));

/**
 * Default values for first page
 */
const defaultValues = {
  firstName: '',
  lastName: '',
  sex: '',
  nationality: '',
  personNumber: '',
  phoneNumber: '',
  isMarketingAllowed: false,
  termsAgree: false,
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  studyType: null,
  studyProgram: null,
};

/**
 * Title on register page depending on which step it is on!
 */
const pageTitle = (currentStep) => {
  switch (currentStep) {
    case 0:
      return 'General Information';
    case 1:
      return 'Account Information';
    case 2:
      return 'Study Information';
    default:
      return 'Create Account';
  }
};

const CreateAccount = ({ profileOptions, studyInfo, projectId }) => {
  const { handleSubmit, getValues, control, watch, clearErrors, unregister } = useForm({ mode: 'all', defaultValues });
  const [currentStep, setCurrentStep] = useState(0);
  const classes = useStyles();

  /**
   * Get study types and programs
   */
  useEffect(() => {
    if (!studyInfo) getStudyOptions();
    if (!profileOptions) getGeneralOptions();
  }, []);

  const onBack = () => {
    setCurrentStep(currentStep - 1);
    switch (currentStep) {
      case 1:
        clearErrors(['email', 'confirmEmail', 'confirmPassword', 'password']);
        unregister(['email', 'confirmEmail', 'confirmPassword', 'password']);
        break;
      case 2:
        clearErrors(['studyType', 'studyProgram']);
        unregister(['studyType', 'studyProgram']);
        break;
      default:
        break;
    }
  };

  /**
   * When NEXT button is clicked
   */
  const onSubmit = (data) => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
    else {
      // data to send to backend
      const creationData = {
        password: data.password,
        accountInfo: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          birthdayDate: new Date(
            data.birthdayDate.getTime() + Math.abs(data.birthdayDate.getTimezoneOffset() * 60000)
          ).toISOString(),
          sex: data.sex,
          nationality: data.nationality,
          personNumber: data.personNumber,
          phoneNumber: data.phoneNumber,
          arrivalDate: '2020-08-18T19:11:54.000Z',
          isMarketingAllowed: data.isMarketingAllowed,
          isTosAgreed: true,
        },
        studyInfo: {
          studyProgramId: data.studyProgram,
          firstSemester: '2020-09-01T00:00:00.000Z', // FIXME: we send placeholder semester info for now
          lastSemester: '2021-01-01T00:00:00.000Z', // FIXME: we send placeholder semester info for now
        },
      };

      RequestSignup(creationData)
        .then(() => {
          RequestConfirmEmail({ email: data.email }, projectId)
            .then(() => setCurrentStep(3))
            .catch(() => {});
        })
        .catch(() => {});
    }
  };

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          {currentStep !== 3 ? (
            <>
              <h6 className={classes.generalInfo}>{pageTitle(currentStep)}</h6>
              {currentStep === 0 ? (
                <RegisterStepOne
                  control={control}
                  getValues={getValues}
                  profileOptions={profileOptions}
                  projectId={projectId}
                />
              ) : null}
              {currentStep === 1 ? <RegisterStepTwo control={control} getValues={getValues} /> : null}
              {currentStep === 2 ? (
                <RegisterStepThree control={control} getValues={getValues} watch={watch} studyInfo={studyInfo} />
              ) : null}

              <StepperComponent
                active={currentStep}
                stepAmount={3}
                handleNext={handleSubmit(onSubmit)}
                handleBack={onBack}
              />
            </>
          ) : (
            <StepCreated email={getValues('email')} />
          )}
        </FormContainer>
      </Content>
      <Footer name="IR" />
    </>
  );
};

const mapState = (state) => ({
  projectId: state.projectInfo.projectId,
  profileOptions: state.options.general,
  studyInfo: state.options.study,
});

const actionCreators = {
  getGeneralOptions,
  getStudyOptions,
};

const connectedCreateAccountPage = connect(mapState, actionCreators)(CreateAccount);
export { connectedCreateAccountPage as CreateAccount };
export default connectedCreateAccountPage;
