import { BackendEvent, BookingStats, EventBookingStats } from "queries/AdminEventQueries.types";
import { AdminEventCheckItemProps } from "components/eventCard/AdminEventCheck";
import { format, utcToZonedTime } from "date-fns-tz";

export const getBookingStatus = (event?: BackendEvent): AdminEventCheckItemProps => {
  if(!event) return {
    label: 'Booking',
    value: 'not available',
    showStatus: false,
    isOk: false,
  };

  const label = 'Booking';
  const bookingStartDatetime = new Date(event.bookingStartDatetime);
  const bookingEndDatetime = new Date(event.bookingEndDatetime);
  const now = new Date();

  if (now < bookingStartDatetime) {
    return {
      label,
      value: 'booking not open',
      showStatus: true,
      isOk: false,
    }; 
  }

  if (now > bookingEndDatetime) {
    return {
      label,
      value: 'closed for booking',
      showStatus: true,
      isOk: false,
    };
  }

  return {
    label,
    value: 'open for booking',
    showStatus: true,
    isOk: true,
  };
}

export const getTransferStatus = (event?: BackendEvent): AdminEventCheckItemProps => {

  if(!event) return {
    label: 'Transfer',
    value: 'not available',
    showStatus: false,
    isOk: false,
  };

  const label = 'Transfer';
  const swapEndDatetime = new Date(event.swapEndDatetime);
  const now = new Date();

  if (now > swapEndDatetime) {
    return {
      label,
      value: 'ticket transfer not possible',
      showStatus: true,
      isOk: false,
    };
  }

  return {
    label,
    value: 'ticket transfer possible',
    showStatus: true,
    isOk: true,
  };
};

export const getTimeDuration = (event?: BackendEvent): string => {
  if(!event) return '';
  const time = `${format(utcToZonedTime(new Date(event.startDatetime), 'Europe/Stockholm'), 'HH:mm')} - ${format(
    utcToZonedTime(new Date(event.endDatetime), 'Europe/Stockholm'),
    'HH:mm'
  )}`;
  return time;
};

export const getEventStatsId = (eventId: number, statsList: EventBookingStats[]): BookingStats => {
  const defaultStats: BookingStats = {
    countBooked: 0,
    countAttending: 0,
    countPaid: 0,
  };
  const stats = statsList.find((s) => s.eventId === eventId);
  if (!stats) return defaultStats;
  return stats.stats;
}

export const getEventStats = (event?: BackendEvent, statsList?: EventBookingStats[]): BookingStats => {
  const defaultStats: BookingStats = {
    countBooked: 0,
    countAttending: 0,
    countPaid: 0,
  };
  if(!event || !statsList) return defaultStats;
  return getEventStatsId(event.eventId, statsList);
}

