import React from 'react';

import { AdminHeader } from 'components/adminHeader';
import { useMediaQuery } from '@material-ui/core';
import theme from 'MuiTheme';

const ManageEventHeader = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AdminHeader
      isMobile={isMobile}
      title="Edit Event"
      subtitle="Enter the information for the event in the fields below. General and booking settings must be filled out. Settings can be changed later as well."
    />
  );
};

export default ManageEventHeader;
