import { useQuery } from "@tanstack/react-query";
import { getAdminEventQuery } from "queries/AdminEventQueries";
import { useAppSelector } from "redux/storeConfig";

const useGetEvent = (eventId: string) => {
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const eventData = useQuery(getAdminEventQuery({ projectId, eventId }));

  return {
    data: eventData.data,
    isLoading: eventData.isLoading,
    isError: eventData.isError,
  }
}

export default useGetEvent;
