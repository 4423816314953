import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/storeConfig';

interface PrivateRouteProps {
  children: React.ReactNode;
  admin?: boolean;
}

/**
 * Makes a route private by checking everytime if the user has the required
 * permissions to view a certain route. otherwise it sends the user into
 * the home page
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, admin = false }) => {
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate(`/${projectId}/`);
  };

  useEffect(() => {
    const { token } = sessionStorage;
    if (!token) {
      navigateHome();
      return;
    }

    const roleID = JSON.parse(atob(token?.split('.')[1]))?.projectRole;

    if (roleID >= 6 && admin) {
      navigateHome();
    }
  }, []);

  return <>{children}</>;
};

export default PrivateRoute;
