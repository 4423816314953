import React, { useEffect, useState } from 'react';
import SubpageModal from '/components/subpageModal/SubpageModal.component';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { adminUsersTransferOpen, adminUsersTransferSearchValue } from '/redux/reducers/AdminReducers';
import { Button, Typography, Divider, Collapse, Checkbox, IconButton, FormControlLabel } from '@material-ui/core';
import { getUserDataTransfer, wipeTransferWindow, transferTicket } from '/redux/actions/AdminActions';
import SearchBar from '/components/searchBar/SearchBar';
import CheckOverview from '/components/adminUsers/CheckOverview.component';

import CloseIcon from '@material-ui/icons/Close';
import GeneralSettings from './generalSettings/GeneralSettingsContainer';
import TicketChecklistContainer from './TicketChecklist.container';

export const TicketTransferContainer = () => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.adminUsers.transferTicket.isOpen);
  const selectedEvents = useSelector((state) => state.adminUsers.selectedEvents);
  const events = useSelector((state) => state.adminUsers.events);

  const userId = useSelector((state) => state.adminUsers.transferTicket.searchValue);
  const projectId = useSelector((state) => state.projectInfo.projectId);

  // Transfer user's data
  const generalInfo = useSelector((state) => state.adminUsers.transferTicket.general);
  const study = useSelector((state) => state.adminUsers.transferTicket.study);
  const preferences = useSelector((state) => state.adminUsers.transferTicket.preferences);
  const checks = useSelector((state) => state.adminUsers.transferTicket.checks);

  const [expanded, setExpanded] = useState(false);
  const [isAttending, setIsAttending] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    if (events === null) return;
    if (isOpen) {
      const selectedEvent = events.find((e) => e.bookingId === selectedEvents[0]);
      setIsAttending(selectedEvent.isAttending);
      setIsPaid(selectedEvent.isPaid);
    }
  }, [isOpen]);

  const returnSuccessfullCount = () => {
    if (!checks) return 0;
    return (
      checks.isBeforeTransferTime.isOk + checks.isOverlapping.isOk + checks.isFiltered.isOk + checks.isMaxGroups.isOk
    );
  };

  const closeModal = async () => {
    dispatch(adminUsersTransferOpen(false));
    dispatch(wipeTransferWindow());
    setExpanded(false);
    setIsAttending(false);
    setIsPaid(false);
  };

  const searchUser = (value) => {
    dispatch(adminUsersTransferSearchValue(value));
  };

  useEffect(() => {
    if (userId !== '') {
      dispatch(getUserDataTransfer());
    }
  }, [userId]);

  const disableButton = () => {
    if (checks !== null) return !checks.isNovelEvent.isOk;
    return true;
  };

  const handleSubmit = () => {
    dispatch(transferTicket(isAttending, isPaid));
  };

  return (
    <>
      <SubpageModal
        hideHeader={!isMobile}
        title={'Transfer ticket'}
        maxWidth={918}
        isOpen={isOpen}
        reset={() => null}
        close={closeModal}
        hideReset={true}
        stretchHeight={1000}
        variant='thick'
      >
        <div style={{ width: '90vw' }} /> {/* This is to fix a bug in the modal */}
        <div style={{ display: 'flex', marginTop: '20px', marginBottom: '22px' }}>
          <div style={{ flex: 1 }}>
            <Typography variant={isMobile ? 'h6' : 'h5'}>Transfer ticket</Typography>
            <Typography variant='body2' color='textSecondary'>
              Transfer the ticket to another user.
            </Typography>
          </div>
          {!isMobile && (
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        {!isMobile && <Divider />}
        <Typography variant='h6' style={{ marginBottom: '16px', marginTop: '16px' }}>
          Choose User
        </Typography>
        <SearchBar setSelected={searchUser} projectId={projectId} />
        <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
        {generalInfo && study && preferences && (
          <>
            <GeneralSettings paramGeneralInfo={generalInfo} paramStudy={study} paramPreferences={preferences} />
            <Typography variant='h6'>Event information</Typography>
            <Typography variant='body2' color='textSecondary'>
              Coming soon (TM)
            </Typography>
            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />

            <CheckOverview
              isMobile={isMobile}
              isLoading={false}
              success={returnSuccessfullCount() === 4}
              setExpanded={() => setExpanded(!expanded)}
              isExpanded={expanded}
              description={`${returnSuccessfullCount()}/4 successful`}
            />

            <Collapse in={isMobile ? expanded : true} timeout='auto'>
              {isMobile && <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />}
              <TicketChecklistContainer isMobile={isMobile} />
            </Collapse>

            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
            <Typography variant='h6'>Status</Typography>
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox
                  checked={isPaid}
                  name='checkedC'
                  style={{ padding: '4px 8px' }}
                  onClick={() => setIsPaid(!isPaid)}
                />
              }
              label={
                <Typography variant='body2' style={{ display: 'inline' }}>
                  Mark as paid
                </Typography>
              }
            />
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox
                  checked={isAttending}
                  name='checkedD'
                  style={{ padding: '4px 8px' }}
                  onClick={() => setIsAttending(!isAttending)}
                />
              }
              label={
                <Typography variant='body2' style={{ display: 'inline' }}>
                  Mark as attending
                </Typography>
              }
            />

            <Button
              fullWidth
              onClick={handleSubmit}
              color='secondary'
              variant='contained'
              disabled={disableButton()}
              style={{ marginTop: '16px', marginBottom: '25px' }}
            >
              {disableButton() ? 'User already booked' : 'confirm'}
            </Button>
          </>
        )}
      </SubpageModal>
    </>
  );
};
