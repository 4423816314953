import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/forms-v2/Text.component';

interface ConfirmPasswordInputProps {
  control: Control<any, any>;
  getValues: (formValue: string) => any;
}

const ConfirmPasswordInput: React.FC<ConfirmPasswordInputProps> = ({ control, getValues }) => {
  return (
    <TextComponent
      name="confirmPassword"
      control={control}
      label="Confirm Password"
      rules={{
        required: { value: true, message: 'Enter confirm password' },
        validate: {
          passwordMatch: (v: string) => (v === getValues('password') ? true : 'Passwords does not match'),
        },
      }}
      type="password"
    />
  );
};

export default ConfirmPasswordInput;
