/* eslint-disable no-useless-escape */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';

import FormLogo from '/components/formLogo/FormLogo.component';
import FormContainer from '/components/formContainer/FormContainer';
import TextComponent from '/components/forms-v2/Text.component';

import { Button } from '@material-ui/core';
import { useNavigate, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { userActions } from '/redux/actions/AuthActions';

const LoginForgot = ({ projectId, sendResetLink, sent }) => {
  const navigate = useNavigate();
  const { handleSubmit, getValues, control } = useForm({ mode: 'all' });

  /**
   * Handles submit button
   */
  const onSubmit = (data) => {
    sendResetLink(data.email, projectId);
  };

  /**
   * when form has been accepted
   */
  useEffect(() => {
    if (sent) navigate(`/${projectId}/sentForgot`);
  }, [sent]);

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />

          <h6 style={{ marginTop: '16px' }}>Reset your password</h6>

          <TextComponent
            name="email"
            control={control}
            label="E-mail"
            rules={{
              required: { value: true, message: 'Enter e-mail' },
              pattern: {
                // taken from https://emailregex.com/
                value:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                message: 'Invalid E-mail address',
              },
            }}
          />

          <TextComponent
            name="confirmEmail"
            control={control}
            label="Confirm E-mail"
            rules={{
              required: { value: true, message: 'Confirm e-mail' },
              pattern: {
                value:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                message: 'Invalid E-mail address',
              },
              validate: {
                emailsMatch: (v) => (v === getValues('email') ? true : 'E-mails do not match'),
              },
            }}
          />

          <div style={{ margin: '16px 0' }}>
            <Link to={`/${projectId}/login`} style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="secondary">
                Back
              </Button>
            </Link>

            {/* TODO: Full Width on Mobile */}
            <Button variant="contained" color="secondary" style={{ float: 'right' }} onClick={handleSubmit(onSubmit)}>
              Reset
            </Button>
          </div>
        </FormContainer>
      </Content>
      <Footer name="IR" />
    </>
  );
};

const mapState = (state) => {
  const { sent } = state.amnesia;
  return { sent, projectId: state.projectInfo.projectId };
};

const actionCreators = {
  sendResetLink: userActions.forgot,
};

export default connect(mapState, actionCreators)(LoginForgot);
