import React from 'react';
import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import theme from 'MuiTheme';

export interface OptionsMenuButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const OptionsMenuButton: React.FC<OptionsMenuButtonProps> = ({ icon, label, onClick, disabled }) => (
  <MenuItem
    onClick={onClick}
    style={{
      color: !disabled ? theme.palette.secondary.main : '',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    disabled={disabled}
  >
    {icon}
    <Typography variant="caption">{label}</Typography>
  </MenuItem>
);

export default OptionsMenuButton;
