import React, { useEffect, useState } from 'react';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Table, TableBody, TableHead, TableRow, TableContainer } from '@material-ui/core';
import BookingsHeader from '/components/adminUsers/BookingsHeader.component';
import BookingBodyDesktop from '/components/adminUsers/BookingsBodyDesktop.component';
import BookingBodyMobile from '/components/adminUsers/BookingsBodyMobile.component';
import DateInterval from '/components/adminUsers/DateInterval.component';
import PriceInterval from '/components/adminUsers/PriceInterval.component';
import Attendance from '/components/adminUsers/Attendance.component';

import { useForm } from 'react-hook-form';
import { adminUsersSetSelectedEvents, adminUsersSetEventFilters } from '/redux/reducers/AdminReducers';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@material-ui/core/Menu';

import { filterEventListAdmin } from '/utils/utils';

const BookingsList = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(Theme.breakpoints.down('xs'));
  const [sortBy, setSortBy] = useState('startDatetime');
  const [sortDirection, setSortDirection] = useState('asc');
  const events = useSelector((state) => state.adminUsers.events);
  const stateFilter = useSelector((state) => state.adminUsers.eventFilter);
  const selectedEvents = useSelector((state) => state.adminUsers.selectedEvents);
  const eventFilterSearch = useSelector((state) => state.adminUsers.eventFilterSearch);

  const { control, watch, reset } = useForm({ mode: 'onSubmit' });
  const watchFields = watch();

  const [timeAnchorEl, setTimeAnchorEl] = React.useState(null);
  const [priceAnchorEl, setPriceAnchorEl] = React.useState(null);
  const [attendedAnchorEl, setAttendedAnchorEl] = React.useState(null);

  // Create a general comparacter between two objects by key as parameter
  const comparator = (a, b, key) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };

  // Comparator for sorting things by date
  const orderComparator = (order, key) => {
    if (order === 'asc') return (a, b) => comparator(a, b, key);
    if (order === 'desc') return (a, b) => comparator(b, a, key);
  };

  const handleSort = (key) => {
    if (sortBy === key) {
      if (sortDirection === 'asc') setSortDirection('desc');
      else setSortDirection('asc');
    } else {
      setSortBy(key);
      setSortDirection('asc');
    }
  };

  // checks if two objects are deeply equal. this function should probably be more fitting if they
  // are in the utils file.
  const deepEqual = (a, b) => {
    if (typeof a == 'object' && a != null && typeof b == 'object' && b != null) {
      var count = [0, 0];
      for (var key in a) count[0]++;
      for (var key in b) count[1]++;
      if (count[0] - count[1] != 0) {
        return false;
      }
      for (var key in a) {
        if (!(key in b) || !deepEqual(a[key], b[key])) {
          return false;
        }
      }
      for (var key in b) {
        if (!(key in a) || !deepEqual(b[key], a[key])) {
          return false;
        }
      }
      return true;
    } else {
      return a === b;
    }
  };

  /**
   * pushes to change filter
   */
  const changeFilter = () => {
    let attended = [];
    if (watchFields.attended) attended.push('attended');
    if (watchFields.notAttended) attended.push('notAttended');

    const startDatetime = new Date(Date.parse(watchFields.startDate));
    startDatetime.setHours(0, 0, 0, 0);

    const endDatetime = new Date(Date.parse(watchFields.endDate));
    endDatetime.setHours(0, 0, 0, 0);

    let filter = {
      startDate: watchFields.startDate ? new Date(startDatetime).toJSON() : null,
      endDate: watchFields.endDate ? new Date(endDatetime).toJSON() : null,
      startPrice: watchFields.startPrice || null,
      endPrice: watchFields.endPrice || null,
      attended: attended || [],
    };

    if (!deepEqual(filter, stateFilter)) {
      dispatch(adminUsersSetEventFilters(filter));
    }
  };

  /**
   * Set events on change
   */
  useEffect(() => {
    if (!isMobile) changeFilter();
  }, [watchFields]);

  // time anchor click
  const timeHandleClick = (event) => {
    setTimeAnchorEl(event.currentTarget);
  };

  // price anchor click
  const priceHandleClick = (event) => {
    setPriceAnchorEl(event.currentTarget);
  };

  // attended anchor click
  const attendedHandleClick = (event) => {
    setAttendedAnchorEl(event.currentTarget);
  };

  // handle the clicking of an event
  const handleSelectEvent = (event) => {
    let pseudoEvents = [...selectedEvents];
    if (pseudoEvents.includes(event)) {
      pseudoEvents = pseudoEvents.filter((pseudoEvent) => pseudoEvent !== event);
    } else {
      pseudoEvents.push(event);
    }
    dispatch(adminUsersSetSelectedEvents(pseudoEvents));
  };

  // handle when clicking on the all checkbox
  const handleAllSelect = () => {
    if (selectedEvents.length !== events.length) {
      let pseudoEvents = [];
      events.forEach((event) => {
        pseudoEvents.push(event.bookingId);
      });
      dispatch(adminUsersSetSelectedEvents(pseudoEvents));
    } else {
      dispatch(adminUsersSetSelectedEvents([]));
    }
  };

  if (!events) return <></>;

  return (
    <>
      <Menu anchorEl={timeAnchorEl} keepMounted open={Boolean(timeAnchorEl)} onClose={() => setTimeAnchorEl(null)}>
        <div style={{ padding: '8px', width: '300px' }}>
          <DateInterval control={control} />
        </div>
      </Menu>

      <Menu anchorEl={priceAnchorEl} keepMounted open={Boolean(priceAnchorEl)} onClose={() => setPriceAnchorEl(null)}>
        <div style={{ padding: '8px', width: '300px' }}>
          <PriceInterval control={control} />
        </div>
      </Menu>

      <Menu
        anchorEl={attendedAnchorEl}
        keepMounted
        open={Boolean(attendedAnchorEl)}
        onClose={() => setAttendedAnchorEl(null)}
      >
        <div style={{ padding: '8px' }}>
          <Attendance control={control} />
        </div>
      </Menu>

      {!isMobile ? (
        <TableContainer style={{ marginTop: '20px' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <BookingsHeader
                  allChecked={events ? selectedEvents.length === events.length : false}
                  onClick={() => handleAllSelect()}
                  timeFilterClick={timeHandleClick}
                  priceFilterClick={priceHandleClick}
                  attendedFilterClick={attendedHandleClick}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  setSortBy={handleSort}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {filterEventListAdmin(events || [], stateFilter, eventFilterSearch)
                .sort(orderComparator(sortDirection, sortBy))
                .map((event, index) => (
                  <BookingBodyDesktop
                    onClick={() => handleSelectEvent(event.bookingId)}
                    isActivated={selectedEvents.includes(event.bookingId) || false}
                    key={index}
                    isPaid={event.basePriceSek ? event.isPaid : true}
                    isAttended={event.isAttending}
                    date={new Date(event.startDatetime).toISOString().substring(0, 10)}
                    title={event.displayTitle}
                    price={event.basePriceSek || 0}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {filterEventListAdmin(events || [], stateFilter, eventFilterSearch).map((event, index) => (
            <BookingBodyMobile
              onClick={() => handleSelectEvent(event.bookingId)}
              isActivated={selectedEvents.includes(event.bookingId) || false}
              key={index}
              isPaid={event.basePriceSek ? event.isPaid : true}
              isAttended={event.isAttending}
              date={new Date(event.startDatetime).toISOString().substring(0, 10)}
              title={event.displayTitle}
              price={event.basePriceSek || 0}
            />
          ))}
        </>
      )}
    </>
  );
};

export default BookingsList;
