import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Control, UseFormWatch } from 'react-hook-form';

import EventGeneral from './containers/EventGeneral';
import EventBooking from './containers/EventBooking';
import OpenEventEditSubMenu from '../../components/OpenEventEditSubMenu';
import EventEditButtons from './components/EventEditButtons';
import SpacedDivider from './components/SpacedDivider';
import useEditEventForm from './hooks/useEditEventForm';
import { EditEventFormCategory, EditEventFormValues } from './types/FormTypes';
import EditEventHeader from './components/EditEventHeader';
import ChipList from './components/ChipList';
import CategoryRender from './components/CategoryRender';
import usePatchEvent from './hooks/usePatchEvent';
import useGetEvent from '../../hooks/useGetEvent';

const buildCategories = (
  control: Control<EditEventFormValues, any>,
  watch: UseFormWatch<EditEventFormValues>
): EditEventFormCategory[] => {
  return [
    {
      label: 'General',
      path: 'general',
      element: <EventGeneral control={control} watch={watch} />,
    },
    {
      label: 'Booking',
      path: 'booking',
      element: <EventBooking control={control} />,
    },
  ];
};

const EditEvent = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const patchEvent = usePatchEvent();
  const eventData = useGetEvent(id as string);
  const { control, watch, handleSubmit } = useEditEventForm(eventData?.data);
  const categories = buildCategories(control, watch);

  const [activeTab, setActiveTab] = useState(categories[0].path);

  const onSubmit = (data: EditEventFormValues) => {
    patchEvent.patchEvent(parseInt(id as string, 10), data);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleActiveTab = (path: string) => {
    setActiveTab(path);
  };

  if (eventData.isLoading || eventData.isError) return <CircularProgress color="secondary" />;

  return (
    <>
      <OpenEventEditSubMenu />
      <EditEventHeader />
      <ChipList categories={categories} activeTab={activeTab} setActiveTab={handleActiveTab} />
      <SpacedDivider />
      <CategoryRender categories={categories} activeTab={activeTab} />
      <EventEditButtons onBack={handleBack} onSubmit={handleSubmit(onSubmit)} />
    </>
  );
};

export default EditEvent;
