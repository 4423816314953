import React from 'react';

import TabChip from 'components/tabChip/TabChip.component';
import ChipContainer from './ChipContainer';
import { EditEventFormCategory } from '../types/FormTypes';

interface ChipListProps {
  categories: EditEventFormCategory[];
  activeTab: string;
  setActiveTab: (path: string) => void;
}

const ChipList: React.FC<ChipListProps> = ({ categories, activeTab, setActiveTab }) => (
  <ChipContainer>
    {categories.map((chip) => (
      <TabChip
        key={`chip-${chip.path}`}
        label={chip.label}
        selected={activeTab === chip.path}
        onClick={() => setActiveTab(chip.path)}
      />
    ))}
  </ChipContainer>
);

export default ChipList;
