import { GetGeneralOptions, GetStudyOptions } from '/data/api/OptionsRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { optionsSetGeneral, optionsSetStudy } from '../reducers/OptionsReducers';

// Get Both Options
export const getOptions = createAsyncThunk('options/getOptions', async (params, { dispatch }) => {
  await dispatch(getGeneralOptions());
  await dispatch(getStudyOptions());
  return 'true';
});

// Get nationalities and gender
export const getGeneralOptions = createAsyncThunk('options/getGeneralOptions', async (params, ThunkAPI) => {
  try {
    const response = await GetGeneralOptions();
    const temp = response.data.data;
    ThunkAPI.dispatch(optionsSetGeneral({ gender: temp.gender, nationalities: temp.nationality }));
    return response.data.data;
  } catch (error) {
    ThunkAPI.rejectWithValue(error); // dispatch if rejected
  }
});

// Get study options
export const getStudyOptions = createAsyncThunk('options/getStudyOptions', async (params, ThunkAPI) => {
  try {
    const response = await GetStudyOptions();

    const sInfo = response.data.data;
    sInfo.sort((a, b) => a.nameEn.localeCompare(b.nameEn));
    const types = sInfo
      .map((d) => d.typeEn)
      .filter((v, i, s) => s.indexOf(v) === i)
      .sort((a, b) => a.localeCompare(b));
    sInfo.types = types;

    ThunkAPI.dispatch(optionsSetStudy(sInfo));

    return response.data.data;
  } catch (error) {
    ThunkAPI.rejectWithValue(error); // dispatch if rejected
  }
});
