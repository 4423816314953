import { useMutation } from "@tanstack/react-query";
import { patchAdminEvent } from "queries/AdminEventQueries";
import { PatchEvent } from "queries/AdminEventQueries.types";
import { snackActions } from "redux/actions/FeedbackActions";
import { useAppDispatch, useAppSelector } from "redux/storeConfig";
import { EditEventFormValues } from "../types/FormTypes";

interface MutationProps {
  eventId: number;
  event: EditEventFormValues;
}

const usePatchEvent = () => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const sendData = useMutation({
    mutationFn: async ({eventId, event}: MutationProps) => {
      const patchEvent: PatchEvent = {
        displayTitle: event.title,
        description: event.description,
        startDatetime: event.startDatetime,
        endDatetime: event.endDatetime,
        attendanceLimit: event.isNoAttendanceLimit ? 0 : event.attendanceLimit, // 0 means no limit
        basePriceSek: event.isFree ? 0 : event.price, // 0 means free
        location: event.location,
        bookingStartDatetime: event.bookingStartDatetime,
        bookingEndDatetime: event.bookingEndDatetime,
        unbookingEndDatetime: event.unbookingEndDatetime,
        swapEndDatetime: event.swapEndDatetime,
      }
      await patchAdminEvent(projectId, eventId, patchEvent)
    },
    onSuccess: () => {
      dispatch(snackActions.successFeedback("Event updated successfully"))
    }
  });

  const patchEvent = (eventId: number, event: EditEventFormValues) => {
    sendData.mutate({eventId, event});
  }

  return {
    isLoading: sendData.isLoading,
    patchEvent,
  };
};

export default usePatchEvent;
