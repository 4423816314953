import React from 'react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t: any) => ({
  dividerText: {
    color: `${t.palette.surface.text}${t.palette.other.opacityMedium}`,
  },
  divider: {
    paddingBottom: '8px',
    [t.breakpoints.up('md')]: {
      paddingBottom: '16px',
    },
  },
}));

type StartEventDividerProps = {
  date: Date;
};

/**
 * Displays the divider between days of events
 *
 * @param {Date} date the date to display
 */
const StartEventDivider = ({ date }: StartEventDividerProps) => {
  const classes = useStyles();

  const formattedDate = format(utcToZonedTime(date, 'Europe/Stockholm'), 'iiii, MMM do');

  return (
    <div className={classes.divider}>
      <Typography className={classes.dividerText} style={{ textAlign: 'center' }}>
        {formattedDate}
      </Typography>
      <Divider />
    </div>
  );
};

export default StartEventDivider;
