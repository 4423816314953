import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetEventGroups, UpdateEventGroup, CreateEventGroup, GetAllEvents } from '../../data/api/AdminRequest';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';

export const getEventGroups1 = createAsyncThunk('adminEventGroups/getEventGroups1', async (projectId) => {
  const response = await GetEventGroups(projectId);
  return response.data.data;
});

export const getAllEvents1 = createAsyncThunk('adminEventGroups/getAllEvents1', async (projectId) => {
  const response = await GetAllEvents(projectId);

  return response.data.data;
});

export const updateEventGroup1 = createAsyncThunk('adminEventGroups/updateEventGroup1', async (data, ThunkAPI) => {
  try {
    console.log('data: ', data);
    const response = await UpdateEventGroup(data.projectId, data);
    ThunkAPI.dispatch(snackActions.successFeedback('Event group saved'));
    console.log('response: ', response);
    return data;
  } catch (error) {
    ThunkAPI.dispatch(snackActions.errorFeedback(response.statusText));
    ThunkAPI.rejectWithValue(error); //Should try this out somehow.
  }
});

export const createEventGroup1 = createAsyncThunk('adminEventGroups/createEventGroup1', async (data, ThunkAPI) => {
  try {
    console.log('data: ', data);
    const response = await CreateEventGroup(data.projectId, data);
    ThunkAPI.dispatch(snackActions.successFeedback('Event group created'));
    console.log('response: ', response);
    return data;
  } catch (error) {
    ThunkAPI.dispatch(snackActions.errorFeedback(response.statusText));
    ThunkAPI.rejectWithValue(error); //Should try this out somehow.
  }
});
