import React from 'react';
import Theme from '/MuiTheme';

import { Button, Divider, Typography } from '@material-ui/core';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles({
  icon: {
    width: '18px',
    height: '16px',
  },
});

type OptionButton = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

const DesktopButton = ({ icon, label, onClick }: OptionButton) => (
  <Button style={{ color: Theme.palette.secondary.main, padding: 0, textTransform: 'capitalize' }} onClick={onClick}>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {icon}
      <Typography variant="caption">{label}</Typography>
    </div>
  </Button>
);

type MobileMenuProps = {
  items: OptionButton[];
};

const MobileMenu = ({ items }: MobileMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: '6px' }} color="secondary">
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {items.map((item) => (
          <MenuItem key={`admin-event-card-${item.label}`} onClick={item.onClick} style={{ color: Theme.palette.secondary.main }}>
            <ListItemIcon style={{ color: Theme.palette.secondary.main }}>{item.icon}</ListItemIcon>
            <Typography variant="inherit">{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

type EventAdminButtonProps = {
  isMobile: boolean;
  onManageClick: () => void;
  onEditClick: () => void;
  onTransferClick: () => void;
  onAddUserClick: () => void;
};

const EventAdminButton = ({ isMobile, onManageClick, onEditClick, onTransferClick, onAddUserClick }: EventAdminButtonProps) => {
  const classes = useStyles();

  const items: OptionButton[] = [
    {
      icon: <SyncAltIcon className={classes.icon} />,
      label: 'Transfer',
      onClick: onTransferClick,
    },
    {
      icon: <PersonAddOutlinedIcon className={classes.icon} />,
      label: 'Add user',
      onClick: onAddUserClick,
    },
  ];

  return (
    <>
      <Divider style={{ marginBottom: isMobile ? '8px' : '16px', marginTop: '8px' }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: !isMobile ? '8px' : undefined,
        }}
      >
        <div style={{ gap: '16px' }}>
          <Button variant="contained" color="secondary" onClick={onManageClick}>
            Manage
          </Button>
          <Button variant="outlined" color="secondary" style={{ width: '97px', marginLeft: '16px' }} onClick={onEditClick}>
            Edit
          </Button>
        </div>
        <div style={{ display: 'flex', gap: '40px' }}>
          {isMobile ? (
            <MobileMenu items={items} />
          ) : (
            items.map((item) => (
              <div key={`desktop-button-${item.label}`}>
                <DesktopButton icon={item.icon} label={item.label} onClick={item.onClick} />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default EventAdminButton;
