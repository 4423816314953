import React from 'react';

const PrivacyLastPoints = () => {
  return (
    <>
      <h6>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h6>
      <p>
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
        feature or setting you can activate to signal your privacy preference not to have data about your online
        browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and
        implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any
        other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
        tracking is adopted that we must follow in the future, we will inform you about that practice in a revised
        version of this privacy notice.
      </p>
      <h6>12. DO WE MAKE UPDATES TO THIS NOTICE?</h6>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version will be indicated by an updated
        "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
        directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of
        how we are protecting your information.
      </p>
      <h6>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h6>
      <p>If you have questions or comments about this notice, you may email us at info@thsint.se or by post to:</p>
      <p>THS International</p>
      <p>Drottning Kristinas V&auml;g 15</p>
      <p>Stockholm, Sweden 10044</p>
      <p>Sweden</p>
      <h6>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h6>
      <p>
        Based on the applicable laws of your country, you may have the right to request access to the personal
        information we collect from you, change that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please visit: info@thsint.se.
      </p>
    </>
  );
};

export default PrivacyLastPoints;
