import { Divider } from '@material-ui/core';
import React from 'react';
import AttendingButton from './AttendingButton';
import CheckedIn from './CheckedIn';
import { UsersEntryRow } from './CommonTypes';
import NameEmail from './NameEmail';
import OptionsMenu from './OptionsMenu';
import PaidButton from './PaidButton';

const UsersMobileRow: React.FC<UsersEntryRow> = ({ name, attending, paid, options, isCheckedIn }) => (
  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
    <NameEmail
      firstName={name.firstName}
      lastName={name.lastName}
      email={name.email}
      copyToClipboard={name.copyToClipboard}
      checkedIn={<CheckedIn displayText={false} checkedIn={isCheckedIn} />}
    />
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '0.5rem' }}>
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <AttendingButton isAttending={attending.isAttending} onClick={attending.onClick} />
        <PaidButton isPaid={paid.isPaid} isFree={paid.isFree} onClick={paid.onClick} />
      </div>
      <OptionsMenu onDeleteClick={options.onDeleteClick} onTransferClick={options.onTransferClick} />
    </div>
    <Divider style={{ marginTop: '10px' }} />
  </div>
);

export default UsersMobileRow;
