import * as Backend from './BackendRequest';

export const GetConfirmation = (token: string) => Backend.request('get', '/confirm', { token }, {});

export const GetUpdateEmail = (token: string) => Backend.request('get', '/updateEmail', { token }, {});

export const RequestProgrammes = () => Backend.request('GET', '/programmes', {});

export const RequestSignup = (data: any) => Backend.request('post', '/signup', {}, data); // TODO: add type

export const RequestConfirmEmail = (email: string, pid: string | number) => Backend.request('post', `/email/${pid}`, {}, {email});

export const RequestLogin = (payload: any, projectId: string | number) => // TODO: add type
  Backend.request('post', `/login/${projectId}`, {}, payload).then((data) =>
    Backend.storeResponse(data, 'token', 'token')
  );

export const Logout = () => {
  sessionStorage.removeItem('token');
};

export const RequestForgot = (payload: any, pid: string | number) => Backend.request('post', `/forgot/${pid}`, {}, payload); // TODO: add type

export const RequestReset = (token: string, payload: any) => Backend.request('post', '/reset', { token }, payload); // TODO: add type

export const RequestProject = (token: string) => Backend.request('get', '/user/project', {}); // todo: remove unused params
