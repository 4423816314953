import React from 'react';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import Typography from '@material-ui/core/Typography';

import FormLogo from '/components/formLogo/FormLogo.component';
import FormContainer from '/components/formContainer/FormContainer';
import { useAppSelector } from 'redux/storeConfig';

const SentForgot = () => {
  const forgottenEmail = useAppSelector((state) => state.amnesia.forgottenEmail);

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          <h6 style={{ marginTop: '16px' }}>Thank you!</h6>ß
          <Typography variant="body2" style={{ height: '180px' }}>
            We have sent a link to reset your password to <span style={{ color: '#497aa4' }}>{forgottenEmail}</span>
          </Typography>
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default SentForgot;
