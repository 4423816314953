import React, { useState } from 'react';
import ButtonComponent from '/components/forms-v2/Button.component';
import { useForm } from 'react-hook-form';
import EmailInput from '/containers/EmailInput/EmailInput';
import ConfirmEmailInput from '/containers/ConfirmEmailInput/ConfirmEmailInput';
import PasswordInput from '/containers/PasswordInput/PasswordInput';
import { EmailChangeDialog } from './EmailChangedDialog';
import { ChangeUserEmail } from '/data/api/ProfileRequest';
import { useDispatch } from 'react-redux';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';

export const ChangeEmailContainer = () => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({ mode: 'all' });

  const watchFields = watch(['email', 'confirmEmail', 'password']);

  const [isCallingBackend, setCallingBackend] = useState(false);
  const [open, setOpen] = useState(false);

  const buttonPressable = () => {
    let pressable = true;
    watchFields.forEach((field) => {
      if (field === '' || field === undefined) {
        pressable = false;
      }
    });
    return pressable;
  };

  const onSubmit = (data) => {
    setCallingBackend(true);
    ChangeUserEmail(data.password, data.email)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        dispatch(snackActions.errorFeedback(err.response.data.error.message));
      })
      .finally(() => {
        setCallingBackend(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6>Change E-Mail</h6>
        <PasswordInput name='password' control={control} label='Password' disableHelper />
        <EmailInput name='email' control={control} label='New E-mail' />
        <ConfirmEmailInput name='newEmail' control={control} label='Confirm New E-mail' getValues={getValues} />
        <ButtonComponent
          label={isCallingBackend ? 'Saving' : 'Save'}
          fixedWidth
          disabled={!buttonPressable() || isCallingBackend}
        />
        <EmailChangeDialog
          open={open}
          handleClose={handleClose}
          email={getValues('email')}
          password={getValues('password')}
        />
      </form>
    </>
  );
};
