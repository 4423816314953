import { makeStyles } from '@material-ui/core';

const useInputStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
    marginBottom: '16px',
  },
}));

export default useInputStyles;
