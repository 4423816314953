import React, { useState } from 'react';

import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { AdminHeader } from '/components/adminHeader';
import ClearableTextField from '/components/clearableTextField/ClearableTextField.component';
import { IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import AdminEventList from './containers/EventList';

const EventOverview = () => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <AdminHeader
        isMobile={isMobile}
        title="Event Overview"
        subtitle="Create and edit all events of the project. Use the management function to change payment and attendance status of the user."
      />
      <div style={{ display: 'flex', gap: '16px' }}>
        <ClearableTextField value={searchValue} setValue={setSearchValue} placeholder="Search Event" />
        <IconButton disabled style={{ width: '56px', height: '56px' }}>
          <FilterListIcon fontSize="small" />
        </IconButton>
      </div>
      <AdminEventList searchValue={searchValue} />
    </>
  );
};

export default EventOverview;
