import React from 'react';
import { Button } from '@material-ui/core';
import useEmailStyles from './useEmailStyles';

interface SuccessEmailProps {
  handleClick: () => void;
}

const SuccessEmail: React.FC<SuccessEmailProps> = ({ handleClick }) => {
  const classes = useEmailStyles();

  return (
    <>
      <h6 className={classes.header}>Thank you!</h6>
      <p className={classes.text}>Your account has been successfully created!</p>
      <Button className={classes.button} onClick={handleClick} variant="contained" color="secondary">
        Continue
      </Button>
    </>
  );
};

export default SuccessEmail;
