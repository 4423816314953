import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import useGetEvent from '../../hooks/useGetEvent';
import useGetEventStats from '../../hooks/useGetEventStats';
import OpenEventEditSubMenu from '../../components/OpenEventEditSubMenu';
import ManageEventHeader from './components/ManageEventHeader';
import EventDisplay from './containers/EventDisplay';
import BookedUsers from './containers/BookedUsers';

const ManageEvent = () => {
  const { id } = useParams();
  const eventData = useGetEvent(id as string);
  const statsData = useGetEventStats(id as string);

  if (
    eventData.isLoading ||
    eventData.isError ||
    statsData.isLoading ||
    statsData.isError ||
    !eventData.data ||
    !statsData.data
  )
    return <CircularProgress color="secondary" />;

  return (
    <>
      <OpenEventEditSubMenu />
      <ManageEventHeader />
      <EventDisplay event={eventData.data} stats={statsData.data} />
      <BookedUsers eventId={id as string} eventData={eventData.data} />
    </>
  );
};

export default ManageEvent;
