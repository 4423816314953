import React, { useState } from 'react';

import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import FormLogo from '/components/formLogo/FormLogo.component.tsx';
import FormContainer from '/components/formContainer/FormContainer';
import ResetForm from './resetForm/ResetForm';
import ResetDone from './resetDone/ResetDone';

export const LoginReset = () => {
  const [done, setDone] = useState(false);

  // sets to done when form is successfully sent
  const doneHandler = () => {
    setDone(true);
  };

  return (
    <>
      <Content greyBackground>
        <FormContainer>
          <FormLogo />
          {!done ? <ResetForm doneHandler={doneHandler} /> : <ResetDone />}
        </FormContainer>
      </Content>
      <Footer name='IR' />
    </>
  );
};
