import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    width: '100%',
    tableLayout: 'fixed',
  },
  tableCell: {
    display: 'flex',
    marginBottom: '8px',
    width: '100%',
  },
  iconMobile: {
    marginRight: '8px',
    height: '20px',
    marginLeft: '16px',
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  successIcon: {
    color: theme.palette.success.main,
  },
}));

const CheckList = ({ items, isMobile }) => {
  const classes = useStyles();

  // Builds a table 1x4
  const buildMobile = (items) => {
    let returnArray = [];
    items.forEach((item, index) => {
      returnArray.push(
        <tr key={`checklist_${index}`}>
          <td>
            <div className={classes.tableCell}>
              {item.success ? (
                <CheckIcon className={`${classes.iconMobile} ${classes.successIcon}`} />
              ) : (
                <WarningIcon className={`${classes.iconMobile} ${classes.warningIcon}`} />
              )}
              <div>
                <Typography variant='body2'>{item.title}</Typography>
                <Typography variant='caption' color='textSecondary'>
                  {item.description}
                </Typography>
              </div>
            </div>
          </td>
        </tr>
      );
    });

    return returnArray;
  };

  // Builds a table 1x4
  const buildDesktop = (items) => {
    let returnArray = [];
    items.forEach((item, index) => {
      returnArray.push(
        <tr key={`checklist_${index}`}>
          <td>
            <div className={classes.tableCell}>
              {item.success ? (
                <CheckIcon className={`${classes.iconMobile} ${classes.successIcon}`} />
              ) : (
                <WarningIcon className={`${classes.iconMobile} ${classes.warningIcon}`} />
              )}
              <div>
                <Typography variant='body2' component='span'>
                  {item.title}:{' '}
                </Typography>
                <Typography variant='caption' color='textSecondary' component='span'>
                  {item.description}
                </Typography>
              </div>
            </div>
          </td>
        </tr>
      );
    });

    return returnArray;
  };

  // Builds a table 2x2
  const renderRows = (entries) => {
    let returnArray = [];
    let row = [];
    let currentIndex = 0;

    entries.forEach((entry, i) => {
      row.push(
        <td key={`cell_${i}`} style={{ verticalAlign: 'top' }}>
          <div className={classes.tableCell}>
            {entry.success ? (
              <CheckIcon className={`${classes.iconMobile} ${classes.successIcon}`} />
            ) : (
              <WarningIcon className={`${classes.iconMobile} ${classes.warningIcon}`} />
            )}
            <div>
              <Typography variant='body2'>{entry.title}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {entry.description}
              </Typography>
            </div>
          </div>
        </td>
      );

      currentIndex += 1;
      if (currentIndex >= 2) {
        returnArray.push(<tr key={`row-${returnArray.length}`}>{row}</tr>);

        row = [];
        currentIndex = 0;
      }
    });

    return returnArray;
  };

  return (
    <>
      <table className={classes.tableRoot}>
        <tbody>{isMobile ? buildMobile(items) : buildDesktop(items)}</tbody>
      </table>
    </>
  );
};

export default CheckList;
