import React from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Theme from '/MuiTheme';
import { Control, Controller } from 'react-hook-form';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#9cacbc',
  },
  checked: {
    color: Theme.palette.secondary.dark,
  },
  track: { backgroundColor: `rgba(0, 38, 73, 0.38)` },
})(Switch);

const useStyles = makeStyles((t: any) => ({
  textNormal: {
    color: `${t.palette.surface.text}${t.palette.other.opacityHigh}`,
  },
  textDisabled: {
    color: `${t.palette.surface.text}${t.palette.other.opacityDisabled}`,
  },
}));

interface SwitchComponentProps {
  name: string;
  control: Control<any, any>;
  labelOn?: string;
  labelOff?: string;
  disabled?: boolean;
}

/**
 * Renders a full-width Select component with options
 * @param data:string, A React hook data
 * @param setData:function, Setter for react hook data
 * @param label:string, label for component
 * @param options:list list of {value, label} objects for options to choose
 * @param error:string, renders error if this is not empty
 */
const SwitchComponent: React.FC<SwitchComponentProps> = ({ name, control, labelOn, labelOff, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <Grid component='label' container alignItems='center' spacing={1}>
            {labelOff ? (
              <Grid item className={disabled ? classes.textDisabled : classes.textNormal}>
                {labelOff}
              </Grid>
            ) : null}
            <Grid item>
              <PurpleSwitch
                checked={field.value}
                color='secondary'
                onChange={(e) => field.onChange(e.target.checked)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={disabled ? disabled : false}
              />
            </Grid>
            {labelOn ? (
              <Grid item className={disabled ? classes.textDisabled : classes.textNormal}>
                {labelOn}
              </Grid>
            ) : null}
          </Grid>
        )}
      />
    </>
  );
};

export default SwitchComponent;
