import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { RequestConfirmEmail } from '/data/api/AuthRequest';
import { connect } from 'react-redux';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((t) => ({
  header: {
    marginTop: '16px',
  },
  text: {
    marginTop: '5px',
    marginBottom: '16px',
  },
  button: {
    width: '100%',
    marginBottom: '40px',
  },
}));

/**
 * Renders success creation page
 *
 * This page is divided also
 */
const StepCreated = (props) => {
  const classes = useStyles();

  const email = props.email ? props.email : 'notworking@null.com';

  const resendEmail = () => {
    RequestConfirmEmail({ email: email }, props.projectId)
      .then(() => props.openSuccess('E-mail sent!'))
      .catch((err) => {
        if (err.response.status < 500) props.openFailure('' + err.response.data.error.message);
        else props.openFailure('Server error');
      });
  };

  return (
    <>
      <h6 className={classes.header}>Verify your e-mail address</h6>
      <p className={classes.text}>
        We have sent a verification link to your e-mail address <a href={'mailto:' + email}>{email}</a>. Please follow
        the instruction in the e-mail.
      </p>
      {!props.disabled && (
        <Button className={classes.button} onClick={resendEmail} variant='contained' color='secondary'>
          Resend E-mail
        </Button>
      )}
    </>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

const mapDispatchToProps = {
  openSuccess: snackActions.successFeedback,
  openFailure: snackActions.errorFeedback,
};

export default connect(mapState, mapDispatchToProps)(StepCreated);
