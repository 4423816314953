import React from 'react';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t: any) => ({
  title: {
    marginTop: (props: any) => (props.isMobile ? '8px' : '16px'),
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: (props: any) => (props.isMobile ? '8px' : '16px'),
  },
  chip: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  disabled: {
    color: `${t.palette.surface.text}${t.palette.other.opacityDisabled}`,
  },
  active: {
    backgroundColor: `${t.palette.secondary.main}${t.palette.overlay.opacity.selected}`,
    color: `${t.palette.secondary.main}${t.palette.other.opacityHigh}`,
  },
}));

type EventCategoryProps = {
  title?: string;
  isMobile?: boolean;
  categories?: React.ReactNode[];
  disabled?: boolean;
  active?: boolean;
};

const EventCategory = ({ title = 'Category', isMobile = false, categories = [], disabled = false, active = false }: EventCategoryProps) => {
  const classes = useStyles({ isMobile });

  const chipStyling = () => {
    if (disabled) return `${classes.chip} ${classes.disabled}`;
    if (active) return `${classes.chip} ${classes.active}`;
    return classes.chip;
  };

  return (
    <>
      <Typography variant="subtitle2" component="p" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.chipsContainer}>
        {categories.map((cat) => (
          <Chip key={`category-${cat}`} label={cat} className={chipStyling()} />
        ))}
        {categories.length === 0 ? (
          <Typography variant="body2" component="p">
            No Category applied
          </Typography>
        ) : null}
      </div>
    </>
  );
};

export default EventCategory;
