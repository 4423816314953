import React from 'react';

const PrivacyInfoKeeping = () => {
  return (
    <>
      <h6>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h6>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
        </em>
      </p>
      <p>
        We will only keep your personal information for as long as it is necessary for the purposes set out in this
        privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or
        other legal requirements). No purpose in this notice will require us keeping your personal information for
        longer than the period of time in which users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your personal information, we will either delete or
        anonymize such information, or, if this is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal information and isolate it from any
        further processing until deletion is possible.
      </p>
      <h6>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h6>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We aim to protect your personal information through a system of organizational and technical security
          measures.
        </em>
      </p>
      <p>
        We have implemented appropriate technical and organizational security measures designed to protect the security
        of any personal information we process. However, despite our safeguards and efforts to secure your information,
        no electronic transmission over the Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will
        not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although
        we will do our best to protect your personal information, transmission of personal information to and from our
        Website is at your own risk. You should only access the Website within a secure environment.
      </p>
    </>
  );
};

export default PrivacyInfoKeeping;
