import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminShowingEventGroup,
  adminEventGroupsDisplayEventGroupSet,
  adminEventGroupsEventsSet,
  adminEventGroupsEventFilterSearch,
} from '../../../redux/reducers/EventGroupsReducers';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { Divider, Typography, Fab } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Theme from '/MuiTheme';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckIcon from '@material-ui/icons/Check';
import { useTheme } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { deleteEventGroup, fetchEventList } from '../../../redux/actions/AdminActions';
import { updateEventGroup1, getAllEvents1, createEventGroup1 } from '../../../redux/actions/EventGroupsActions';
import { CheckboxEventList } from '../../../components/adminCheckboxEventList/CheckboxEventList';
import ClearableTextField from '/components/clearableTextField/ClearableTextField.component';

function CreateEventGroup({ showEventGroupId }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery(Theme.breakpoints.up('sm'));
  const allEventGroups = useSelector((state) => state.adminEventGroups.eventGroups);
  const projectId = useSelector((state) => state.projectInfo.projectId);
  const eventFilterSearch = useSelector((state) => state.adminEventGroups.eventFilterSearch);

  //If we're in "create" mode, turn on isEditing directly.
  const [isEditing, setIsEditing] = useState(location.hash === '#createEventGroup' ? true : false);
  const [limitingTickets, setLimitingTickets] = useState(false); //Consider removing
  const [checkedEvents, setCheckedEvents] = useState();
  const [shapedEvents, setShapedEvents] = useState();
  const [oldCheckedEvents, setOldCheckedEvents] = useState(); //Remembers what to reset CheckboxEventList to.

  const [sortBy, setSortBy] = useState('startDatetime');
  const [sortDirection, setSortDirection] = useState('asc');

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
  } = useForm();

  const { ref: inputRef, ...inputProps } = register('name', {
    required: true, //can also have the value of 'error text', but the error text is currently provided by other means further down.
    disabled: !isEditing,
  });

  const { ref: inputRef2, ...inputProps2 } = register('ticketLimit', {
    disabled:
      //displayEventGroup?.ticketLimit === 0 &&
      // !isEditing,
      !watch('checkTicketLimit'), // && limitingTickets && !isEditing,
    //!(limitingTickets && isEditing), //Working in edit and create.
    //!(limitingTickets && isEditing) || !showEventGroupId, //Attempt does not work in create
    //!isEditing /*  && !isEditing && displayEventGroup?.ticketLimit === 0 */,
    //disabled: (!isEditing && !limitingTickets) || (!isEditing && limitingTickets) || (isEditing && !limitingTickets),
    valueAsNumber: true,
  });

  //The comparator functions should probably be shared across the project from some suitable location.
  // Create a general comparacter between two objects by key as parameter
  const comparator = (a, b, key) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };

  // Comparator for sorting things by date
  const orderComparator = (order, key) => {
    if (order === 'asc') return (a, b) => comparator(a, b, key);
    if (order === 'desc') return (a, b) => comparator(b, a, key);
  };

  const createEventsForListCallback = (result) => {
    return result.payload.map((event) => {
      return { id: event.eventId, name: event.displayTitle, date: new Date(event.startDatetime) };
    });
  };

  useEffect(() => {
    console.log('about to get all events...');
    dispatch(getAllEvents1(projectId)).then((result) => {
      //console.log('result :', result);
      const temp = createEventsForListCallback(result);
      //console.log('temp: ', temp);
      setShapedEvents(temp); //Only after getting the events above do I want to shape them.
    });
  }, []);

  useEffect(() => {
    allEventGroups.find((eventGroup) => {
      if (eventGroup?.id === showEventGroupId) {
        //console.log('got here');
        dispatch(adminEventGroupsDisplayEventGroupSet(eventGroup));
        return true;
      }
    });
  }, [allEventGroups]);

  const displayEventGroup = useSelector((state) => state.adminEventGroups.displayEventGroup);

  useEffect(() => {
    displayEventGroup?.ticketLimit !== 0 ? setLimitingTickets(true) : setLimitingTickets(false);
    displayEventGroup?.ticketLimit !== 0
      ? setValue('checkTicketLimitBox', true)
      : setValue('checkTicketLimitBox', false);
    setCheckedEvents(displayEventGroup?.eventIDs); //Set checked events for this event group.
  }, [displayEventGroup]);

  //const allEvents = useSelector((state) => state.adminEventGroups.events);

  const handleSaveEventGroup = (data) => {
    if (showEventGroupId) {
      //Editing an existing event group.
      console.log('Form submitted, prior to removing checkticketLimit: ', data);
      const tempRequestBody = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != undefined)); //Removing all undefined properties from 'data'. Perhaps this can be set in react-hook-form configuration.

      const tempData = {
        projectId: projectId,
        eventGroupId: showEventGroupId,
        requestBody: tempRequestBody,
      };
      tempData.requestBody.eventIDs = checkedEvents;
      if (tempData.requestBody.checkTicketLimit === false) {
        tempData.requestBody.ticketLimit = 0;
      }
      delete tempData.requestBody?.checkTicketLimit; //Ignoring a ckeckbox. Perhaps can be ignored with react-hook-form already.
      console.log('Form submitted, after removing checkTicketLimit: ', tempData);
      dispatch(updateEventGroup1(tempData)); //TODO remember to check if I have updated the event IDs in redux.
    } else {
      //Creating a new event group
      console.log('Saving new event group, initial data argument: ', data);
      const tempRequestBody = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != undefined)); //Removing all undefined properties from 'data'. Perhaps this can be set in react-hook-form configuration.
      const tempData = {
        projectId: projectId,
        //eventGroupId: showEventGroupId,
        requestBody: tempRequestBody,
      };
      tempData.requestBody.eventIDs = checkedEvents;
      //if (tempData.requestBody.checkTicketLimit === false) { //This gives an erro, but when is this required?
      //  tempData.requestBody.ticketLimit = 0;
      //}
      delete tempData.requestBody?.checkTicketLimit; //Ignoring a ckeckbox. Perhaps can be ignored with react-hook-form already.
      console.log('Saving new event goup, data has been tranformed to: ', tempData);
      dispatch(createEventGroup1(tempData));
    }
  };

  const onBackToOverview = () => {
    if (!showEventGroupId) {
      //Creating new event group.
      navigate('#eventGroups');
    } else {
      //Editing existing event group.
      dispatch(adminShowingEventGroup(undefined));
    }
  };

  const handleChangeLimitTickets = () => {
    if (getValues('checkTicketLimit')) {
      console.log('Checkbox was checked and we are unchecking it.');
      setLimitingTickets(false);
      setValue('ticketLimit', '0');
    } else {
      console.log('Checkbox was unchecked and we are checking it.');
      setLimitingTickets(true);
    }
    // setLimitingTickets(!limitingTickets);
    // if (!getValues('checkTicketLimit')) {
    //   console.log('unchecked limit tickets.');
    // }
  };

  const handleDeleteEventGroup = () => {
    dispatch(deleteEventGroup(showEventGroupId));
    onBackToOverview(); //try this out...
  };

  const handleEnterEditMode = (event) => {
    setIsEditing(true);
    //Prevent form submission. A button inside a form element will always fire
    //a submit, even by default. Do this, or or add the type="button" attribute.
    event.preventDefault();
    console.log('Edit mode entered.');
    setOldCheckedEvents(checkedEvents);
  };

  const resetFormManually = () => {
    setValue('name', '');
    setValue('checkTicketLimit', false);
    setValue('isBroadcasted', false);
    setValue('ticketLimit', 0);
    setCheckedEvents([]);
    setLimitingTickets(false);
  };

  const handleExitEditMode = () => {
    if (location.hash !== '#createEventGroup') {
      //We're in "info" mode. Reset the form to its original values.
      setIsEditing(false);
      displayEventGroup?.ticketLimit !== 0 ? setLimitingTickets(true) : setLimitingTickets(false);
      displayEventGroup?.ticketLimit !== 0
        ? setValue('checkTicketLimitBox', true)
        : setValue('checkTicketLimitBox', false);
      console.log('checkTicketLimitBox: ', getValues('checkTicketLimitBox'));
      reset();
      setCheckedEvents(oldCheckedEvents); //Resets the CheckboxEventList
    } else {
      //We're in "create" mode.
      console.log('Clear form fields to empty.');
      resetFormManually();
    }
  };

  const checkBoxClickCallback = (eventId) => {
    if (!checkedEvents.includes(eventId)) {
      console.log('Did not find the event in checkedEvents, adding...');
      setCheckedEvents([...checkedEvents, eventId]);
    } else {
      console.log('Did find the event in checkedEvents, removing...');
      setCheckedEvents(
        checkedEvents.filter((event) => {
          console.log(event);
          return event !== eventId;
        })
      );
    }
    console.log('The id of the selected event is: ', eventId);
  };

  const filterEventList = (shapedEvents, eventFilterSearch) => {
    //shapedEvents.sort(orderComparator(sortDirection, sortBy));
    // check data is existing or filters are
    if (!shapedEvents || shapedEvents.length == 0) {
      return [];
    }

    let filteredData = shapedEvents;
    // Search
    filteredData = filteredData.filter((entry) => {
      if (eventFilterSearch.length < 3) return true;
      return entry.name.toLowerCase().includes(eventFilterSearch.toLowerCase());
    });
    return filteredData;
  };

  //This sets the form up for creating a new event group, rather than editing it.
  useEffect(() => {
    if (!showEventGroupId) {
      console.log('Creating new event group');
      resetFormManually();
    }
  }, []);

  useEffect(() => {
    //This one is needed to set the 'checkTicketLimit' under proper conditions so that 'ticketLimit'
    //knows when to be disabled (by watching 'checkTicketLimit').
    if (isEditing && limitingTickets && showEventGroupId && location.hash !== '#createEventGroup') {
      console.log('Enable checkTicketLimit Checkbox and ticketLimit TextField only in this situation.');
      if (limitingTickets) {
        setValue('checkTicketLimit', true);
      }
    }
  }, [isEditing, limitingTickets, displayEventGroup]);

  useEffect(() => {
    //This is needed in order to uncheck the checkTicketLimit box after form submission in this particular state.
    if (showEventGroupId && displayEventGroup && isEditing && !limitingTickets) {
      console.log('Disable checkTicketLimit Checkbox and ticketLimit TextField only in this situation.');
      setValue('checkTicketLimit', false);
      //setLimitingTickets(false);
    }
  }, [displayEventGroup, isEditing, limitingTickets]);

  // const createEventsForListCallback = () => {
  //   return allEvents.forEach((event, index, arr) => {
  //     arr[index] = { id: event.eventId, name: event.displayTitle, date: new Date(event.startDatetime) };
  //     console.log(arr[index]);
  //   });
  // };

  console.log('limitingTickets: ', limitingTickets);
  console.log('isEditing: ', isEditing);
  console.log('displayEventGroup: ', displayEventGroup);
  //console.log('shapedEvents: ', shapedEvents);
  //console.log('checkedEvents: ', checkedEvents);
  return (
    <React.Fragment>
      {isDesktop && (
        <Button variant='text' color='secondary' startIcon={<ArrowBackIcon />} onClick={onBackToOverview}>
          Back to overview
        </Button>
      )}
      {!isDesktop && (
        <div
          style={{
            height: '56px',
            backgroundColor: '#FAFAFA',
            zIndex: '1200',
            width: '100%',
            minWidth: '1vh',
            position: 'fixed',
            top: '0',
            marginLeft: '-16px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
            <Button
              variant='text'
              color='secondary'
              startIcon={<ArrowBackIcon />}
              onClick={onBackToOverview}
              style={{ marginLeft: '10px' }}
            >
              Back to overview
            </Button>
            {showEventGroupId && !isEditing ? (
              <IconButton onClick={handleDeleteEventGroup} style={{ marginRight: '20px' }}>
                <DeleteOutlineIcon color='secondary' />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleExitEditMode}
                style={{ marginRight: '20px' }}
                aria-label='cancel edits and reset form'
              >
                <CloseIcon color='primary' />
              </IconButton>
            )}
          </div>
        </div>
      )}

      <div
        style={
          !isDesktop
            ? {
                marginTop: '-48px',
                zIndex: '1150',
                backgroundColor: 'white',
                position: 'absolute',
                marginLeft: '-16px',
                paddingLeft: '16px',
                paddingRight: '16px',
              }
            : null
        }
      >
        <form noValidate onSubmit={handleSubmit(handleSaveEventGroup)}>
          <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '16px' }}>
            {(displayEventGroup || !showEventGroupId) && (
              <Controller
                name='name'
                control={control}
                rules={{ required: 'Event group name is required' }}
                render={({
                  field: { onChange, value = displayEventGroup?.name ? displayEventGroup?.name : '' },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={inputRef}
                    {...inputProps}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    id='standard-error-helper-text'
                    label={isDesktop ? 'Event Group Name' : 'Group name'}
                    variant='outlined'
                    style={{ flexGrow: '1' }}
                    helperText={error?.message}
                  />
                )}
              />
            )}
            {/*errors.name?.type === 'required' && 'Event group name is required'*/}
            {/*errors.name && errors.name.message*/}
            {isDesktop && (
              <React.Fragment>
                {showEventGroupId && !isEditing ? (
                  <Button color='secondary' onClick={handleDeleteEventGroup} style={{ marginLeft: '16px' }}>
                    Delete
                  </Button>
                ) : (
                  <Button color='secondary' onClick={handleExitEditMode} style={{ marginLeft: '16px' }}>
                    Cancel
                  </Button>
                )}

                <Button
                  {...(isEditing ? { type: 'submit' } : {})}
                  {...(!isEditing ? { onClick: handleEnterEditMode } : {})}
                  //onClick={handleEnterEditMode}
                  color='secondary'
                  variant='contained'
                  style={{ marginLeft: '16px' }}
                >
                  {!isEditing ? 'Edit' : 'Save'}
                </Button>
              </React.Fragment>
            )}
          </div>
          <Divider style={{ marginTop: '16px' }} />
          <div style={{ display: isDesktop ? 'flex' : '', alignItems: 'baseline' }}>
            <div style={{ display: 'block', flexGrow: 2 }}>
              <Typography color='textPrimary' variant='h6' style={{ marginTop: '30px' }}>
                Applied Events
              </Typography>
              <Typography color='textPrimary' variant='body2' style={{ marginBottom: '24px' }}>
                Select events the group applies to.
              </Typography>
            </div>
            <ClearableTextField
              label='Search Events'
              value={eventFilterSearch}
              setValue={(e) => dispatch(adminEventGroupsEventFilterSearch(e))}
              style={
                { width: isDesktop ? 'unset' : '', flexGrow: 1, marginBottom: !isDesktop ? '22px' : '' } //marginRight: isDesktop && '16px'
              }
              disabled={!isEditing}
            />
          </div>

          {showEventGroupId
            ? displayEventGroup &&
              shapedEvents &&
              checkedEvents && (
                <CheckboxEventList
                  checked={checkedEvents}
                  events={filterEventList(shapedEvents, eventFilterSearch)}
                  onClick={checkBoxClickCallback}
                  isMobile={!isDesktop}
                  isEditing={isEditing}
                ></CheckboxEventList>
              )
            : shapedEvents &&
              checkedEvents && (
                <CheckboxEventList
                  checked={checkedEvents}
                  events={filterEventList(shapedEvents, eventFilterSearch)}
                  onClick={checkBoxClickCallback}
                  isMobile={!isDesktop}
                  isEditing={isEditing}
                ></CheckboxEventList>
              )}

          <Typography color='textPrimary' variant='h6' style={{ marginTop: '30px', marginBottom: '8px' }}>
            Settings
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {(displayEventGroup || !showEventGroupId) && (
              <FormControlLabel
                disabled={!isEditing}
                control={
                  <Controller
                    name='isBroadcasted'
                    control={control}
                    render={({ field: { onChange, value = displayEventGroup?.isBroadcasted ? true : false } }) => (
                      <Checkbox
                        checked={value}
                        disabled={!isEditing}
                        onChange={onChange}
                        name='checkedDisplayEventGroups'
                      />
                    )}
                  />
                }
                label='Display event group to users.'
                style={{ flexGrow: '1' }}
              />
            )}

            <Typography
              color='textSecondary'
              variant='caption'
              style={{ flexBasis: '100%', marginLeft: '35px', marginTop: '-10px' }}
            >
              Shown as "Event Category" on the Booking page.
            </Typography>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {(displayEventGroup || !showEventGroupId) && (
              <FormControlLabel
                disabled={!isEditing}
                control={
                  <Controller
                    name='checkTicketLimit'
                    control={control}
                    render={({ field: { onChange, value = displayEventGroup?.ticketLimit !== 0 ? true : false } }) => (
                      <Checkbox
                        checked={value}
                        {...register('checkTicketLimitBox')}
                        disabled={!isEditing}
                        onChange={(e) => {
                          handleChangeLimitTickets();
                          onChange(e.target.checked); //Required by react-hook-form
                        }}
                        name='checkTicketLimit'
                      />
                    )}
                  />
                }
                label='Limit the amount of bookable tickets.'
                style={{ flexGrow: '1' }}
              />
            )}

            <Typography
              color='textSecondary'
              variant='caption'
              style={{ flexBasis: '100%', marginLeft: '35px', marginTop: '-10px' }}
            >
              Does not apply to bookings made through the admin pages.
            </Typography>
          </div>
          {(displayEventGroup || !showEventGroupId) && (
            <Controller
              name='ticketLimit'
              control={control}
              rules={{ required: 'Number of limited tickets is required, if the ticket limit checkbox is checked.' }}
              render={({
                field: { onChange, value = displayEventGroup?.ticketLimit !== 0 ? displayEventGroup?.ticketLimit : '' },
                fieldState: { error },
              }) => (
                <TextField
                  id='event-group-ticket-limit'
                  inputRef={inputRef2}
                  {...inputProps2}
                  // disabled={!limitingTickets}
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  label='Ticket Limit'
                  variant='outlined'
                  style={{ marginTop: '11px', marginLeft: '35px' }}
                  helperText={error?.message}
                  //disabled={!showEventGroupId}
                />
              )}
            />
          )}

          {/*  Viewing/editing/creating event group:  */}
          {!isDesktop && (
            <Fab
              onClick={!isEditing && showEventGroupId ? handleEnterEditMode : handleSaveEventGroup}
              {...(isEditing ? { type: 'submit' } : {})}
              aria-label='enter edit mode'
              style={{
                position: 'fixed',
                right: '20px',
                bottom: '20px',
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                zIndex: '1200',
              }}
              children={!isEditing && showEventGroupId ? <EditOutlinedIcon /> : <CheckIcon />}
            />
          )}
        </form>
      </div>
    </React.Fragment>
  );
}

export default CreateEventGroup;
