import React from 'react';
import { Control } from 'react-hook-form';

import DateComponent from 'components/forms-v2/Date.component';
import useBookingStyles from './EventBooking.styles';
import useStartEndStyles from './EventStartEnd.styles';
import { editEventFormKeys } from '../types/FormTypes';
import EventBookingHeader from '../components/EventBookingHeader';

interface EventBookingProps {
  control: Control<any, any>;
}

const noMargins = { marginTop: '0', marginBottom: '0' };

const EventBooking: React.FC<EventBookingProps> = ({ control }) => {
  const classes = useBookingStyles();
  const startEndClasses = useStartEndStyles();

  return (
    <>
      <EventBookingHeader />
      <div className={classes.inputContainer}>
        <div className={startEndClasses.inputContainer}>
          <DateComponent
            control={control}
            name={editEventFormKeys.bookingStartDatetime}
            label="Booking start"
            style={noMargins}
            helperText="Date and time from which the event can be booked by users"
            isDatetime
          />
          <DateComponent
            control={control}
            name={editEventFormKeys.bookingEndDatetime}
            label="Booking end"
            style={noMargins}
            helperText="Date and time until which the event can be booked by users"
            isDatetime
          />
        </div>
        <DateComponent
          control={control}
          name={editEventFormKeys.unbookingEndDatetime}
          label="Unbooking End"
          style={noMargins}
          helperText="Date and time until which the event can be unbooked by ticket holders"
          isDatetime
        />
        <DateComponent
          control={control}
          name={editEventFormKeys.swapEndDatetime}
          label="Ticket Transfer End"
          style={noMargins}
          helperText="Date and time until which users can transfer their ticket to another user"
          isDatetime
        />
      </div>
    </>
  );
};

export default EventBooking;
