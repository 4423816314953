import React from 'react';
import { useForm } from 'react-hook-form';

import { useAppDispatch } from 'redux/storeConfig';
import { snackActions } from '/redux/actions/FeedbackActions';
import ButtonComponent from '/components/forms-v2/Button.component';
import PasswordInput from '/containers/PasswordInput/PasswordInput';
import ConfirmPasswordInput from '/containers/ConfirmPasswordInput/ConfirmPasswordInput';
import PasswordInputNoValidation from 'containers/PasswordInput/PasswordInputNoValidation';
import useChangePassword from 'queries/profile/useChangePassword';

interface FormValues {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const ChangePasswordContainer = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, getValues, watch, control } = useForm<FormValues>({ mode: 'all' });
  const watchFields = watch(['confirmPassword', 'oldPassword', 'password']);
  const changePassword = useChangePassword({
    onSuccess: () => {
      dispatch(snackActions.successFeedback('Password Changed Successfully'));
    },
  });

  const buttonPressable = () => {
    let pressable = true;
    watchFields.forEach((field) => {
      if (field === '' || field === undefined) {
        pressable = false;
      }
    });

    if (changePassword.isLoading) {
      pressable = false;
    }

    return pressable;
  };

  const onSubmit = async (data: FormValues) => {
    const { password, oldPassword } = data;
    changePassword.mutate({ password, oldPassword });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6>Change Password</h6>
        <PasswordInputNoValidation
          name="oldPassword"
          control={control}
          label="Old Password"
          rules={{
            validate: {
              passwordMatch: (v: string) =>
                v !== getValues('password') ? true : 'Old password is the same as new one',
            },
          }}
        />
        <PasswordInput name="password" control={control} label="New Password" disableHelper={false} rules={{}} />
        <ConfirmPasswordInput control={control} getValues={getValues} />
        <ButtonComponent
          label="Save"
          fixedWidth
          disabled={!buttonPressable()}
          click={undefined}
          fullWidth={undefined}
        />
      </form>
    </>
  );
};

export default ChangePasswordContainer;
