import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';
import Emojify from '/components/emojify/Emojify';

const useStyles = makeStyles((t) => ({
  markdownClass: {
    '& p, ul': {
      marginTop: '16px',
      marginBottom: '0px',
    },
  },
  unbookTicket: {
    backgroundColor: t.palette.error.main,
    '&:hover': {
      backgroundColor: t.palette.error.dark,
    },
  },
  emojifyNormal: {
    '& .emoji': {
      opacity: 0.6,
      height: '1.25em',
      width: '1.25em',
      margin: '0 .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
  },
  emojifyBooked: {
    '& .emoji': {
      opacity: 1.0,
      height: '1.25em',
      width: '1.25em',
      margin: '0 .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
  },
  emojifyDisabled: {
    '& .emoji': {
      opacity: 0.38,
      height: '1.25em',
      width: '1.25em',
      margin: '0 .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
  },
}));

type EventDescriptionProps = {
  disabled?: boolean;
  active?: boolean;
  description?: string;
  [key: string]: any;
};

const EventDescription = ({ disabled = false, active = false, description = '', ...props }: EventDescriptionProps) => {
  const classes = useStyles();

  const styleEmojify = () => {
    if (active) return classes.emojifyBooked;
    if (disabled) return classes.emojifyDisabled;
    return classes.emojifyNormal;
  };

  return (
    <Typography variant="body2" component="span" {...props}>
      <Emojify className={styleEmojify()}>
        <ReactMarkdown className={classes.markdownClass}>{description}</ReactMarkdown>
      </Emojify>
    </Typography>
  );
};

export default EventDescription;
