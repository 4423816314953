import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuBar from './MenuBar';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import logo from '../../static/logo_500.png';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

const CustomListItem = withStyles((t) => ({
  root: {
    fontSize: '24px',
    height: '48px',
    paddingTop: t.spacing(1),
    paddingBottom: t.spacing(1),
    paddingLeft: '8px',
    color: `#00000087`,
    ' & > p': {
      margin: '0',
      paddingLeft: '28px',
      lineHeight: 'inherit',
    },
    '&:active, &:hover': {
      backgroundColor: `${t.palette.primary.main}12 !important`,
      color: `${t.palette.primary.main} !important`,
    },
  },
  selected: {
    backgroundColor: `${t.palette.primary.main}12 !important`,
    color: t.palette.primary.main,
  },
}))(ListItem);

const useStyles = makeStyles((t) => ({
  paper: {
    width: '264px',
  },
  head: {
    height: '200px',
    width: '224px',
    paddingTop: t.spacing(8),
    paddingLeft: t.spacing(4),
    paddingRight: t.spacing(6),
    paddingBottom: t.spacing(4),
    borderBottom: `1px solid ${t.palette.primary.main}12`,
  },
  menuList: {
    paddingLeft: t.spacing(2),
    paddingRight: t.spacing(2),
    paddingTop: t.spacing(6),
  },
}));

const MenuBurger = (props) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      <MenuBar {...props} />
      <SwipeableDrawer
        disableBackdropTransition
        classes={{ root: classes.root, paper: classes.paper }}
        open={props.openDrawer}
        onClick={props.toggleDrawer}
        onOpen={props.toggleDrawer}
        onClose={props.toggleDrawer}
      >
        <div className={classes.head}>
          <img src={logo} style={{ height: '100px' }} />
          <Typography variant={'h6'}>THS International Reception Spring 2023</Typography>
          <Typography variant={'subtitle2'} style={{ color: '#00000060' }}>
            Event Signup
          </Typography>
        </div>
        <List className={classes.menuList}>
          <MenuList {...props} projectId={props.projectId} handleClick={(v) => setCurrentPage(v)} />
        </List>
      </SwipeableDrawer>
    </>
  );
};

const MenuList = (props) => {
  return (
    <Fragment>
      {props.routes.map((r, i) => (
        <CustomListItem
          key={i}
          component={Link}
          button
          selected={window.location.pathname == `/${props.projectId}/${r.name}`.toLowerCase()}
          to={`/${props.projectId}/${r.name}`.toLowerCase()}
          onClick={() => props.handleClick(`/${props.projectId}/${r.name}`.toLowerCase())}
        >
          {r.icon}
          <Typography variant={'body1'}>{r.name}</Typography>
        </CustomListItem>
      ))}
    </Fragment>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(MenuBurger);
