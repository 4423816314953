import { makeStyles } from '@material-ui/core';

const useAttentancePriceStyles = makeStyles(() => ({
  inputContainer: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
  },
}));

export default useAttentancePriceStyles;
