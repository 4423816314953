import React from 'react';

import { TableRow } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import CustomTableCell from '/components/adminAccess/customTableCell/CustomTableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';

const EventGroupBodyDesktop = ({
  groupName = 'Group Name',
  appliedEvents = 0,
  ticketLimit = 0,
  displayed = true,
  eventGroupId = 0,
  onDeleteGroup,
  onShowGroup,
}) => {
  return (
    eventGroupId !== 0 && ( //Render once we have some data in the props.
      <TableRow key={eventGroupId}>
        <CustomTableCell>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Typography variant='body2' aria-aria-label='Event group name'>
              {groupName}
            </Typography>
          </div>
        </CustomTableCell>
        <CustomTableCell>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Typography variant='body2' aria-aria-label='number of events this group applies to'>
              {appliedEvents}
            </Typography>
          </div>
        </CustomTableCell>
        <CustomTableCell>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Typography variant='body2' aria-aria-label='number of tickets this event group is limited to'>
              {ticketLimit === 0 ? 'None' : ticketLimit}
            </Typography>
          </div>
        </CustomTableCell>
        <CustomTableCell>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Typography variant='body1' aria-label='is group displayed?'>
              {displayed ? 'Yes' : 'No'}
            </Typography>
          </div>
        </CustomTableCell>
        <CustomTableCell style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => onShowGroup(eventGroupId)}
            style={{ flexBasis: '30%' }}
            aria-label='edit event group'
            size='small'
          >
            <EditIcon color='secondary' fontSize='inherit' />
          </IconButton>
          <IconButton
            onClick={() => onDeleteGroup(eventGroupId)}
            style={{ flexBasis: '30%' }}
            aria-label='delete event group'
            size='small'
          >
            <DeleteIcon color='secondary' fontSize='inherit' />
          </IconButton>
        </CustomTableCell>
      </TableRow>
    )
  );
};

export default EventGroupBodyDesktop;
