export const PROJECT_UNSIGNED = 'PROJECT_UNSIGNED';
export const PROJECT_SIGNED = 'PROJECT_SIGNED';
export const PROJECT_FAIL = 'PROJECT_FAIL';
export const PROJECT_SWITCH = 'PROJECT_SWITCH';

const initialState = {
  signed: false,
  data: {},
  error: {},
  projectId: 0,
};

interface Action {
  [key: string]: any;
}

export const projectInfo = (state = initialState, action: Action) => {
  switch (action.type) {
    case PROJECT_SIGNED:
      return {
        ...state,
        signed: true,
        data: action.data,
      };
    case PROJECT_UNSIGNED:
      return {
        ...state,
        signed: false,
        data: action.data,
      };
    case PROJECT_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case PROJECT_SWITCH:
      return {
        ...state,
        projectId: action.projectId,
      };
    default:
      return state;
  }
};
