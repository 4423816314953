import { Dispatch } from 'redux';
import { ROLE_ADMIN, ROLE_STUDENT, ROLE_FAIL, ROLE_NO_PROJECT } from '../reducers/AccessReducers';

const roleAdmin = () => {
  const routes = ['Home', 'Events', 'Tickets', 'Profile', 'Logout', 'Admin'];
  return { type: ROLE_ADMIN, routes };
};

const roleStudent = () => {
  const routes = ['Home', 'Events', 'Tickets', 'Profile', 'Logout'];
  return { type: ROLE_STUDENT, routes };
};

export const roleEMPTY = (err: any) => {
  const routes = ['Login'];
  return { type: ROLE_FAIL, routes, error: err };
};

export const roleNoProject = () => {
  const routes = ['Home', 'Logout'];
  return { type: ROLE_NO_PROJECT, routes };
};

const resetRole = (reason: string) => (dispatch: Dispatch) => {
  dispatch(roleEMPTY(reason));
};

const setRole = (token: string) => (dispatch: Dispatch) => {
  const roleID = JSON.parse(atob(token.split('.')[1])).projectRole;
  // TODO: I belive that this action will never be called if token is empty. So this wrapping clause might be superfluous.
  if (token) {
    if (roleID < 6) {
      dispatch(roleAdmin());
    } else {
      dispatch(roleStudent());
    }
  } else {
    dispatch(roleEMPTY('token not ok'));
  }
};

export const accessActions = {
  setRole,
  resetRole,
};
