import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ChangeUserPassword } from 'data/api/ProfileRequest';

export interface ChangePasswordProps {
  oldPassword: string;
  password: string;
}

const useChangePassword = (options?: Omit<UseMutationOptions<any, any, ChangePasswordProps>, 'mutationFn'>) =>
  useMutation(({ oldPassword, password }) => ChangeUserPassword(oldPassword, password), options);

export default useChangePassword;
