import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/forms-v2/Text.component';

interface PasswordInputNoValidationProps {
  control: Control<any, any>;
  name?: string;
  label?: string;
  rules?: {
    [x: string]: any;
  };
}

const PasswordInputNoValidation: React.FC<PasswordInputNoValidationProps> = ({
  control,
  name = 'password',
  label = 'Password',
  rules,
}) => {
  return (
    <TextComponent
      name={name}
      control={control}
      label={label}
      rules={{
        required: { value: true, message: 'Enter password' },
        ...rules,
      }}
      type="password"
    />
  );
};

export default PasswordInputNoValidation;
