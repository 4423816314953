import { makeStyles } from '@material-ui/core';

const useNameEmailStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    lineHeight: '27px',
  },
  emailIconContainer: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  emailIcon: {
    width: '14px',
    height: '14px',
    cursor: 'pointer',
  },
}));

export default useNameEmailStyles;
