import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  general: null,
  study: null,
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    optionsSetGeneral: (state, action) => {
      state.general = action.payload;
    },
    optionsSetStudy: (state, action) => {
      state.study = action.payload;
    },
  },
});

export const { optionsSetGeneral, optionsSetStudy } = optionsSlice.actions;

export default optionsSlice.reducer;
