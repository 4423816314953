import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Backend from '/data/api/BackendRequest.ts';
import { connect } from 'react-redux';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import RadioComponent from '/components/forms-v2/Radio.component';
import CheckComponent from '/components/forms-v2/Check.component';
import ButtonComponent from '/components/forms-v2/Button.component';
import TextComponent from '/components/forms-v2/Text.component';
import Typography from '@material-ui/core/Typography';
import DateOfArrivalInput from '/containers/DateOfArrivalInput/DateOfArrivalInput';

const Preferences = (props) => {
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({ mode: 'all' });

  const [drinkData, setDrinkData] = useState([]);
  const [foodPrefs, setFoodPrefs] = useState([]);
  const [foodPrefsOther, setFoodPrefsOther] = useState([]);
  const otherInputWatch = watch('otherInput'); // watches over the input

  /**
   * Checks if user typed in otherInput
   */
  useEffect(() => {
    if (otherInputWatch) {
      if (otherInputWatch.length > 0) {
        setValue('other', true);
      }
    }
  }, [otherInputWatch]);

  /**
   * get data from database
   */
  useEffect(() => {
    let isMounted = true;
    const proms = [];
    const projectId = props.projectId;
    proms.push(
      Backend.request('get', `/user/${projectId}/project/info`, {
        projectId: projectId,
      })
    );
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/1`));
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/2`));
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/3`));
    proms.push(Backend.request('get', `/user/${projectId}/food/1`));
    proms.push(Backend.request('get', `/user/${projectId}/food/2`));
    proms.push(Backend.request('get', `/user/${projectId}/food/3`));

    Promise.all(proms)
      .then((res) => {
        if (isMounted) {
          // set arrival date
          setValue('arrivalDate', res[0].data.data.arrivalDate);

          // get drink, food, other data
          setDrinkData(res[1].data.data);
          // FIXME: remove TEMPORARY WORKAROUND FOR NON-PARTIAL ORDERING OF FOOD PREF.
          let option_two = res[2].data.data;
          option_two.unshift(option_two.pop());
          setFoodPrefs(option_two);
          setFoodPrefsOther(res[3].data.data);

          // set values from already existing user data
          setValue('drink', res[4].data.data[0]);
          res[5].data.data.map((food) => setValue(food, true));
          if (res[6].data.data[0] && res[6].data.data[0] !== '') {
            setValue('other', true);
            setValue('otherInput', res[6].data.data[0]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * when user presses submit
   */
  const onSubmit = (data) => {
    // arrival date
    let sendDataDate = {};
    sendDataDate = { ...sendDataDate, arrivalDate: data.arrivalDate };

    // drink Pref
    let drinkPref = [''];
    drinkPref[0] = data.drink;

    // food pref
    let foodPref = [];
    const foodPrefInput = getValues(foodPrefs);
    foodPrefInput.forEach((pref, index) => {
      if (pref === true) foodPref.push(foodPrefs[index]);
    });

    if (!foodPref.length || !foodPref.some((fpref) => foodPrefs.slice(0, 6).includes(fpref))) {
      props.openFailure('Please select a food preference');
      return;
    }

    // save to backend
    const proms = [];
    const projectId = props.projectId;
    proms.push(Backend.request('patch', `/user/${projectId}/project`, { projectId: projectId }, sendDataDate));
    proms.push(
      Backend.request('patch', `/user/${projectId}/food/1`, null, {
        foodPref: drinkPref,
      })
    );
    proms.push(
      Backend.request('patch', `/user/${projectId}/food/2`, null, {
        foodPref: foodPref,
      })
    );
    proms.push(
      Backend.request('patch', `/user/${projectId}/food/3`, null, {
        foodPref: data.otherInput === '' || !data.other ? [] : [data.otherInput],
      })
    );
    Promise.all(proms)
      .then(() => {
        props.openSuccess('Your changes have been saved!');
      })
      .catch((err) => {
        if (err.response.status < 500) props.openFailure('' + err.response.data.error.message);
        else props.openFailure('Server error');
      });
  };

  return (
    <>
      <DateOfArrivalInput control={control} />
      <div style={{ marginTop: '25px' }}>
        <Typography variant="body1">Drink Preferences</Typography>
        {drinkData.length > 0 ? (
          <RadioComponent
            name="drink"
            control={control}
            defaultValue={drinkData[0]}
            options={drinkData.map((drink) => ({ value: drink, label: drink }))}
          />
        ) : null}
      </div>

      <div style={{ marginTop: '25px' }}>
        <Typography variant="body1">Food Preferences</Typography>
        {foodPrefs.slice(0, 6).map((food, index) => (
          <CheckComponent
            key={index}
            name={food}
            control={control}
            label={<Typography variant="body2">{food}</Typography>}
            noMargin
          />
        ))}
      </div>

      <div style={{ marginTop: '25px', marginBottom: '38px' }}>
        <Typography variant="body1">Allergies/Intolerances (Optional)</Typography>
        {foodPrefs.slice(6, foodPrefs.length).map((food, index) => (
          <CheckComponent
            key={index}
            name={food}
            control={control}
            label={<Typography variant="body2">{food}</Typography>}
            noMargin
          />
        ))}
        <CheckComponent
          name="other"
          control={control}
          label={
            <TextComponent
              name="otherInput"
              control={control}
              label=""
              placeholder="Other"
              variant="standard"
              style={{}}
              small
            />
          }
          noMargin
        />
      </div>
      <ButtonComponent click={handleSubmit(onSubmit)} label="Save" fixedWidth />
    </>
  );
};

function mapStateToProps(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

const mapDispatchToProps = {
  openSuccess: snackActions.successFeedback,
  openFailure: snackActions.errorFeedback,
  closeSnackbar: snackActions.closeSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
