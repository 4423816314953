import React, { useState } from 'react';
import { Card, CardContent, Collapse, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventHeader from './EventHeader';

const useStyles = makeStyles((t: any) => ({
  cardBase: {
    padding: (props: any) => (props.isMobile ? '8px' : '8px 16px'),
    marginBottom: (props: any) => (props.isMobile ? '8px' : '16px'),
  },
  disabled: {
    color: `${t.palette.surface.text}${t.palette.other.opacityDisabled}`,
  },
  active: {
    backgroundColor: `${t.palette.secondary.main}${t.palette.overlay.opacity.selected}`,
    color: `${t.palette.secondary.main}${t.palette.other.opacityHigh}`,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

type EventCardProps = {
  title?: string;
  subtitle?: string;
  isMobile?: boolean;
  disabled?: boolean;
  active?: boolean;
  statistics?: React.ReactNode;
  children?: React.ReactNode;
  button?: React.ReactNode;
  elevation?: number;
  disableCollapse?: boolean;
  noPadding?: boolean;
};

/**
 * Displays an event card with customizable content
 */
const EventCard = ({
  title,
  subtitle,
  isMobile = false,
  disabled = false,
  active = false,
  statistics,
  children,
  button,
  elevation,
  disableCollapse,
  noPadding,
}: EventCardProps) => {
  const classes = useStyles({ isMobile });
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => setExpanded(!expanded);

  const baseStyling = () => {
    if (disabled) return `${classes.cardBase} ${classes.disabled}`;
    if (active) return `${classes.cardBase} ${classes.active}`;
    return classes.cardBase;
  };

  return (
    <Card elevation={elevation === undefined ? 1 : elevation} className={!noPadding ? baseStyling() : ''}>
      <CardContent className={classes.cardContent}>
        <EventHeader
          title={title}
          subtitle={subtitle}
          expanded={expanded}
          onClick={handleExpanded}
          hidden={disableCollapse}
        />
        {statistics}
        {!disableCollapse ? (
          <>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Divider style={{ marginTop: isMobile ? '8px' : '16px' }} />
              {children}
            </Collapse>
            {button}
          </>
        ) : (
          <>{children}</>
        )}
      </CardContent>
    </Card>
  );
};

export default EventCard;
