import { connect } from 'react-redux';
import React from 'react';
import { Typography, IconButton, Grid } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo from '../../static/logo_100.png';

const CustomIconButton = withStyles(() => ({
  root: {
    color: 'white',
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(IconButton);

const useStyles = makeStyles((t) => ({
  root: {
    display: 'block',
    maxWidth: '1280px',
    height: '100%',
    '& > * a': {
      color: t.palette.primary.contrastText,
      textDecoration: 'none',
    },
    '& > *': {
      color: t.palette.primary.contrastText,
      minHeight: t.spacing(2) * 4,
      alignItems: 'center',
      [t.breakpoints.up('xs')]: {
        padding: `0 ${t.breakpoints.margin.xs}px`,
      },
      [t.breakpoints.up('md')]: {
        padding: `0 ${t.breakpoints.margin.md}px`,
      },
    },
  },
  top: {
    backgroundColor: t.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid white',
    '& > *': {
      display: 'flex',
      justifyContent: 'space-around',
      height: '100%',
      '& > *': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    [t.breakpoints.up('xs')]: {
      height: '56px',
    },
    [t.breakpoints.up('md')]: {
      height: '45px',
    },
  },
  mid: {
    height: '64px',
    backgroundColor: t.palette.neutral.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      display: 'flex',
      [t.breakpoints.up('xs')]: {
        flexDirection: 'column',
      },
      [t.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    [t.breakpoints.up('xs')]: {
      height: '112px',
      '& > * img': {
        width: '64px',
      },
    },
    [t.breakpoints.up('md')]: {
      height: '32px',
      '& > * img': {
        width: '28px',
      },
    },
  },
  bot: {
    height: '28px',
    width: '100%',
    backgroundColor: t.palette.neutral.dark,
    display: 'flex',
    float: 'left',
    padding: 0,
    '& > *': {
      margin: 0,
      [t.breakpoints.up('xs')]: {
        padding: `0 ${t.breakpoints.margin.xs}px`,
      },
      [t.breakpoints.up('md')]: {
        padding: `0 ${t.breakpoints.margin.md}px`,
      },
    },
  },
  show: {
    [t.breakpoints.up('xs')]: {
      display: 'none',
    },
    [t.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  border: {
    [t.breakpoints.up('md')]: {
      borderLeft: '1px solid',
      borderColor: t.palette.neutral.contrastText,
      paddingLeft: '8px',
      marginLeft: '8px',
    },
  },
  icon: {
    [t.breakpoints.up('xs')]: {
      fontSize: '32px',
    },
    [t.breakpoints.up('sm')]: {
      fontSize: '36px',
    },
    [t.breakpoints.up('md')]: {
      fontSize: '32px',
    },
  },
  linkContainer: {
    justifyContent: 'flex-start',
    [t.breakpoints.down('sm')]: {
      height: '100%',
      justifyContent: 'space-evenly',
    },
  },
}));

const Footer = ({ projectId }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid className={classes.top} container>
        <Grid item className={classes.show} md={6} xs={1} style={{ justifyContent: 'flex-start' }}>
          <Typography variant="body1">THS&nbsp;International Reception Spring 2023</Typography>
        </Grid>
        <Grid item md={1} xs={3}>
          <CustomIconButton onClick={() => window.open('https://www.thsint.se', '_blank')}>
            <LanguageIcon className={classes.icon} />
          </CustomIconButton>
        </Grid>
        <Grid item md={1} xs={3}>
          <CustomIconButton onClick={() => window.open('mailto:info@thsint.se', '_blank')}>
            <MailIcon className={classes.icon} />
          </CustomIconButton>
        </Grid>
        <Grid item md={1} xs={3}>
          <CustomIconButton onClick={() => window.open('https://www.facebook.com/thsint', '_blank')}>
            <FacebookIcon className={classes.icon} />
          </CustomIconButton>
        </Grid>
        <Grid item md={1} xs={3}>
          <CustomIconButton
            onClick={() => window.open('https://www.instagram.com/ths_international_reception', '_blank')}
          >
            <InstagramIcon className={classes.icon} />
          </CustomIconButton>
        </Grid>
      </Grid>
      <div className={classes.mid}>
        <Grid item md={6} xs={4} className={classes.linkContainer}>
          <Link to={{ pathname: `/${projectId}/about/aboutus` }} target="_blank">
            <Typography variant={useMediaQuery(Theme.breakpoints.down('sm')) ? 'body2' : 'body1'}>
              Learn&nbsp;More
            </Typography>
          </Link>
          <Link className={classes.border} to={{ pathname: `/${projectId}/about/terms` }} target="_blank">
            <Typography variant={useMediaQuery(Theme.breakpoints.down('sm')) ? 'body2' : 'body1'}>
              Terms&nbsp;of&nbsp;Use
            </Typography>
          </Link>
          <Link className={classes.border} to={{ pathname: `/${projectId}/about/privacy` }} target="_blank">
            <Typography variant={useMediaQuery(Theme.breakpoints.down('sm')) ? 'body2' : 'body1'}>
              Privacy&nbsp;Policy
            </Typography>
          </Link>
          <Link className={classes.border} to={{ pathname: `/${projectId}/about/additional` }} target="_blank">
            <Typography variant={useMediaQuery(Theme.breakpoints.down('sm')) ? 'body2' : 'body1'}>
              Additional&nbsp;Communication
            </Typography>
          </Link>
        </Grid>
        <Grid item md={1} xs={3} style={{ alignItems: 'center', justifyContent: 'space-around' }}>
          <img src={logo} alt="ths-logo" />
        </Grid>
      </div>
      <div className={classes.bot}>
        <Typography variant={useMediaQuery(Theme.breakpoints.down('sm')) ? 'body2' : 'body1'}>
          ©&nbsp;2023 THS&nbsp;International - All Rights Reserved
        </Typography>
      </div>
    </Grid>
  );
};

const mapState = (state) => ({
  projectId: state.projectInfo.projectId,
});

export default connect(mapState)(Footer);
