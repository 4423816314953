import React from 'react';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useSubMenuStyles from './SubMenu.styles';

interface BackArrowTextProps {
  label: string;
  backButton: () => void;
}

const BackArrowText: React.FC<BackArrowTextProps> = ({ label, backButton }) => {
  const classes = useSubMenuStyles();

  return (
    <button className={classes.container} onClick={backButton} type="button">
      <ArrowBackIcon className={classes.arrowBackIcon} />
      <Typography variant="button">{label}</Typography>
    </button>
  );
};

export default BackArrowText;
