import React, { useState, useEffect } from 'react';

import FormLogo from '/components/formLogo/FormLogo.component.tsx';
import FormContainer from '/components/formContainer/FormContainer';
import DateComponent from '/components/forms-v2/Date.component';
import RadioComponent from '/components/forms-v2/Radio.component';
import CheckComponent from '/components/forms-v2/Check.component';
import ButtonComponent from '/components/forms-v2/Button.component';
import TextComponent from '/components/forms-v2/Text.component';
import Typography from '@material-ui/core/Typography';

const PageTwo = ({ control, watch, onSubmit, setValue, foodPrefs, drinkData, foodPrefsOther }) => {
  const otherInputWatch = watch('otherInput'); // watches over the input

  /**
   * Checks if user typed in otherInput
   */
  useEffect(() => {
    if (otherInputWatch) {
      if (otherInputWatch.length > 0) {
        setValue('other', true);
      }
    }
  }, [otherInputWatch]);

  return (
    <FormContainer>
      <FormLogo />
      <DateComponent name='arrivalDate' control={control} label='Arrival in Stockholm' />

      <div style={{ marginTop: '25px' }}>
        <Typography variant='body1'>Drink Preferences</Typography>
        {drinkData.length > 0 ? (
          <RadioComponent
            name='drink'
            control={control}
            defaultValue={''}
            options={drinkData.map((drink) => ({ value: drink, label: drink }))}
          />
        ) : null}
      </div>

      <div style={{ marginTop: '25px' }}>
        <Typography variant='body1'>Food Preferences</Typography>
        {foodPrefs.slice(0, 6).map((food, index) => (
          <CheckComponent
            key={index}
            name={food}
            control={control}
            label={<Typography variant='body2'>{food}</Typography>}
            noMargin
          />
        ))}
      </div>

      <div style={{ marginTop: '25px', marginBottom: '90px' }}>
        <Typography variant='body1'>Allergies/Intolerances (Optional)</Typography>
        {foodPrefs.slice(6, foodPrefs.length).map((food, index) => (
          <CheckComponent
            key={index}
            name={food}
            control={control}
            label={<Typography variant='body2'>{food}</Typography>}
            noMargin
          />
        ))}
        <CheckComponent
          name='other'
          control={control}
          label={
            <TextComponent
              name='otherInput'
              control={control}
              label=''
              placeholder='Other'
              variant='standard'
              style={{}}
              small
            />
          }
          noMargin
        />
        <div style={{ float: 'right', marginTop: '16px' }}>
          <ButtonComponent click={onSubmit} label='Save' fixedWidth />
        </div>
      </div>
    </FormContainer>
  );
};

export default PageTwo;
