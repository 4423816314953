import React from 'react';
import { Typography } from '@material-ui/core';
import { Control, UseFormWatch } from 'react-hook-form';

import SwitchComponent from 'components/forms-v2/Switch.component';
import TextComponent from 'components/forms-v2/Text.component';
import useAttentancePriceStyles from './EventAttendancePrice.styles';
import useInputStyles from './EventInput.styles';
import { editEventFormKeys } from '../types/FormTypes';

interface EventAttendancePriceProps {
  control: Control<any, any>;
  watch: UseFormWatch<any>;
}

const EventAttendancePrice: React.FC<EventAttendancePriceProps> = ({ control, watch }) => {
  const baseClasses = useInputStyles();
  const classes = useAttentancePriceStyles();

  const buildRules = (pleaseEnterText: string, watchField: string): { [x: string]: any } => ({
    required: { value: !watch(watchField), message: pleaseEnterText },
    minValue: { value: 0, message: 'Minimum 0' },
  });

  return (
    <>
      <div className={baseClasses.root}>
        <Typography variant="body1">Attendance</Typography>
        <div className={classes.inputContainer}>
          <TextComponent
            name={editEventFormKeys.attendanceLimit}
            control={control}
            rules={buildRules('Enter attendance limit', editEventFormKeys.isNoAttendanceLimit)}
            type="number"
            label="Attendance limit"
            disabled={watch(editEventFormKeys.isNoAttendanceLimit)}
            style={{ margin: '0' }}
            helperText="Enter the max. amount of participants"
          />
          <SwitchComponent
            name={editEventFormKeys.isNoAttendanceLimit}
            control={control}
            labelOn="No limit"
            labelOff=""
          />
        </div>
      </div>
      <div className={baseClasses.root}>
        <Typography variant="body1">Price</Typography>
        <div className={classes.inputContainer}>
          <TextComponent
            name={editEventFormKeys.price}
            control={control}
            rules={buildRules('Enter price', editEventFormKeys.isFree)}
            type="number"
            label="Price"
            disabled={watch(editEventFormKeys.isFree)}
            style={{ margin: '0' }}
            helperText="Enter the price in SEK"
          />
          <SwitchComponent name={editEventFormKeys.isFree} control={control} labelOn="Free" labelOff="" />
        </div>
      </div>
    </>
  );
};

export default EventAttendancePrice;
