import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAdminEventStatsQuery } from 'queries/AdminEventQueries';
import { BookingStats } from 'queries/AdminEventQueries.types';
import { useAppSelector } from 'redux/storeConfig';
import { getEventStatsId } from '../utils/EventCardMapper';

const useGetEventStats = (eventId: string) => {
  const [stats, setStats] = useState<BookingStats>({
    countBooked: 0,
    countAttending: 0,
    countPaid: 0,
  });
  const projectId = useAppSelector((state) => state.projectInfo.projectId);
  const statsData = useQuery(getAdminEventStatsQuery({ projectId }));

  useEffect(() => {
    if (!statsData.data) return;
    const newStats = getEventStatsId(parseInt(eventId, 10), statsData.data);
    setStats(newStats);
  }, [statsData.data]);

  return {
    data: stats,
    isLoading: statsData.isLoading,
    isError: statsData.isError,
  };
};

export default useGetEventStats;
