import axios from 'axios';
import { snackActions } from '/redux/actions/FeedbackActions';
// eslint-disable-next-line import/no-cycle
import { store } from '/redux/storeConfig';

type BackendResponse<T> = {
  data: {
    data: T;
  };
};

type ObjectType = {
  [key: string]: any;
};

const sendRequest = async <T,>(config: ObjectType = {}): Promise<BackendResponse<T>> =>
  axios<BackendResponse<T>>(config)
    .then((req: any) => req)
    .catch((err: any) => {
      const statusMessage: string | undefined = err?.response?.data?.error?.message;
      if (err?.response?.status < 500 && statusMessage) store.dispatch(snackActions.errorFeedback(statusMessage));
      else store.dispatch(snackActions.errorFeedback('Server error'));
      return Promise.reject(err);
    });

const rawRequest = async <T,>(
  method: string,
  endpoint: string,
  params?: ObjectType,
  data: ObjectType = {},
  conf: ObjectType = {}
): Promise<BackendResponse<T>> => {
  const BASEURL = `${process.env.API_ADDRESS}:${process.env.API_PORT}`;
  const config = { ...conf };
  config.headers = { 'Content-Type': 'application/json' };
  if (sessionStorage.token != null) {
    config.headers.Authorization = `Bearer ${sessionStorage.token}`;
  }
  config.baseURL = BASEURL;
  config.method = method;
  config.url = endpoint;
  config.params = params;
  config.data = data;
  return sendRequest<T>(config);
};

export const storeResponse = (response: any, responseDataKey: string, sessionStorageKey: string) => {
  if (response.status === 200) {
    switch (responseDataKey) {
      case '':
        sessionStorage.setItem(sessionStorageKey, response.data.data); // FIXME: This should maybe be somewhere else?
        break;
      default:
        sessionStorage.setItem(sessionStorageKey, response.data.data[responseDataKey]); // FIXME: This should maybe be somewhere else?
    }
    return response.data.data;
  }
  return null;
};

export const request = async <T = any,>(
  method: string,
  endpoint: string,
  params?: ObjectType,
  data?: ObjectType
): Promise<BackendResponse<T>> => rawRequest<T>(method, endpoint, params, data, {});

export const requestBlob = (method: string, endpoint: string, params?: ObjectType, data?: ObjectType) => {
  const config: ObjectType = {};
  config.responseType = 'blob';
  return rawRequest(method, endpoint, params, data, config);
};
