import React, { useState } from 'react';

import EventsTitle from '/components/adminUsers/eventsOverview/EventsTitle.component';
import EventsContent from '/components/adminUsers/eventsOverview/EventsContent.component';
import CategoryChips from '/components/adminUsers/eventsOverview/CategoryChips.component';

import { Divider } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Collapse from '@material-ui/core/Collapse';
import Theme from '/MuiTheme';

import { useSelector } from 'react-redux';

// The events overview container with it's logic and render
const EventsOverview = () => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('xs'));
  const [collapsed, setCollapsed] = useState(true);
  const events = useSelector((state) => state.adminUsers.events);
  const eventGroups = useSelector((state) => state.eventRetrival.eventGroups);
  const preferences = useSelector((state) => state.adminUsers.preferences);

  const formData = () => {
    const totalBooked = events.length;
    const totalAttended = events.filter((event) => event.isAttending).length;
    const totalUnpaid = events
      .filter((event) => !event.isPaid)
      .map((event) => event.basePriceSek)
      .reduce((a, b) => a + b, 0);

    return [
      {
        label: totalBooked,
        state: 'default',
      },
      {
        label: `${totalAttended}/${totalBooked}`,
        state: totalAttended === totalBooked ? 'success' : 'warning',
      },
      {
        label: totalUnpaid,
        state: totalUnpaid > 0 ? 'error' : 'success',
      },
    ];
  };

  // Format the events and event groups
  // into a list of strings for the chip to render
  const formatEventGroups = () => {
    if (!eventGroups || !events) return;

    let categories = [];

    eventGroups.forEach((value) => {
      if (events.filter((event) => event.eventGroups.includes(value.id)).length > 0) {
        categories.push({
          value: value.id.toString(),
          current: events.filter((ticket) => ticket.eventGroups.includes(value.id)).length,
          ticketLimit: value.ticketLimit,
          label: value.name,
        });
      }
    });

    let stringed = [];

    categories.forEach((category) => {
      stringed.push(`${category.label}: ${category.current}${category.ticketLimit ? '/' + category.ticketLimit : ''}`);
    });

    return stringed;
  };

  if (!events || !eventGroups || !preferences) return <></>;

  return (
    <div style={{ paddingTop: '8px' }}>
      <EventsTitle
        isCollapsed={collapsed}
        isAlcohol={preferences.drinking}
        isMobile={isMobile}
        onClick={() => setCollapsed(!collapsed)}
      />
      <EventsContent data={formData()} isMobile={isMobile} />

      {!isMobile ? (
        <div style={{ marginTop: '16px' }}>
          <CategoryChips data={formatEventGroups()} />
        </div>
      ) : (
        <Collapse in={!collapsed} timeout='auto' unmountOnExit>
          <Divider style={{ marginTop: '10px', marginBottom: '8px' }} />
          <CategoryChips data={formatEventGroups()} />
        </Collapse>
      )}
    </div>
  );
};

export default EventsOverview;
