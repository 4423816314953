import { RequestProject } from '../../data/api/AuthRequest';
import { roleNoProject } from './AccessActions';
import { store } from '../storeConfig';

import { PROJECT_UNSIGNED, PROJECT_SIGNED, PROJECT_FAIL, PROJECT_SWITCH } from '../reducers/ProjectReducers';

export const projectActions = {
  projects,
  projectSET,
};

function projectUNSIGNED(data) {
  return { type: PROJECT_UNSIGNED, data, signed: false };
}

function projectSIGNED(data) {
  return { type: PROJECT_SIGNED, data, signed: true };
}

function projectFAIL(err) {
  return { type: PROJECT_FAIL, error: err };
}

function projectSET(projectId) {
  return { type: PROJECT_SWITCH, projectId };
}

function inProject(response, projectId) {
  let found = false;
  response.forEach((el) => {
    if (el.projectId == projectId) {
      found = true;
    }
  });

  return found;
}

function projects(token) {
  const appState = store.getState();

  return (dispatch) => {
    RequestProject(token)
      .then((resp) => {
        if (inProject(resp.data.data, appState.projectInfo.projectId)) {
          dispatch(projectSIGNED());
        } else {
          dispatch(projectUNSIGNED());
          dispatch(roleNoProject());
        }
      })
      .catch((error) => {
        dispatch(projectFAIL(error));
        dispatch(roleNoProject()); // this means the user is not assigned to any role
      });
  };
}
