import React from 'react';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

const ClearableTextField = ({ label, placeholder = '', value, setValue, style, disabled }) => (
  <TextField
    fullWidth
    color='secondary'
    disabled={disabled}
    variant='outlined'
    label={label || ''}
    placeholder={placeholder}
    value={value}
    onChange={(e) => setValue(e.target.value)}
    style={style}
    InputProps={{
      endAdornment: value ? (
        <InputAdornment position='end'>
          <IconButton onClick={() => setValue('')} edge='end'>
            <CancelIcon />
          </IconButton>
        </InputAdornment>
      ) : null,
    }}
  />
);

export default ClearableTextField;
