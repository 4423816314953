import React from 'react';
import { EditEventFormCategory } from '../types/FormTypes';

interface CategoryRenderProps {
  categories: EditEventFormCategory[];
  activeTab: string;
}

const CategoryRender: React.FC<CategoryRenderProps> = ({ categories, activeTab }) => (
  <>{categories.find((chip) => chip.path === activeTab)?.element}</>
);

export default CategoryRender;
