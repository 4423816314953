import React from 'react';

import { AdminHeader } from 'components/adminHeader';
import { useMediaQuery } from '@material-ui/core';
import theme from 'MuiTheme';

const EditEventHeader = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AdminHeader
      isMobile={isMobile}
      title="Manage Event"
      subtitle="See the status of the event and manage all bookings."
      disableDivider
    />
  );
};

export default EditEventHeader;
