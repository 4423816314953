import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  loading: {
    marginRight: '8px',
  },
  iconMobile: {
    marginRight: '8px',
    height: '38px',
    width: '44px',
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  iconExpandButton: {
    position: 'relative',
    top: '0px',
    padding: '0px',
    height: '27px',
    marginLeft: 'auto',
  },
}));

const CheckOverview = ({ isLoading, success, setExpanded, isExpanded, description, isMobile }) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', marginBottom: !isMobile && '16px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isLoading ? (
          <CircularProgress color='secondary' className={classes.loading} />
        ) : success ? (
          <CheckIcon className={`${classes.iconMobile} ${classes.successIcon}`} />
        ) : (
          <WarningIcon className={`${classes.iconMobile} ${classes.warningIcon}`} />
        )}
        <div>
          <Typography variant='h6'>{success ? 'Checks successful' : 'Checks failed'}</Typography>
          <Typography variant='body2' color='textSecondary'>
            {description}
          </Typography>
        </div>
      </div>
      {!isLoading && isMobile && (
        <IconButton className={classes.iconExpandButton} color='secondary' onClick={setExpanded}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      )}
    </div>
  );
};

export default CheckOverview;
