
import { BackendEvent } from "queries/AdminEventQueries.types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { EditEventFormValues } from "../types/FormTypes";

const defaultValues: EditEventFormValues = {
  title: '',
  description: '',
  startDatetime: new Date().toISOString(),
  endDatetime: new Date().toISOString(),
  attendanceLimit: 0,
  isNoAttendanceLimit: false,
  price: 0,
  isFree: false,
  location: '',
  bookingStartDatetime: new Date().toISOString(),
  bookingEndDatetime: new Date().toISOString(),
  unbookingEndDatetime: new Date().toISOString(),
  swapEndDatetime: new Date().toISOString(),
};

const useEditEventForm = (event?: BackendEvent) => {
  const { control, watch, setValue, handleSubmit } = useForm<EditEventFormValues>({
    mode: 'onSubmit',
    defaultValues,
  });

  useEffect(() => {
    if (event) {
      setValue('title', event.displayTitle);
      setValue('description', event.description);
      setValue('startDatetime', event.startDatetime);
      setValue('endDatetime', event.endDatetime);
      setValue('attendanceLimit', event.attendanceLimit);
      setValue('price', event.basePriceSek);
      setValue('location', event.location);
      setValue('bookingStartDatetime', event.bookingStartDatetime);
      setValue('bookingEndDatetime', event.bookingEndDatetime);
      setValue('unbookingEndDatetime', event.unbookingEndDatetime);
      setValue('swapEndDatetime', event.swapEndDatetime);
      setValue('isNoAttendanceLimit', event.attendanceLimit === 0);
      setValue('isFree', event.basePriceSek === 0);
    }
  }, [event]);

  return {
    control,
    watch,
    handleSubmit,
  }
}

export default useEditEventForm;
