import React from 'react';

interface ChipContainerProps {
  children: React.ReactNode;
}

const ChipContainer: React.FC<ChipContainerProps> = ({ children }) => (
  <div style={{ display: 'flex', gap: '0.5rem' }}>{children}</div>
);

export default ChipContainer;
