import { createSlice } from '@reduxjs/toolkit';

export const ADMIN_ACCESS_USERS_SET = 'ADMIN_ACCESS_USERS_SET';
export const ADMIN_ACCESS_SEARCH_VALUE_SET = 'ADMIN_ACCESS_SEARCH_VALUE_SET';
export const ADMIN_ACCESS_ROLE_SORT_UP_SET = 'ADMIN_ACCESS_ROLE_SORT_UP_SET';
export const ADMIN_ACCESS_ID_TO_DELETE_SET = 'ADMIN_ACCESS_ID_TO_DELETE_SET';
export const ADMIN_ACCESS_DELETE_DIALOG_OPEN_SET = 'ADMIN_ACCESS_DELETE_DIALOG_OPEN_SET';
export const ADMIN_ACCESS_ROLE_FILTER_SET = 'ADMIN_ACCESS_ROLE_FILTER_SET';
export const ADMIN_ACCESS_ROLES_SET = 'ADMIN_ACCESS_ROLES_SET';
export const ADMIN_ACCESS_CURRENT_PAGE_SET = 'ADMIN_ACCESS_CURRENT_PAGE_SET';

const adminAccessInitial = {
  users: [],
  currentPage: 1,
  searchValue: '',
  roleSortUp: false,
  idToDelete: 0,
  deleteDialogOpen: false,
  roleFilter: [false, false, false],
  roles: [
    { roleId: 6, title: 'Student' },
    { roleId: 4, title: 'Buddy' },
    { roleId: 2, title: 'PT' },
  ],
};

const adminUsersSlice = createSlice({
  name: 'options',
  initialState: {
    searchValue: '',
    general: null,
    study: null,
    preferences: null,
    events: null,
    checkedIn: false,
    selectedEvents: [],
    eventFilterSearch: '',
    eventFilter: {
      startDate: null,
      endDate: null,
      startPrice: null,
      endPrice: null,
      attended: [],
    },
    addBooking: {
      eventList: [],
      selectedEvent: null,
      isOpen: false,
      isExpanded: false,
      responseData: null,
    },
    transferTicket: {
      isOpen: false,
      searchValue: '',
      general: null,
      study: null,
      preferences: null,
      checks: null,
    },
  },
  reducers: {
    adminUsersTransferChecks: (state, action) => {
      state.transferTicket.checks = action.payload;
    },
    adminUsersTransferOpen: (state, action) => {
      state.transferTicket.isOpen = action.payload;
    },
    adminUsersTransferSearchValue: (state, action) => {
      state.transferTicket.searchValue = action.payload;
    },
    adminUsersTransferGeneral: (state, action) => {
      state.transferTicket.general = action.payload;
    },
    adminUsersTransferStudy: (state, action) => {
      state.transferTicket.study = action.payload;
    },
    adminUsersTransferPreferences: (state, action) => {
      state.transferTicket.preferences = action.payload;
    },
    adminUsersEventListSet: (state, action) => {
      state.addBooking.eventList = action.payload;
    },
    adminUsersSelectEvent: (state, action) => {
      state.addBooking.selectedEvent = action.payload;
    },
    adminUsersAddBookingDialogOpenSet: (state, action) => {
      state.addBooking.isOpen = action.payload;
    },
    adminUsersAddBookingDialogExpandedSet: (state, action) => {
      state.addBooking.isExpanded = action.payload;
    },
    adminUsersAddBookingResponseDataSet: (state, action) => {
      state.addBooking.responseData = action.payload;
    },
    adminUsersEventFilterSearch: (state, action) => {
      state.eventFilterSearch = action.payload;
    },
    adminUsersSetEventFilters: (state, action) => {
      state.eventFilter = action.payload;
    },
    adminUsersSetSelectedEvents: (state, action) => {
      state.selectedEvents = action.payload;
    },
    adminUsersSetSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    adminUsersSetGeneral: (state, action) => {
      state.general = action.payload;
    },
    adminUsersSetStudy: (state, action) => {
      state.study = action.payload;
    },
    adminUsersSetPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    adminUsersSetEvents: (state, action) => {
      state.events = action.payload;
    },
    adminUsersSetCheckedIn: (state, action) => {
      state.checkedIn = action.payload;
    },
  },
});

export const {
  adminUsersTransferChecks,
  adminUsersTransferOpen,
  adminUsersTransferSearchValue,
  adminUsersTransferGeneral,
  adminUsersTransferStudy,
  adminUsersTransferPreferences,
  adminUsersEventListSet,
  adminUsersSelectEvent,
  adminUsersAddBookingDialogOpenSet,
  adminUsersAddBookingDialogExpandedSet,
  adminUsersAddBookingResponseDataSet,
  adminUsersEventFilterSearch,
  adminUsersSetEventFilters,
  adminUsersSetSearchValue,
  adminUsersSetGeneral,
  adminUsersSetStudy,
  adminUsersSetPreferences,
  adminUsersSetEvents,
  adminUsersSetCheckedIn,
  adminUsersSetSelectedEvents,
} = adminUsersSlice.actions;
export const adminUsers = adminUsersSlice.reducer;

interface Action {
  [key: string]: any;
}

export const adminAccess = (state = adminAccessInitial, action: Action) => {
  switch (action.type) {
    case ADMIN_ACCESS_USERS_SET:
      return {
        ...state,
        users: action.payload,
      };
    case ADMIN_ACCESS_SEARCH_VALUE_SET:
      return {
        ...state,
        searchValue: action.payload,
      };
    case ADMIN_ACCESS_ROLE_SORT_UP_SET:
      return {
        ...state,
        roleSortUp: action.payload,
      };
    case ADMIN_ACCESS_ID_TO_DELETE_SET:
      return {
        ...state,
        idToDelete: action.payload,
      };
    case ADMIN_ACCESS_DELETE_DIALOG_OPEN_SET:
      return {
        ...state,
        deleteDialogOpen: action.payload,
      };
    case ADMIN_ACCESS_ROLE_FILTER_SET:
      return {
        ...state,
        roleFilter: action.payload,
      };
    case ADMIN_ACCESS_ROLES_SET: {
      return {
        ...state,
        roles: action.payload,
      };
    }
    case ADMIN_ACCESS_CURRENT_PAGE_SET: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
};
