import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link, NavLink } from 'react-router-dom';
import logo from '../../static/logo_100.png';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuLocation from './MenuLocation';

import { withStyles, makeStyles } from '@material-ui/core/styles';

const CustomButton = withStyles((t) => ({
  root: {
    borderRadius: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    '& > img': {
      padding: '8px',
      height: '32px',
      width: '32px',
    },
    '&:hover': {
      backgroundColor: `${t.palette.background.main}${t.palette.overlay.opacity.hover}`,
    },
  },
}))(Button);

const useStyles = makeStyles((t) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      height: '48px',
      width: '48px',
    },
  },
  partition: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  link: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    height: '100%',
    '& > img': {
      padding: '8px',
      height: '32px',
      width: '32px',
    },
  },
  active: {
    backgroundColor: `${t.palette.background.main}${t.palette.overlay.opacity.activated}`,
  },
  burgerButton: {
    height: '56px',
  },
}));

/**
 * MenuBar renders a menu bar for the application. It is really customly made to style and distribute buttons and images as needed.
 * It keeps track of the current page that the user is on, to update the "activeness" of each button.
 */
const MenuBar = (props) => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(window.location.pathname.substring(2).slice(1));
  const classes = useStyles();

  useEffect(() => {
    setCurrentPage(window.location.pathname.substring(2).slice(1));
  }, [window.location.pathname]);

  return (
    <>
      <div className={classes.partition} style={{ flex: '0' }}>
        {!props.isPhone ? (
          <CustomButton
            className={currentPage === '' ? classes.active : ''}
            onClick={() => setCurrentPage('')}
            underline={'none'}
            disableRipple
            disableElevation
          >
            <NavLink className={classes.root} to={`/${props.projectId}/`} end>
              <img src={logo} alt='Home' />
            </NavLink>
          </CustomButton>
        ) : (
          <>
            {location.pathname === `/${props.projectId}/forgot` ? (
              <Link to={`/${props.projectId}/login`} style={{ textDecoration: 'none' }}>
                <IconButton>
                  <ArrowBackIcon style={{ color: '#fff', fontSize: '24' }} />
                </IconButton>
              </Link>
            ) : (
              <IconButton onClick={props.toggleDrawer} className={classes.burgerButton}>
                <MenuIcon style={{ color: '#fff', fontSize: '24' }} />
              </IconButton>
            )}
          </>
        )}
      </div>
      <div className={classes.partition} style={{ flex: '1000 1 0' }}>
        <MenuButtons
          currentPage={currentPage}
          {...props}
          loc='middle'
          projectId={props.projectId}
          onClick={(x) => setCurrentPage(x)}
        ></MenuButtons>
        {props.isPhone && <MenuLocation routes={props.routes} />}
      </div>
      <div className={classes.partition} style={{ flex: '1 1 0' }}>
        <MenuButtons
          currentPage={currentPage}
          {...props}
          loc='right'
          projectId={props.projectId}
          onClick={(x) => setCurrentPage(x)}
        ></MenuButtons>
        {props.isPhone ? (
          <CustomButton
            className={currentPage === '' ? classes.active : ''}
            onClick={() => setCurrentPage('')}
            underline={'none'}
            disableRipple
            disableElevation
          >
            <NavLink className={classes.root} end to={`/${props.projectId}/`}>
              <img src={logo} alt='Home' />
            </NavLink>
          </CustomButton>
        ) : null}
      </div>
    </>
  );
};

/**
 * MenuButtons renders the "menu-bar buttons/routes to different pages". It takes props that indicate which is the current visited page as well as handlers to update the location (indirectly).
 */
const MenuButtons = (props) => {
  const classes = useStyles();
  const routes = props.routes.filter((x) => x.name != 'Home' && x.location === props.loc);

  return (
    <Hidden smDown>
      {routes.map((r, i) => (
        <CustomButton
          className={props.currentPage.toLowerCase() === r.name.toLowerCase() ? classes.active : ''}
          onClick={() => props.onClick(`/${props.projectId}/${r.name.toLowerCase()}`)}
          key={i}
          underline={'none'}
          disableRipple
          disableElevation
        >
          <NavLink key={i} className={classes.link} end to={`/${props.projectId}/${r.name.toLowerCase()}`}>
            <h6>{r.name}</h6>
          </NavLink>
        </CustomButton>
      ))}
    </Hidden>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(MenuBar);
