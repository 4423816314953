import { Divider } from '@material-ui/core';
import EventsContent from 'components/adminUsers/eventsOverview/EventsContent.component';
import React from 'react';
import AdminEventCheck, { AdminEventCheckItemProps } from './AdminEventCheck';
import EventCard from './EventCardBase';

export interface AdminEventCardTrimmedProps {
  title: string;
  subtitle: string;
  currentEventBooked: number;
  currentEventAttended: number;
  maxEventAttended: number;
  paidPeople: number;
  isMobile: boolean;
  checks: AdminEventCheckItemProps[];
}

const AdminEventCardTrimmed: React.FC<AdminEventCardTrimmedProps> = ({
  title,
  subtitle,
  currentEventBooked,
  currentEventAttended,
  maxEventAttended,
  paidPeople,
  isMobile,
  checks,
}) => {
  return (
    <EventCard
      title={title}
      subtitle={subtitle}
      isMobile={isMobile}
      elevation={0}
      disableCollapse
      noPadding
      statistics={
        <>
          <Divider style={{ marginBottom: '16px', marginTop: '8px' }} />
          <EventsContent
            data={[
              {
                label: `${currentEventBooked}/${maxEventAttended > 0 ? maxEventAttended : '-'}`,
                state: 'default',
              },
              {
                label: `${currentEventAttended}/${currentEventBooked}`,
                state: currentEventAttended === currentEventBooked ? 'success' : 'warning',
              },
              {
                label: `${paidPeople}/${currentEventBooked}`,
                state: paidPeople === currentEventBooked ? 'success' : 'error',
              },
            ]}
            isMobile={isMobile}
          />
        </>
      }
    >
      <Divider style={{ margin: '16px 0' }} />
      <AdminEventCheck items={checks} />
      <Divider style={{ marginTop: '16px' }} />
    </EventCard>
  );
};

export default AdminEventCardTrimmed;
