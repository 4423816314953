import React from 'react';
import { Button } from '@material-ui/core';
import theme from 'MuiTheme';

export interface AttendingButtonProps {
  isAttending: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
}

const AttendingButton: React.FC<AttendingButtonProps> = ({ isAttending, onClick, style }) => (
  <Button
    onClick={onClick}
    variant="contained"
    color="secondary"
    style={{ width: '105px', backgroundColor: isAttending ? theme.palette.success.main : '', ...style }}
  >
    {isAttending ? 'Attending' : 'Attend'}
  </Button>
);

export default AttendingButton;
