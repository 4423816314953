import React, { useState, useEffect } from 'react';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import * as Backend from '/data/api/BackendRequest';
import { useForm } from 'react-hook-form';
import PageOne from './pageOne/PageOne';
import PageTwo from './pageTwo/PageTwo';

import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { snackActions } from '/redux/actions/FeedbackActions';
import { projectActions } from '/redux/actions/ProjectActions';
import { accessActions } from '/redux/actions/AccessActions';
import { userActions } from '/redux/actions/AuthActions';

const WriteProjectInfo = ({ projectId, openFailure, setRole, projects, redirect }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [drinkData, setDrinkData] = useState([]);
  const [foodPrefs, setFoodPrefs] = useState([]);
  const [foodPrefsOther, setFoodPrefsOther] = useState([]);

  const { handleSubmit, control, setValue, getValues, watch } = useForm({ mode: 'all' });

  /**
   * get data from database
   */
  useEffect(() => {
    let isMounted = true;
    const proms = [];
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/1`));
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/2`));
    proms.push(Backend.request('get', `/user/${projectId}/food-prefs/3`));

    Promise.all(proms)
      .then((res) => {
        if (isMounted) {
          setDrinkData(res[0].data.data);
          const optionTwo = res[1].data.data;
          optionTwo.unshift(optionTwo.pop());
          setFoodPrefs(optionTwo);
          setFoodPrefsOther(res[2].data.data);
        }
      })
      .catch(() => {});

    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = (data) => {
    if (!currentPage) {
      setCurrentPage(1);
      return;
    }

    // drink Pref
    const drinkPref = [''];
    drinkPref[0] = data.drink;

    if (drinkPref[0] === '') {
      openFailure('Please select a drink preference');
      return;
    }

    // food pref
    const foodPref = [];
    const foodPrefInput = getValues(foodPrefs);
    foodPrefInput.forEach((pref, index) => {
      if (pref === true) foodPref.push(foodPrefs[index]);
    });

    if (!foodPref.length || !foodPref.some((fpref) => foodPrefs.slice(0, 6).includes(fpref))) {
      openFailure('Please select a food preference');
      return;
    }

    // save to backend
    const proms = [];
    proms.push(
      Backend.request('post', `/user/${projectId}/food/1`, null, {
        foodPref: drinkPref,
      })
    );
    proms.push(
      Backend.request('post', `/user/${projectId}/food/2`, null, {
        foodPref,
      })
    );
    proms.push(
      Backend.request('post', `/user/${projectId}/food/3`, null, {
        foodPref: data.otherInput === '' || !data.other ? [] : [data.otherInput],
      })
    );
    proms.push(
      Backend.request('post', `/user/assign/${projectId}`, null, {
        arrivalDate: data.arrivalDate,
        isTosAgreed: data.isTosAgreed,
      })
    );
    Promise.all(proms)
      .then(() => {
        const { token } = sessionStorage;
        setRole(token);
        projects(token);
        redirect(`/${projectId}/events`); // change to projectId/events later on.
        navigate(`/${projectId}/events`);
      })
      .catch(() => {});
  };

  const whichPage = () => {
    switch (currentPage) {
      case 0:
        return <PageOne control={control} onSubmit={handleSubmit(onSubmit)} />;
      case 1:
        return (
          <PageTwo
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            watch={watch}
            setValue={setValue}
            drinkData={drinkData}
            foodPrefs={foodPrefs}
            foodPrefsOther={foodPrefsOther}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Content>{whichPage()}</Content>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  projectId: state.projectInfo.projectId,
});

const mapDispatchToProps = {
  openSuccess: snackActions.successFeedback,
  openFailure: snackActions.errorFeedback,
  redirect: userActions.redirect,
  setRole: accessActions.setRole,
  projects: projectActions.projects,
};

export default connect(mapStateToProps, mapDispatchToProps)(WriteProjectInfo);
