export const SIGNUP_INIT = 'SIGNUP_INIT';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_OK = 'SIGNUP_OK';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const GET_EMAIL = 'GET_EMAIL';
export const CONFIRM_SENT = 'CONFIRM_SENT';
export const CONFIRM_FAIL = 'CONFIRM_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_OK = 'LOGIN_OK';
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';
export const LOGOUT = 'LOGOUT';

export const SENDING_FORGOT = 'SENDING_FORGOT';
export const FORGOT_OK = 'FORGOT_OK';
export const FORGOT_FAIL = 'FORGOT_FAIL';
export const SENDING_RESET = 'SENDING_RESET';
export const RESET_OK = 'RESET_OK';
export const RESET_FAIL = 'RESET_FAIL';

interface Action {
  [key: string]: any;
}

const token = sessionStorage.getItem('token');
const initialState = { loggedIn: false, token, error: null, landingPage: '' };

export const authentication = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loggedIn: false,
      };
    case 'LOGIN_OK':
      return {
        ...state,
        loggedIn: true,
        token: action.token,
      };
    case 'LOGIN_REDIRECT':
      return {
        ...state,
        landingPage: action.data,
      };
    case 'LOGIN_FAIL':
      return {
        ...state,
        loggedIn: false,
      };
    case 'LOGOUT':
      return {
        ...state,
        loggedIn: false,
        error: null,
      };
    default:
      return state;
  }
};

export const amnesia = (state = { sending: false, sent: false, error: null, forgottenEmail: null }, action: Action) => {
  switch (action.type) {
    case 'SENDING_FORGOT':
      return {
        ...state,
        sending: true,
        sent: false,
      };
    case 'FORGOT_OK':
      return {
        ...state,
        sending: false,
        sent: true,
        forgottenEmail: action.email,
      };
    case 'FORGOT_FAIL':
      return {
        ...state,
        sending: true,
        sent: false,
        error: action.error,
      };
    case 'SENDING_RESET':
      return {
        ...state,
        sending: true,
        sent: false,
      };
    case 'RESET_OK':
      return {
        ...state,
        sending: true,
        sent: true,
      };
    case 'RESET_FAIL':
      return {
        ...state,
        sending: true,
        sent: false,
        error: action.error,
      };
    default:
      return state;
  }
};
