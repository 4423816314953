import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/storeConfig';
import { useLocation, useNavigate } from 'react-router-dom';

import SubMenu from 'components/subMenu/SubMenu';
import subMenuSlice from 'redux/reducers/SubMenuReducer';
import { useMediaQuery } from '@material-ui/core';
import theme from 'MuiTheme';

const SubMenuContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSubMenu = useAppSelector((state) => state.subMenu.isSubMenu);
  const subMenuLabel = useAppSelector((state) => state.subMenu.label);

  useEffect(() => {
    if (isSubMenu) {
      dispatch(subMenuSlice.actions.closeSubMenu());
    }
  }, [location.pathname]);

  return (
    <>
      {isMobile && (
        <>
          <SubMenu
            isOpen={isSubMenu}
            label={subMenuLabel}
            backButton={() => {
              navigate(-1);
            }}
          />
          {isSubMenu && <div style={{ height: '72px', width: '100%' }} />}
        </>
      )}
    </>
  );
};

export default SubMenuContainer;
