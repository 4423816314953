import React from 'react';
import useEmailStyles from './useEmailStyles';

interface ErrorEmailProps {
  errorMessage: string;
}

const ErrorEmail: React.FC<ErrorEmailProps> = ({ errorMessage }) => {
  const classes = useEmailStyles();

  return (
    <>
      <h6 className={classes.header}>Error</h6>
      <p className={classes.text}>{errorMessage}</p>
    </>
  );
};

export default ErrorEmail;
