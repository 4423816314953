import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const EventGroupBodyMobile = ({
  groupName = 'Group Name',
  appliedEvents = 0,
  ticketLimit = 0,
  displayed = true,
  eventGroupId = 0,
  onDeleteGroup,
  onShowGroup,
}) => {
  return (
    <Card key={eventGroupId} style={{ marginTop: '3px' }}>
      <CardContent style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', padding: '8px' }}>
        <div style={{ gridColumn: '1 / span 5' }}>
          <Typography color='textPrimary' variant='h6' noWrap aria-label='Event group name'>
            {groupName}
          </Typography>
        </div>
        <div style={{ justifySelf: 'end', paddingRight: '6px' }}>
          <IconButton onClick={() => onShowGroup(eventGroupId)} aria-label='edit event group' size='small'>
            <EditIcon color='secondary' fontSize='inherit' />
          </IconButton>
        </div>
        <div style={{ gridColumnStart: 'span 2' }}>
          <Typography variant='caption' color='textPrimary'>
            Events:{' '}
          </Typography>
          <Typography variant='caption' color='textSecondary' aria-label='number of events this group applies to'>
            {appliedEvents}
          </Typography>
        </div>
        <div style={{ gridColumn: '3 / span 2', justifySelf: 'center' }}>
          <Typography variant='caption' color='textPrimary'>
            Limit:{' '}
          </Typography>
          <Typography
            variant='caption'
            color='textSecondary'
            aria-label='number of tickets this event group is limited to'
          >
            {ticketLimit === 0 ? 'None' : ticketLimit}
          </Typography>
        </div>
        <div style={{ gridColumn: '5 / span 2', justifySelf: 'end' }}>
          <Typography variant='caption' color='textPrimary'>
            Displayed:{' '}
          </Typography>
          <Typography variant='caption' color='textSecondary' aria-label='is group displayed?'>
            {displayed ? 'Yes' : 'No'}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
export default EventGroupBodyMobile;
