import './index.scss';
import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'query-client';
import { store } from './redux/storeConfig';
import App from './app/App';

render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </QueryClientProvider>,
  document.querySelector('#root')
);
