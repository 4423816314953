import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearableTextField from '/components/clearableTextField/ClearableTextField.component';
import BookingsOptions from '/components/adminUsers/BookingsOptions.component';
import { Divider } from '@material-ui/core';
import BookingsFilterContainer from './BookingsFilter.container';
import { adminUsersSetSelectedEvents, adminUsersEventFilterSearch } from '/redux/reducers/AdminReducers';
import { useSelector, useDispatch } from 'react-redux';
import { payBookings, deleteBookings } from '/redux/actions/AdminActions';

import { adminUsersTransferOpen } from '/redux/reducers/AdminReducers';
import { Dialog } from 'components/dialog/Dialog.component';

const BookingsContainer = () => {
  const [deleteOpen, setDeleteOpen] = useState(false);

  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(Theme.breakpoints.up('sm'));
  const events = useSelector((state) => state.adminUsers.events);
  const selectedEvents = useSelector((state) => state.adminUsers.selectedEvents);
  const eventFilterSearch = useSelector((state) => state.adminUsers.eventFilterSearch);

  const handleDeleteBooking = () => {
    if (selectedEvents.length > 0) {
      dispatch(deleteBookings());
      setDeleteOpen(false);
    }
  };

  const handlePayBooking = () => {
    if (selectedEvents.length > 0) {
      dispatch(payBookings());
    }
  };

  const handleAllSelect = () => {
    if (selectedEvents.length !== events.length) {
      let pseudoEvents = [];
      events.forEach((event) => {
        pseudoEvents.push(event.bookingId);
      });
      dispatch(adminUsersSetSelectedEvents(pseudoEvents));
    } else {
      dispatch(adminUsersSetSelectedEvents([]));
    }
  };

  const openTransferWindow = () => {
    dispatch(adminUsersTransferOpen(true));
  };

  const openDeleteModal = () => {
    setDeleteOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteOpen(false);
  };

  if (!events) return <></>;

  // TODO: Remove hard coding from icon button, -16px on margin
  return (
    <>
      <Dialog
        title=''
        question='Are you sure you want to delete the user from the event(s)?'
        buttonLabel='DELETE'
        confirmOnClick={handleDeleteBooking}
        cancelOnClick={closeDeleteModal}
        isOpen={deleteOpen}
      />
      <div style={!isDesktop ? { position: 'sticky', top: '45px', backgroundColor: '#ffffff', zIndex: 10 } : null}>
        <Typography variant='h6' style={{ paddingTop: '16px', marginBottom: '4px' }}>
          Bookings
        </Typography>
        {isDesktop ? (
          <Typography variant='body1' style={{ marginBottom: '16px' }}>
            Select events to change their status.
          </Typography>
        ) : (
          <Typography variant='body2' style={{ marginBottom: '8px' }}>
            Tap events to change their status.{' '}
          </Typography>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
          <ClearableTextField
            label='Search Events'
            value={eventFilterSearch}
            setValue={(e) => dispatch(adminUsersEventFilterSearch(e))}
            style={{ marginRight: isDesktop && '16px' }}
          />
          {isDesktop ? (
            <BookingsOptions
              onClickPay={handlePayBooking}
              isSelectedActive={selectedEvents.length > 0}
              onClickDelete={openDeleteModal}
              onClickTransfer={openTransferWindow}
              clickTransferEnabled={selectedEvents.length == 1}
            />
          ) : (
            <BookingsFilterContainer />
          )}
        </div>
        {!isDesktop && (
          <>
            <BookingsOptions
              isMobile
              selectAll={selectedEvents.length === events.length}
              onClickSelectAll={handleAllSelect}
              onClickPay={handlePayBooking}
              isSelectedActive={selectedEvents.length > 0}
              onClickDelete={openDeleteModal}
              onClickTransfer={openTransferWindow}
              clickTransferEnabled={selectedEvents.length == 1}
            />
            {events.length > 0 && (
              <Divider
                style={{
                  margin: '0 -16px',
                  boxShadow: '0px 8px 5px rgba(0, 0, 0, 0.2)',
                  height: '16px',
                  backgroundColor: '#ffffff',
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BookingsContainer;
