import { useMediaQuery } from '@material-ui/core';
import BackArrowText from 'components/subMenu/BackArrowText';
import theme from 'MuiTheme';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import subMenuSlice from 'redux/reducers/SubMenuReducer';
import { useAppDispatch, useAppSelector } from 'redux/storeConfig';

const OpenEventEditSubMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSubMenu = useAppSelector((state) => state.subMenu.isSubMenu);

  useEffect(() => {
    if (!isSubMenu) {
      dispatch(subMenuSlice.actions.openSubMenu({ label: 'Back to overview' }));
    }
  }, []);

  return (
    <>
      {!isMobile && (
        <div style={{ marginBottom: '1rem' }}>
          <BackArrowText label="Back to overview" backButton={() => navigate(-1)} />
        </div>
      )}
    </>
  );
};

export default OpenEventEditSubMenu;
