import React from 'react';
import { Paper } from '@material-ui/core';

import useSubMenuStyles from './SubMenu.styles';
import BackArrowText from './BackArrowText';

export interface SubMenuProps {
  isOpen: boolean;
  label: string;
  backButton: () => void;
}

const SubMenu: React.FC<SubMenuProps> = ({ isOpen, label, backButton }) => {
  const classes = useSubMenuStyles();

  if (!isOpen) {
    return <></>;
  }

  return (
    <Paper elevation={2} className={classes.root}>
      <BackArrowText label={label} backButton={backButton} />
    </Paper>
  );
};

export default SubMenu;
