import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SubMenuType = {
  isSubMenu: boolean; // if true, don't show the mobile menu
  label: string;
};

const initialState: SubMenuType = {
  isSubMenu: false,
  label: '',
};

const subMenuSlice = createSlice({
  name: 'subMenu',
  initialState,
  reducers: {
    openSubMenu: (state, action: PayloadAction<{ label: string }>) => {
      state.isSubMenu = true;
      state.label = action.payload.label;
      window.scrollTo(0, 0);
    },
    closeSubMenu: (state) => {
      state.isSubMenu = false;
      state.label = '';
    },
  },
});

export default subMenuSlice;
