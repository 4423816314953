import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import TextComponent from '/components/forms-v2/Text.component';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Theme from '/MuiTheme';
import { connect } from 'react-redux';
import { eventActions } from '/redux/actions/EventActions';

/**
 * Search Bar for events page
 */
const EventSearch = ({ openOverlay, setEventSearch }) => {
  const { control, watch } = useForm({ mode: 'onSubmit' });

  const watchSearch = watch('search');

  useEffect(() => {
    setEventSearch(watchSearch);
  }, [watchSearch]);

  const open = () => {
    openOverlay();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextComponent control={control} name="search" label="Search event" />
      {useMediaQuery(Theme.breakpoints.down('sm')) ? (
        <IconButton style={{ margin: '0 16px' }} onClick={open}>
          <FilterListIcon style={{ width: '24px', height: '24px', color: Theme.palette.secondary.main }} />
        </IconButton>
      ) : null}
    </div>
  );
};

const actionCreators = {
  openOverlay: eventActions.openOverlay,
  setEventSearch: eventActions.setEventSearch,
};

export default connect(null, actionCreators)(EventSearch);
