import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Typography } from '@material-ui/core';
import theme from 'MuiTheme';
import useCheckedInStyles from './CheckedIn.styles';

export interface CheckedInProps {
  displayText: boolean;
  checkedIn: boolean;
}

const CheckedIn: React.FC<CheckedInProps> = ({ displayText, checkedIn }) => {
  const classes = useCheckedInStyles();

  const iconStyle: React.CSSProperties = {
    width: '16px',
    height: '16px',
  };

  const color: React.CSSProperties = {
    color: checkedIn ? theme.palette.success.main : theme.palette.warning.main,
  };

  return (
    <div className={classes.root} style={color}>
      {checkedIn ? <VerifiedUserIcon style={iconStyle} /> : <WarningIcon style={iconStyle} />}
      {displayText && <Typography variant="caption">{checkedIn ? 'Checked-In' : 'Not Checked-In'}</Typography>}
    </div>
  );
};

export default CheckedIn;
