import React from 'react';

const PrivacyWithWho = () => {
  return (
    <>
      <h6>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h6>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>We only share information with the following categories of third parties.</em>
      </p>
      <p>
        We only share and disclose your information with the following categories of third parties. If we have processed
        your data based on your consent and you wish to revoke your consent, please contact us using the contact details
        provided in the section below titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".
      </p>
      <ul>
        <li>Cloud Computing Services</li>
      </ul>
      <ul>
        <li>Communication &amp; Collaboration Tools</li>
      </ul>
      <ul>
        <li>Website Hosting Service Providers</li>
      </ul>
      <ul>
        <li>Data Analytics Services</li>
      </ul>
    </>
  );
};

export default PrivacyWithWho;
