import React, { useState, useEffect } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import { adminAccessRoleFilterSet, adminAccessCurrentPageSet } from '/redux/actions/AdminActions';

/**
 * Styling for menu
 */
const useStyles = makeStyles((t) => ({
  arrow: {
    width: 20,
    height: 20,
    color: `${t.palette.secondary.main}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItemHover: {
    '&:hover': {
      color: t.palette.secondary.main,
    },
  },
}));

/**
 * Filter Menu
 *
 * Required props:
 *  - roles
 *  - setRoleFilter: function.
 */
const RoleFilter = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  /* TODO: Change this to something you get from the backend */
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    setFilter(
      props.roles.map((role, index) => ({
        label: role.title,
        checked: props.roleFilter[index] || false,
        roleId: role.roleId,
      }))
    );
  }, []);

  /**
   * When user changes the checkbox, it filters for the access
   * also set current page to 1 - to make sure the list does not become empty.
   */
  useEffect(() => {
    props.setRoleFilter(filter.map((filt) => filt.checked));
    props.setCurrentPage(1);
  }, [filter]);

  /**
   * Opening the menu
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @param {*} index index of the checkbox
   */
  const changeCheckbox = (index) => {
    let tempFilter = [...filter];
    tempFilter[index].checked = !tempFilter[index].checked;
    setFilter(tempFilter);
  };

  /**
   * Closes the menu
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FilterListIcon
        aria-controls='userRoleMenu'
        aria-haspopup='true'
        className={classes.arrow}
        onClick={handleClick}
      />
      <Menu id='userRoleMenu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {filter.map((checked, index) => (
          <MenuItem
            key={index}
            className={classes.menuItemHover}
            selected={filter.checked}
            onClick={() => changeCheckbox(index)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.checked || false}
                  style={{ padding: 0, marginRight: '16px' }}
                  color='secondary'
                />
              }
              label={checked.label}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const mapState = (state) => {
  return {
    roles: state.adminAccess.roles,
    roleFilter: state.adminAccess.roleFilter,
  };
};

const actions = {
  setRoleFilter: adminAccessRoleFilterSet,
  setCurrentPage: adminAccessCurrentPageSet,
};

export default connect(mapState, actions)(RoleFilter);
