import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { authentication, amnesia } from './reducers/AuthReducers';
import { eventRetrival, eventTicketTransfer } from './reducers/EventReducers';
import { feedback } from './reducers/FeedbackReducers';
import { projectInfo } from './reducers/ProjectReducers';
import emailRetrival from './reducers/EmailReducer';
import { roles } from './reducers/AccessReducers';
import { adminAccess, adminUsers } from './reducers/AdminReducers';
import options from './reducers/OptionsReducers';
import subMenuSlice from './reducers/SubMenuReducer';
// eslint-disable-next-line import/no-cycle
import { adminEventGroups } from './reducers/EventGroupsReducers'; // fix dependency cycle. very dangerous

export const store = configureStore({
  reducer: {
    authentication,
    emailRetrival,
    amnesia,
    eventRetrival,
    feedback,
    projectInfo,
    roles,
    adminAccess,
    adminUsers,
    options,
    adminEventGroups,
    eventTicketTransfer,
    subMenu: subMenuSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
