import { AppDispatch, RootState } from 'redux/storeConfig';
import {
  RequestProgrammes,
  RequestSignup,
  RequestConfirmEmail,
  RequestLogin,
  Logout,
  RequestForgot,
  RequestReset,
} from '../../data/api/AuthRequest';

import { accessActions } from './AccessActions';
import { projectActions } from './ProjectActions';
import { snackActions } from './FeedbackActions';

import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_OK,
  LOGIN_REDIRECT,
  LOGOUT,
  SENDING_FORGOT,
  FORGOT_OK,
  FORGOT_FAIL,
  SIGNUP_OK,
  SENDING_RESET,
  RESET_OK,
  RESET_FAIL,
  CONFIRM_FAIL,
  CONFIRM_SENT,
  GET_EMAIL,
  SIGNUP_FAIL,
  SIGNUP_REQUEST,
  SIGNUP_INIT,
} from '/redux/reducers/AuthReducers';

const signupINIT = (studyInfo: { [x: string]: any }) => ({ type: SIGNUP_INIT, studyInfo });
const signupREQUEST = () => ({ type: SIGNUP_REQUEST });
const signupOK = () => ({ type: SIGNUP_OK });
const signupFAIL = (err: any) => ({ type: SIGNUP_FAIL, error: err });
const getEmail = (email: string) => ({ type: GET_EMAIL, email });
const confirmSENT = (email: string) => ({ type: CONFIRM_SENT, email });
const confirmFAIL = (err: any) => ({ type: CONFIRM_FAIL, error: err });
const sendForgot = () => ({ type: SENDING_FORGOT });
const forgotOK = (email: string) => ({ type: FORGOT_OK, email });
const forgotFAIL = (err: any) => ({ type: FORGOT_FAIL, error: err });
const sendReset = () => ({ type: SENDING_RESET });
const resetOK = () => ({ type: RESET_OK });
const resetFAIL = (err: any) => ({ type: RESET_FAIL, error: err });
const loginRequest = () => ({ type: LOGIN_REQUEST });
const loginOK = (token: string) => ({ type: LOGIN_OK, token });
const loginREDIRECT = (path: string) => ({ type: LOGIN_REDIRECT, data: path });
const loginFAIL = (err: any) => ({ type: LOGIN_FAIL, error: err });
const logoutOK = () => ({ type: LOGOUT });

const signupDetails = () => (dispatch: AppDispatch) => {
  RequestProgrammes()
    .then((resp: any) => {
      resp.data.data.sort((a: any, b: any) => a.nameEn.localeCompare(b.nameEn));
      const prog = resp.data.data;
      const types = resp.data.data
        .map((d: any) => d.typeEn)
        .filter((v: any, i: any, s: any) => s.indexOf(v) === i)
        .sort((a: any, b: any) => a.localeCompare(b));
      dispatch(signupINIT({ programmes: prog, types }));
    })
    .catch((err: any) => dispatch(signupFAIL(err)));
};

const createAccount = (user: any) => (dispatch: AppDispatch) => {
  dispatch(signupREQUEST());
  RequestSignup(user)
    .then(() => {
      dispatch(snackActions.successFeedback('Successfully created an account!'));
      dispatch(signupOK());
    })
    .catch((err: any) => {
      if (err.response.status < 500) dispatch(snackActions.errorFeedback(`${err.response.data.error.message}`));
      else dispatch(snackActions.errorFeedback('Server error'));
      dispatch(signupFAIL(err));
    });
};

const sendEmail = (email: string, pid: string) => (dispatch: AppDispatch) => {
  dispatch(getEmail(email));
  RequestConfirmEmail(email, pid)
    .then(() => dispatch(confirmSENT(email)))
    .catch((err: any) => dispatch(confirmFAIL(err)));
};

const forgot = (email: string, pid: string) => (dispatch: AppDispatch) => {
  dispatch(sendForgot());
  RequestForgot({ email }, pid)
    .then(() => {
      dispatch(forgotOK(email));
    })
    .catch((error: any) => {
      dispatch(forgotFAIL(error));
    });
};

const reset = (token: string, pwd: string) => (dispatch: AppDispatch) => {
  dispatch(sendReset());
  RequestReset(token, { password: pwd })
    .then(() => {
      dispatch(resetOK());
    })
    .catch((error: any) => {
      dispatch(resetFAIL(error));
    });
};

const login = (email: string, password: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const appState = getState();
  dispatch(loginRequest());
  RequestLogin(
    {
      email: email.toLowerCase(),
      password,
    },
    appState.projectInfo.projectId
  )
    .then((data: any) => {
      dispatch(projectActions.projects(data.token));
      dispatch(loginOK(data.token));
      dispatch(accessActions.setRole(data.token));
    })
    .catch((error: any) => {
      dispatch(loginFAIL(error));
    });
};

const redirect = (path: any) => (dispatch: AppDispatch) => {
  dispatch(loginREDIRECT(path));
};

const logout = () => (dispatch: AppDispatch) => {
  Logout();
  dispatch(logoutOK());
  dispatch(accessActions.resetRole('logging out'));
};

export const createActions = {
  signupDetails,
  createAccount,
  sendEmail,
};

export const userActions = {
  login,
  redirect,
  loginRequest,
  loginOK,
  loginFAIL,
  logout,
  forgot,
  reset,
};
