// eslint-disable-next-line import/no-cycle
import * as Backend from './BackendRequest'; // fix circular dependency

export const GetUsersList = (projectId: string) => {
  return Backend.request('get', `admin/${projectId}/roles/list`);
};

export const SetUserRole = (projectId: string, accountId: string | number, roleId: string | number) => {
  return Backend.request('patch', `admin/${projectId}/role/${accountId}`, {}, { roleId });
};

export const DeleteUser = (projectId: string, idToDelete: string | number) => {
  return Backend.request('delete', `admin/${projectId}/user`, { removalId: idToDelete });
};

export const GetUserInfo = (projectId: string, userId: string | number) => {
  return Backend.request('get', `/admin/${projectId}/user/${userId}`);
};

export const GetUserStudy = (projectId: string, userId: string | number) => {
  return Backend.request('get', `/admin/${projectId}/study-info/${userId}`);
};

export const GetUserTickets = (projectId: string, userId: string | number) => {
  return Backend.request('get', `/admin/${projectId}/user/tickets/${userId}`, {
    projectId,
  });
};

export const GetUserPrefs = (projectId: string, userId: string | number, optionId: string | number) => {
  return Backend.request('get', `/admin/${projectId}/food/${userId}/${optionId}`);
};

export const GetUserCheckedIn = (projectId: string, userId: string | number) => {
  return Backend.request('GET', `admin/${projectId}/user/checkin/${userId}`);
};

export const GetAllEvents = (projectId: string) => {
  return Backend.request('GET', `admin/${projectId}/events`);
};

export const CheckEventToUser = (projectId: string, eventId: string | number, userId: string | number) => {
  return Backend.request('GET', `/admin/${projectId}/check-booking/${eventId}/${userId}`);
};

export const AddBookingToUser = (projectId: string, userId: string | number, eventId: string | number, isPaid: boolean, isAttending: boolean) => {
  return Backend.request('POST', `admin/${projectId}/booking/${eventId}/${userId}`, {}, { isPaid, isAttending });
};

export const SetUserCheckedIn = (projectId: string, userId: string | number, value: boolean) => {
  return Backend.request('PATCH', `admin/${projectId}/user/checkin/${userId}`, {}, { checkedIn: value });
};

export const AddEventToUser = (projectId: string, eventId: string | number, userId: string | number) => {
  return Backend.request('POST', `admin/${projectId}/booking/${eventId}/${userId}`);
};

export const GetUserArrivalDate = (projectId: string, userId: string | number) => {
  return Backend.request('GET', `admin/${projectId}/project/info/${userId}`);
};

export const DeleteBookingToUser = (projectId: string, bookingId: string | number) => {
  return Backend.request('DELETE', `admin/${projectId}/ticket/${bookingId}`);
};

export const SetEventPaid = (projectId: string, bookingId: string | number, isPaid: boolean) => {
  return Backend.request('PATCH', `admin/${projectId}/booking/${bookingId}`, {}, { isPaid });
};

export const ExportSingleEvent = (projectId: string, eventId: string | number) => {
  return Backend.requestBlob('GET', `admin/${projectId}/export_single/${eventId}`);
};

export const GetEventGroups = (projectId: string) => {
  return Backend.request('GET', `admin/${projectId}/event-group`);
};
export const DeleteEventGroup = (projectId: string, eventGroupId: string | number) => {
  return Backend.request('DELETE', `admin/${projectId}/event-group/${eventGroupId}`);
};
export const CreateEventGroup = (projectId: string, params: any) => { // todo: type params
  return Backend.request('POST', `admin/${projectId}/event-group`, {}, params.requestBody);
};
export const UpdateEventGroup = (projectId: string, params: any) => { // todo: type params
  return Backend.request(
    'PATCH',
    `admin/${projectId}/event-group/${params.eventGroupId}`,
    {},
    params.requestBody
  ); 
};
export const CheckTransfer = (projectId: string, bookingId: string | number, userId: string | number) => { // todo: remove unused params
  return Backend.request('GET', `admin/${projectId}/booking/${bookingId}`);
};

export const TransferTicket = (projectId: string, bookingId: string | number, userId: string | number, isPaid: boolean, isAttending: boolean) => { // todo: remove unused params
  return Backend.request('PATCH', `admin/${projectId}/booking/${bookingId}`, {}, { isPaid });
};

export const CheckTicket = (projectId: string, userId: string | number, bookingId: string | number) => {
  return Backend.request('POST', `admin/${projectId}/ticket/transfer/check/${userId}`, {}, { bookingID: bookingId });
};

export const PostTicket = (projectId: string, userId: string | number, bookingId: string | number, isAttending: boolean, isPaid: boolean) => {
  return Backend.request(
    'POST',
    `admin/${projectId}/ticket/transfer/confirm/${userId}`,
    {},
    { bookingID: bookingId, isAttending, isPaid }
  );
};
