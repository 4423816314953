import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Content from '/components/content/Content';
import Access from './access/Access';
import EventFilter from './eventFilter/EventFilter';
import EventGroups from './eventGroups/EventGroups';
import Project from './project/Project';
import Users from './users/Users';
import * as Backend from '/data/api/BackendRequest.ts';
import Footer from '/components/footer/Footer';
import { Routes, Route, Navigate } from 'react-router-dom';
import TabBar from '/components/tabs/Tabs';
import CreateEventGroup from './eventGroups/CreateEventGroup';
import AdminEventsPage from './events/Events';

const Admin = (props) => {
  const [eventsData, setEventsData] = useState([]);
  const projectId = useSelector((state) => state.projectInfo.projectId);

  const categories = {
    'Users Overview': `/${props.projectId}/admin/users`,
    'Events Overview': `/${props.projectId}/admin/events`,
    'Event Filters': `/${props.projectId}/admin/filters`,
    'Event Groups': `/${props.projectId}/admin/groups`,
    'Project Settings': `/${props.projectId}/admin/project`,
    'Access Management': `/${props.projectId}/admin/management`,
  };

  useEffect(() => {
    if (!projectId) return;
    getEvents();
  }, []);

  const getEvents = () => {
    Backend.request('get', `admin/${props.projectId}/events`, {}).then((response) => setEventsData(response.data.data));
  };

  if (!projectId) return <></>;

  return (
    <>
      <TabBar tablist={categories} />
      <Content sidebar={categories}>
        <Routes>
          <Route path="/users/*" element={<Users />} />
          <Route path="/events/*" element={<AdminEventsPage />} />
          <Route path="/filters/*" element={<EventFilter events={eventsData} />} />
          <Route
            path="/groups/*"
            element={
              <Routes>
                <Route path="/new" element={<CreateEventGroup />} />
                <Route path="*" element={<EventGroups />} />
              </Routes>
            }
          />
          <Route path="/project/*" element={<Project />} />
          <Route path="/management/*" element={<Access />} />
          <Route path="*" element={<Navigate to="users" />} />
        </Routes>
      </Content>
      <Footer name="IR" />
    </>
  );
};

function mapState(state) {
  return {
    projectId: state.projectInfo.projectId,
  };
}

export default connect(mapState)(Admin);
