import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getBookingStatus, getTimeDuration, getTransferStatus } from 'pages/admin/events/utils/EventCardMapper';
import { AdminEventCard } from '/components/eventCard/AdminEventCard';

const EventListItem = ({ event, statsList, eventFilters, eventGroups, isMobile }) => {
  const navigate = useNavigate();
  const projectId = useSelector((state) => state.projectInfo.projectId);
  const isCancelled = React.useRef(false);
  const [stats, setStats] = React.useState({});
  const [filters, setFilters] = React.useState([]);
  const [groups, setGroups] = React.useState([]);

  const handleEditClick = () => {
    navigate(`/${projectId}/admin/events/edit/${event.eventId}`);
  };

  const handleManageClick = () => {
    navigate(`/${projectId}/admin/events/manage/${event.eventId}`);
  };

  /**
   * To make it more efficient when mapping the stats by making it async.
   */
  React.useEffect(() => {
    if (!event || !eventFilters || !eventGroups || !statsList) return undefined;

    const mapFiltersAndGroup = async () => {
      const filtered = eventFilters?.filter((filter) => filter?.eventIds?.includes(event.eventId));
      const grouped = eventGroups?.filter((group) => group?.eventIds?.includes(event.eventId));
      const eventStats = statsList?.find((s) => s?.eventId === event.eventId);
      if (!isCancelled.current) {
        setFilters(filtered);
        setGroups(grouped);
        setStats(eventStats?.stats);
      }
    };

    mapFiltersAndGroup(event, eventFilters, eventGroups, statsList);

    return () => {
      isCancelled.current = true;
    };
  }, [event, eventFilters, eventGroups, statsList]);

  return (
    <AdminEventCard
      title={event.displayTitle}
      subtitle={getTimeDuration()}
      isMobile={isMobile}
      currentEventBooked={stats?.countBooked || 0}
      currentEventAttended={stats?.countAttending || 0}
      maxEventAttended={event.attendanceLimit}
      paidPeople={stats?.countPaid || 0}
      description={event.description}
      filters={filters}
      categories={groups}
      checks={[
        {
          label: 'Location',
          value: event.location,
          showStatus: false,
          isOk: false,
        },
        {
          label: 'Price',
          value: `${event.basePriceSek} SEK`,
          showStatus: false,
          isOk: false,
        },
        {
          ...getBookingStatus(event),
        },
        {
          ...getTransferStatus(event),
        },
      ]}
      onEditClick={handleEditClick}
      onManageClick={handleManageClick}
    />
  );
};

export default EventListItem;
