import React from 'react';
import { Typography } from '@material-ui/core';
import { Control } from 'react-hook-form';

import DateComponent from 'components/forms-v2/Date.component';
import useStartEndStyles from './EventStartEnd.styles';
import useInputStyles from './EventInput.styles';
import { editEventFormKeys } from '../types/FormTypes';

interface EventStartEndProps {
  control: Control<any, any>;
}

const EventStartEnd: React.FC<EventStartEndProps> = ({ control }) => {
  const baseClasses = useInputStyles();
  const classes = useStartEndStyles();

  return (
    <div className={baseClasses.root}>
      <Typography variant="body1">Event Date</Typography>
      <div className={classes.inputContainer}>
        <DateComponent
          control={control}
          name={editEventFormKeys.startDatetime}
          label="Start date"
          style={{ marginTop: '0', marginBottom: '0' }}
          helperText="Enter the start date and time of the event. Dates and times are in CE(S)T"
          isDatetime
        />
        <DateComponent
          control={control}
          name={editEventFormKeys.endDatetime}
          label="End date"
          style={{ marginTop: '0', marginBottom: '0' }}
          helperText="Enter the end date and time of the event. Dates and times are in CE(S)T"
          isDatetime
        />
      </div>
    </div>
  );
};

export default EventStartEnd;
