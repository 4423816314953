import { createSlice } from '@reduxjs/toolkit';

export const IS_LOADING = 'IS_LOADING';
export const EVENTS_RETRIVED = 'EVENTS_RETRIVED';
export const EVENTS_STATS_RETRIVED = 'EVENTS_STATS_RETRIVED';
export const TICKETS_RETRIVED = 'TICKETS_RETRIVED';
export const TICKET_REMOVED = 'TICKET_REMOVED';
export const EVENT_BOOKED = 'EVENT_BOOKED';
export const EVENT_ERROR = 'EVENT_ERROR';
export const EVENT_FILTER = 'EVENT_FILTER';
export const EVENT_OVERLAY = 'EVENT_OVERLAY';
export const EVENT_SEARCH = 'EVENT_SEARCH';
export const EVENT_GROUPS = 'EVENT_GROUPS';

const initialState = {
  isLoading: true,
  data: [],
  error: null,
  tickets: [],
  snack: {},
  filter: {
    startDate: null,
    endDate: null,
    startPrice: null,
    endPrice: null,
    booked: false,
    bookable: false,
    closed: false,
    full: false,
    categories: [],
  },
  filterOverlay: false,
  filterSearch: '',
  eventGroups: null,
};

interface Action {
  [key: string]: any;
}

export const eventRetrival = (state = initialState, action: Action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
        bookedEvent: undefined,
        error: null,
        snack: {},
      };
    case EVENTS_RETRIVED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
        snack: {},
      };
    case EVENTS_STATS_RETRIVED:
      return {
        ...state,
        isLoading: false,
        stats: action.data,
        error: null,
        snack: {},
      };
    case TICKETS_RETRIVED:
      return {
        ...state,
        isLoading: false,
        tickets: action.data,
        error: null,
        snack: {},
      };
    case TICKET_REMOVED:
      return {
        ...state,
        isLoading: false,
        error: null,
        snack: {},
      };
    case EVENT_BOOKED:
      return {
        ...state,
        isLoading: false,
        bookedEvent: action.data.data.booked.bookingId,
        error: null,
        snack: {
          open: true,
          context: (x: string) => `Success: A ticket for ${x} has been booked!`,
        },
      };
    case EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        snack: {
          open: true,
          context: (x: string) => `Error: ${x}`,
        },
      };
    case EVENT_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case EVENT_SEARCH:
      return {
        ...state,
        filterSearch: action.filterSearch,
      };
    case EVENT_OVERLAY:
      return {
        ...state,
        filterOverlay: action.overlay,
      };
    case EVENT_GROUPS:
      return {
        ...state,
        eventGroups: action.data,
      };
    default:
      return state;
  }
};

const eventTicketTransferSlice = createSlice({
  name: 'options',
  initialState: {
    isOpen: false,
    bookingId: null,
  },
  reducers: {
    eventTicketTransferOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    eventTicketTransferBookingId: (state, action) => {
      state.bookingId = action.payload;
    },
  },
});

export const { eventTicketTransferOpen, eventTicketTransferBookingId } = eventTicketTransferSlice.actions;
export const eventTicketTransfer = eventTicketTransferSlice.reducer;
