import Dialog from '@material-ui/core/Dialog';
import FormLogo from '/components/formLogo/FormLogo.component.tsx';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { snackActions } from '/redux/actions/FeedbackActions.jsx';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ChangeUserEmail } from '/data/api/ProfileRequest';

const useStyles = makeStyles((t) => ({
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentHeightPadding: {
    marginTop: '16px',
  },
  modalPadding: {
    padding: '30px',
  },
  header: {
    marginTop: '16px',
  },
  text: {
    marginTop: '5px',
    marginBottom: '16px',
  },
  button: {
    width: '100%',
    marginBottom: '40px',
  },
}));

export const EmailChangeDialog = ({ open, handleClose, email, password }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const resendEmail = () => {
    ChangeUserEmail(password, email)
      .then(() => dispatch(snackActions.successFeedback('E-mail sent!')))
      .catch((err) => {
        if (err.response.status < 500) dispatch(snackActions.successFeedback('' + err.response.data.error.message));
        else dispatch(snackActions.successFeedback('Server error'));
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <div className={classes.closeContainer}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography className={classes.modalPadding} component={'span'}>
          <FormLogo />
          <div className={classes.contentHeightPadding} />
          <h6 className={classes.header}>Verify your e-mail address</h6>
          <p className={classes.text}>
            We have sent a verification link to your e-mail address <a href={'mailto:' + email}>{email}</a>. Please
            follow the instruction in the e-mail.
          </p>
          <Button className={classes.button} onClick={resendEmail} variant='contained' color='secondary'>
            Resend E-mail
          </Button>
        </Typography>
      </Dialog>
    </>
  );
};
