import React, { useState, useEffect, useMemo } from 'react';
import { Divider, Typography } from '@material-ui/core';
import EventGroupsSearchBarContainer from '/containers/adminEventGroups/EventGroupsSearchBarContainer'; //This is probably legacy since we intend to use FilterComponent.
import EventGroupsList from '/containers/adminEventGroups/EventGroupsList.container';
import Theme from '/MuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import './eventGroup.scss'; //Move any applicable styles inline.
import { Button, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearableTextField from '/components/clearableTextField/ClearableTextField.component';
import FilterComponent from '/components/forms/FilterComponent';
import { getEventGroups1 } from '../../../redux/actions/EventGroupsActions';
import { useNavigate, Link } from 'react-router-dom';
import { adminShowingEventGroup } from '../../../redux/reducers/EventGroupsReducers';
import { deleteEventGroup } from '../../../redux/actions/AdminActions';
import CreateEventGroup from './CreateEventGroup';

const EventGroups = () => {
  //const attr = ['name']; //The attributes to filter for in the search bar.
  const projectId = useSelector((state) => state.projectInfo.projectId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getEventGroups1(projectId));
  }, []);

  const eventGroups = useSelector((state) => state.adminEventGroups.eventGroups);

  const [eventGroupToShow, setEventGroupToShow] = useState(
    useSelector((state) => state.adminEventGroups.adminShowingEventGroup)
  );
  const isDesktop = useMediaQuery(Theme.breakpoints.up('sm'));

  //const eventGroupsFilterSearch = useSelector((state) => state.adminEventGroups.eventGroupsFilterSearch);
  //Perhaps consider removing eventGroupsFilterSearch from the store, in light of
  //recommendation from react-redux docs regarding minimal store data and the
  //preferred usage of derived data instead.

  //Use something like this once the actions createEventGroup, deleteEventGroup are ready.
  const handleCreateEventGroup = () => {
    navigate('new'); //We could use this after removing the Link around the button.
    //dispatch(createEventGroup());
  };

  const handleDeleteEventGroup = (eventGroupId) => {
    if (eventGroups.length > 0) {
      dispatch(deleteEventGroup(eventGroupId));
    }
  };

  const handleShowGroup = (eventGroupId) => {
    dispatch(adminShowingEventGroup(eventGroupId));
    setEventGroupToShow(eventGroupId);
  };

  function CustomButton({ icon, text, onButtonClick }) {
    const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));
    return (
      <>
        {isMobile ? (
          <>
            <Fab
              onClick={onButtonClick}
              color='secondary'
              aria-label={text}
              style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: '1200' }}
              children={icon}
            />
          </>
        ) : (
          <Button
            variant='contained'
            color='secondary'
            startIcon={icon}
            style={{
              borderRadius: '50px',
              height: '48px',
              marginTop: '16px',
              float: 'right',
            }}
            onClick={onButtonClick}
          >
            {text}
          </Button>
        )}
      </>
    );
  }

  if (useSelector((state) => state.adminEventGroups.showingEventGroup)) {
    return <CreateEventGroup showEventGroupId={eventGroupToShow} />;
  }
  if (eventGroups === []) {
    return <div>Loading page.</div>;
  } else {
    return (
      <div className='grouppageContainer'>
        <Typography
          variant={!isDesktop ? 'h5' : 'h4'}
          style={!isDesktop ? { marginTop: '16px', marginBottom: '10px' } : null}
        >
          Event Groups
        </Typography>
        <Typography variant={!isDesktop ? 'body2' : 'body1'} style={{ marginTop: 0, marginBottom: '12px' }}>
          Create event groups to limit the amount of bookable tickets and/or to inform users about coherent events.
          Event groups can be shown as “Event Category” on the event booking page.
        </Typography>
        {/*         <div className='eventToolbar'>
          <div className='searchField'>
            <FilterComponent setList={setDisplayEventGroups} list={eventGroups} attributes={attr}>
              Search event groups
            </FilterComponent>
          </div>
        </div> */}
        <Divider style={{ marginTop: '16px' }} />
        {eventGroups === [] ? (
          'Loading groups.'
        ) : (
          <EventGroupsList
            groups={eventGroups}
            onDeleteGroup={handleDeleteEventGroup}
            onShowGroup={handleShowGroup}
            style={{ marginTop: '8px' }}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
            paddingRight: '8px',
            marginTop: '32px',
          }}
        >
          <CustomButton icon={<AddIcon />} text='Create' onButtonClick={handleCreateEventGroup} />
        </div>
      </div>
    );
  }
};

export default EventGroups;
